/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./textFieldNoRequired.scss";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

const TextFieldNoRequired = (props) => {
  const {t} = useTranslation()
  let {
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    iconClass,
    maxLength,
    onIconClick,
    onKeyDown,
    maskType,
    noTextfield,
    formMethod,
    rows,
    defaultValue,
    rules,
    iconClassLeft,
    onIconLeftClick,
    iconRightShow,
    iconLeftShow,
    required,
    textFieldClassName
  } = props;

  let values = formMethod?.getValues();
  let hasError = errors[name] !== undefined;

  const [error, setError] = useState(false);

  return (
    <div className="TextFieldNoRequired">
      {noTextfield ? (
        <>
          {iconLeftShow && (
            <div className="iconSection left_icon">
              <i
                className={"icon-left " + iconClassLeft}
                onClick={() =>
                  values[name] && values[name] !== "" && onIconLeftClick()
                }
              ></i>
            </div>
          )}
          <Controller
            name={name}
            control={formMethod?.control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
              <InputMask
                className="form-control"
                mask={maskType}
                maskChar=""
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                {...field} // Pass the field props to InputMask
              />
            )}
          />
        </>
      ) : (
        <>
          {iconLeftShow && (
            <div className="iconSection left_icon">
              <i
                className={"icon-left " + iconClassLeft}
                onClick={() =>
                  values[name] && values[name] !== "" && onIconLeftClick()
                }
              ></i>
            </div>
          )}
          <Controller
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <>
                <Form.Control
                   className={hasError ? "error " + textFieldClassName : textFieldClassName}
                  name={name}
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  autoComplete="off"
                  type={type}
                  as={textarea}
                  rows={rows}
                  onBlur={() => handleBlur}
                  onKeyDown={onKeyDown}
                  onFocus={(e) => {
                    handleFocus && handleFocus(e);
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimLeft();
                    onChange && onChange(e.target.value.trimLeft());
                    setError(hasError);
                    props.onChange && props.onChange(e);
                  }}
                  value={formMethod.watch(name)}
                />
              </>
            )}
            name={name}
            control={formMethod?.control}
            rules={rules}
          />
          {iconRightShow && (
            <div className="iconSection">
              <i
                className={"icon-css " + iconClass}
                onClick={() =>
                  values[name] && values[name] !== "" && onIconClick()
                }
              ></i>
            </div>
          )}
        </>
      )}

      {multiErrorFields.length > 0 ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) => {
            if (messages) {
              let isMultipleError = Object.keys(messages).every(
                (errKey) =>
                  multiErrorFields.filter((m) => m[errKey] !== undefined)
                    .length > 0
              );
              if (isMultipleError) {
                let arr = [];
                for (const fieldError of multiErrorFields) {
                  let key = Object.keys(fieldError)[0];
                  let success = Object.keys(messages).includes(key);
                  arr.push(
                    <div className={success ? "red" : "green"}>
                      <span key={key}>{fieldError[key]}</span>
                    </div>
                  );
                }
                return (
                  <div className="errorMsg show passwordcustom">{arr}</div>
                );
              } else {
                return (
                  <div className="errorMsg show">{errors[name]?.message}</div>
                );
              }
            } else {
              return <div className="errorMsg"></div>;
            }
          }}
        />
      ) : hasError ? (
        <div className="errorMsg show">
          <span>{errors[name]?.message}</span>
        </div>
      ) : (
        <div className="errorMsg">{t("error")}</div>
      )}
    </div>
  );
};

TextFieldNoRequired.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
  noNegative: false,
};

export default TextFieldNoRequired;
