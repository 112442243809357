/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Marker } from "@react-google-maps/api";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

import GoogleMapStyle from "../map/googleMapStyle.json";
import "./contactus.scss";
import { useTranslation } from "react-i18next";
import Constant from "../../util/constant";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "536px",
};

/**
 * A functional component that renders a contact form modal.
 * @param {object} props - The props object containing contact data and modal state.
 * @returns The rendered contact form modal.
 */
const ContactUs = (props) => {
  const { t } = useTranslation();
  const history = useLocation();

  let location = props?.contactData?.location || {
    type: "Point",
    coordinates: [-2.9124, 56.6980]
  };
  const center = {
    lat: location?.coordinates[1],
    lng: location?.coordinates[0],
  };

  const [currentAddress, setCurrentAddress] = useState("");

  useEffect(() => {
    const getAddress = async () => {
      try {
        let locationAddress = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location?.coordinates[1]},${location?.coordinates[0]}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
        );
        setCurrentAddress(locationAddress?.data?.results[0]?.formatted_address);
      } catch (error) {
      }
    }
    getAddress()
  }, [props?.contactData])

  return (
    <>
      {/**
       * Renders a modal component for contacting the support team.
       * @param {boolean} props.showModal - Determines whether the modal is shown or hidden.
       * @param {function} props.modalClose - Callback function to close the modal.
       * @param {string} history.pathname - The current pathname of the page.
       * @param {object} location - The location object containing coordinates.
       * @param {string} location.coordinates[0] - The longitude coordinate.
       * @param {string} location.coordinates[1] - The latitude coordinate.
       * @param {string} phoneNumber - The phone number to display.
       * @param {object} props.contactData - The contact data object.
       * @param {string} props.contactData.address -
       */}
      <Modal
        centered
        className="contactModal map_padding"
        show={props.showModal}
        onHide={() => {
          props.modalClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="support-head">{t("CONTACT_US")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {center && (history.pathname === "/" ||
            history.pathname === "/event" ||
            history.pathname === "/bookmark") ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              options={{ styles: GoogleMapStyle, disableDefaultUI: true }}
              center={center}
              zoom={15}
            >
              <Marker
                key="1"
                position={center}
              // icon={{
              //   path: "M19.8608 0.595947C19.5738 0.595947 19.2869 0.595947 19 0.595947C8.52629 0.595947 0.0612564 8.97997 0.0612564 19.3534C0.0612564 28.448 6.66113 36.1215 15.4131 37.8267L17.8522 42.6581C18.2827 43.6528 19.7174 43.6528 20.1478 42.6581L22.5869 37.8267C31.3389 36.1214 37.9388 28.59 37.9388 19.3534C37.9388 9.26416 29.9041 1.02215 19.8609 0.595947H19.8608ZM22.013 31.2901C21.2957 31.4322 20.4347 31.5743 19.5739 31.5743C19.4305 31.5743 19.2869 31.5743 19.1435 31.5743C12.2566 31.5743 6.66113 26.0323 6.66113 19.2114C6.66113 12.3906 12.2567 6.84855 19.1435 6.84855C19.2869 6.84855 19.4305 6.84855 19.5739 6.84855H19.7174C26.3173 7.13278 31.4824 12.5327 31.4824 19.2114C31.4824 25.1797 27.3216 30.0112 22.013 31.2901H22.013ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901Z",
              //   fillColor: "#FF9800",
              //   strokeColor: "#FF9800",
              //   fillOpacity: 1,
              //   anchor: new window.google.maps.Point(17, 46),
              //   scaledSize: new window.google.maps.Size(37, 37)
              // }}
              />
            </GoogleMap>
          ) : (
            <LoadScript googleMapsApiKey={Constant.GOOGLEMAPAPI.APIKEY}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                options={{ styles: GoogleMapStyle, disableDefaultUI: true }}
                center={center}
                zoom={15}
              >
                <Marker
                  position={center}
                // icon={{
                //   path: "M19.8608 0.595947C19.5738 0.595947 19.2869 0.595947 19 0.595947C8.52629 0.595947 0.0612564 8.97997 0.0612564 19.3534C0.0612564 28.448 6.66113 36.1215 15.4131 37.8267L17.8522 42.6581C18.2827 43.6528 19.7174 43.6528 20.1478 42.6581L22.5869 37.8267C31.3389 36.1214 37.9388 28.59 37.9388 19.3534C37.9388 9.26416 29.9041 1.02215 19.8609 0.595947H19.8608ZM22.013 31.2901C21.2957 31.4322 20.4347 31.5743 19.5739 31.5743C19.4305 31.5743 19.2869 31.5743 19.1435 31.5743C12.2566 31.5743 6.66113 26.0323 6.66113 19.2114C6.66113 12.3906 12.2567 6.84855 19.1435 6.84855C19.2869 6.84855 19.4305 6.84855 19.5739 6.84855H19.7174C26.3173 7.13278 31.4824 12.5327 31.4824 19.2114C31.4824 25.1797 27.3216 30.0112 22.013 31.2901H22.013ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901Z",
                //   fillColor: "#FF9800",
                //   strokeColor: "#FF9800",
                //   fillOpacity: 1,
                //   anchor: new window.google.maps.Point(17, 46),
                //   scaledSize: new window.google.maps.Size(37, 37)
                // }}
                />
              </GoogleMap>
            </LoadScript>
          )}
          <div className="boxWrap d-flex flex-wrap align-items-center justify-content-between">
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`https://www.google.com/maps/?q=${location?.coordinates[1]},${location?.coordinates[0]}`}
              target="_blank"
              rel="noreferrer"
              className="contactBox d-flex align-items-center"
            >
              <div className="leftside">
                {props?.contactData?.address}
                <span>{currentAddress}</span>
              </div>
              <span className="icon icon-marker"></span>
            </a>
            <a
              href="tel:`${phoneNumber}`"
              className="contactBox d-flex align-items-center"
            >
              <div className="leftside">
                +44 {props?.contactData?.phoneNumber}
                <span>{t("PHONE_NUMBER")}</span>
              </div>
              <span className="icon icon-call"></span>
            </a>
            <a
              href="mailto:`${props.contactData.email}`"
              target="_blank"
              className=" contactBox d-flex align-items-center"
            >
              <div className="leftside">
                {props?.contactData?.email}
                <span>{t("EMAIL_ADDRESS")}</span>
              </div>
              <span className="icon icon-mail"></span>
            </a>
          </div>
          <a
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={`https://www.google.com/maps/?q=${location?.coordinates[1]},${location?.coordinates[0]}`}
            target="_blank"
            rel="noreferrer"
            className="directionIcon"
          >
            <span className="icon icon-coolicon"></span>
          </a>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ContactUs;
