import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import './searchbar.scss'
import { useTranslation } from "react-i18next";


/**
 * A functional component that represents a search bar.
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.onClear - The function to be called when the search bar is cleared.
 * @param {Function} props.onSearch - The function to be called when the search input changes.
 * @returns {JSX.Element} - The rendered search bar component.
 */
const Searchbar = (props) => {
  const {t} = useTranslation()
  let { onClear, value, onSearch } = props
  return (
    <div><div className="headWrap">
      <div className="topbar-wrap">
        <div className="col-md-9 d-inline-block searchbox">
          <InputGroup className="mb-3">
            <InputGroup.Text id="searchInput">
              <span className="icon-search"></span>
            </InputGroup.Text>
            <Form.Control
              placeholder={t("Search")}
              aria-label="Search"
              aria-describedby="searchInput"
              value={value}
              onChange={onSearch}
              onClick={() => {
                onClear && onClear();
              }}
            />
          </InputGroup>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Searchbar