/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Fade, Modal } from 'react-bootstrap';
import CustomButton from '../customButton/customButton';

import './confirmModal.scss';


/**
 * A reusable confirm modal component that displays a modal dialog with a title, description,
 * and two buttons for user confirmation.
 * @param {Object} props - The props object containing the following properties:
 *   - btnVisible: A boolean indicating whether the buttons should be visible.
 *   - modalContent: The content of the modal.
 *   - className: The CSS class name for the modal.
 *   - showModal: A boolean indicating whether the modal should be shown.
 *   - onHide: A function to handle the onHide event of the modal.
 *   - modalTitle: The title of the modal.
 *   - modalDescription: The description of the modal.
 *   - children: The child components of the modal.
 */
const ConfirmModal = (props) => {

  let [btnVisible, setVisible] = useState(props.btnVisible);
  let [modalContent, setModalContent] = useState(props.modalContent);

  /**
   * Renders a modal component with customizable content and buttons.
   * @param {string} className - Additional class names to apply to the modal.
   * @param {boolean} showModal - Determines whether the modal is shown or hidden.
   * @param {React.Component} animation - The animation to apply when showing or hiding the modal.
   * @param {function} onHide - Callback function to handle hiding the modal.
   * @param {string} modalTitle - The title of the modal.
   * @param {string} modalDescription - The description of the modal.
   * @param {React.Component} children - Additional content to render inside the modal body.
   * @param {boolean} btnVisible - Determines whether the modal footer with buttons is visible.
   *
   */

  return (
    <Modal
      className={'confirmModal ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      <div className="icon-warning"></div>
      <div className='modal-header'>
        <p>{props.modalTitle}</p>
      </div>

      <Modal.Body>
        {modalContent &&
          <p className="modalContent">{props.modalDescription}</p>
        }
        {props?.children}
      </Modal.Body>

      {btnVisible && <div className='modal-footer'>
        <CustomButton type="submit" className="outline_btn" title={props.leftBtnTitle} onClick={() => { props.onProcced(true) }} />
        <CustomButton type="submit" title={props.rightBtnTitle} onClick={() => { props.onHide(false) }} />
      </div>}
    </Modal>
  )
}

ConfirmModal.defaultProps = {
  className: 'confirmModal',
  btnVisible: true,
  modalContent: true
}

export default (ConfirmModal);