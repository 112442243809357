/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { get, cloneDeep } from "lodash";
import setHours from "date-fns/setHours";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import Constant from "../../util/constant";
import HookForm from "../hookForm/hookForm";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import setMinutes from "date-fns/setMinutes";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";

import { showToast } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";
import { addBusiness } from "../../state/ducks/businessListDetails/actions";


import Label from "../label/label";
import CheckBox from "../checkbox/checkBox";
import TextField from "../textField/textField";
import TimePicker from "../timePicker/timePicker";
import CustomButton from "../customButton/customButton";
import CustomDropdown from "../customDropdown/customDropdown";
import CustomToggleButton from "../customToggleButton/customToggleButton";
import TextFieldNoRequired from "../textFieldNoRequired/textFieldNoRequired";
import GooglePlaceDropDown from "../googlePlaceDropDown/googlePlaceDropDown";

import "./customAddBusiness.scss";
import CustomeSelectBox from "../customeSelectBox/customeSelectBox";

const listing = [
  {
    day: "Mon",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
  {
    day: "Tue",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
  {
    day: "Wed",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
  {
    day: "Thu",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
  {
    day: "Fri",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
  {
    day: "Sat",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
  {
    day: "Sun",
    startTime: "09:00",
    endTime: "17:00",
    is24Hours: false,
    isDayActive: false,
    isDisable: false,
  },
];

const CustomAddBusiness = (props) => {
  let { onClickCancel, item, count, onReload, callback } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form, setLoginForm] = useState();
  const [dayMe, setDayMe] = useState({});
  const dimensions = useWindowDimension();
  const [busy, setBusy] = useState(false);
  const [endTime, setEndTime] = useState([]);
  const [startTime, setStartTime] = useState([]);
  const [serviceName, setServiceName] = useState();
  const [titleHeight, SetTitleHeight] = useState("");
  const [buttonHeight, SetButtonHeight] = useState("");
  const [headerHeight, SetHeaderHeight] = useState("");
  const [categoryId, setCategoryId] = useState(item._id);
  const [portLocation, setPortLocation] = useState(null);
  const [selectedItem, setSelectedItem] = useState(item);
  const [characterCount, setcharacterCount] = useState(0);
  const [addbusinessHeight, SetAddBusinessHeight] = useState("");
  const [dayListing, setDayListing] = useState(cloneDeep(listing));
  const [businessLiveLink, setBusinessLiveLink] = useState(0);
  const [selectedTowns, setSelectedTowns] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState();

  /**
   * Retrieves the category list for color from the Redux store using the useSelector hook.
   * @param {Function} state - The state object from the Redux store.
   * @returns The category list for color.
   */
  const colorList = useSelector(
    (state) => state?.CategoryDetails?.getCategoryListForColor?.categoryList
  );

  const townList = useSelector(state => state?.townDetails?.townList?.payload)

  /**
   * useEffect hook that updates the selected item whenever the item prop changes.
   * @param {any} item - The item to set as the selected item.
   * @returns None
   */
  useEffect(() => {
    console.log("item", item);
    setSelectedItem(item);
  }, [item]);

  /**
   * useEffect hook that updates the serviceName state variable whenever the colorList changes.
   * It maps over the colorList array and creates a new array of objects with the id, value, and color properties.
   * The new array is then assigned to the serviceName state variable using the setServiceName function.
   * @param {Array} colorList - The list of colors to map over.
   * @returns None
   */
  useEffect(() => {
    let name = colorList?.map((item) => {
      let colorData = {
        id: item._id,
        value: item.categoryName,
        color: item.color,
      };
      return colorData;
    });
    setServiceName(name);
    setSelectedItem(colorList.find((i) => i._id === item._id))
  }, [colorList]);

  /**
   * Renders a custom start time button component.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the start time.
   * @param {function} props.onClick - The function to be called when the button is clicked.
   * @returns {JSX.Element} - The rendered custom start time button component.
   */
  const StartTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">-- : --</span>
        )}
      </button>
    );
  };

  /**
   * Renders a button component for displaying an end time value.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the end time.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered button component.
   */
  const EndTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">-- : --</span>
        )}
      </button>
    );
  };

  /**
   * Updates the start time array with the new time value at the specified index.
   * @param {Array} times - The new time value to update.
   * @param {number} index - The index of the start time array to update.
   * @returns None
   */
  const onChangeStartTime = (times, index) => {
    let temp_time = startTime;
    temp_time[index] = times;
    setStartTime(temp_time);
  };

  /**
   * Updates the end time value at the specified index in the times array.
   * @param {Array} times - The array of times.
   * @param {number} index - The index of the time to update.
   * @returns None
   */
  const onChangeEndTime = (times, index) => {
    let temp_time = endTime;
    temp_time[index] = times;
    setEndTime(temp_time);
  };

  /**
   * An object representing a form for adding business information.
   * Each property in the object represents a form field with its name and validation rules.
   * @property {object} businessName - The business name field.
   * @property {string} businessName.name - The name of the field.
   * @property {object} businessName.validate - The validation rules for the field.
   * @property {object} businessName.validate.required - The required validation rule for the field.
   * @property {boolean} businessName.validate.required.value - The value indicating if the field is required.
   * @property {string} businessName.validate.required.message - The error message to display if the field is required.
   * @property {object} businessName.validate.pattern -
   */
  const addBusinessForm = {
    businessName: {
      name: "businessName",
      validate: {
        required: {
          value: true,
          message: t("BUSINESS_NAME_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: t("ONLY_ALPHABETS_NUMBER_ACCENTS"),
        },
      },
    },
    descripton: {
      name: "descripton",
      validate: {
        required: {
          value: true,
          message: t("DESCRIPTION_REQUIRED"),
        },
      },
    },
    categroy: {
      name: "categroy",
      validate: {
        required: {
          value: false,
          message: t("required"),
        },
      },
    },
    town: {
      name: "towns",
      validate: {
        required: {
          value: true,
          message: t("TOWN_REQUIRED"),
        },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: !onlineStatus,
          message: t("ADDRESS_REQUIRED"),
        },
      },
    },
    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: !onlineStatus,
        },
      },
    },
    starttime: {
      name: "starttime",
      validate: {
        required: {
          value: false,
        },
      },
    },
    endtime: {
      name: "endtime",
      validate: {
        required: {
          value: false,
        },
      },
    },
    email: {
      name: "email",
      validate: {
        required: {
          value: false,
          message: t("EMAIL_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("EMAIL_INVALID"),
        },
      },
    },
    cunrtyCode: {
      name: "cunrtyCode",
      validate: {},
    },
    phoneNumber: {
      name: "phoneNumber",
      validate: {
        required: {
          value: false,
          message: t("PHONE_NUMBER_REQUIRED"),
        },
        maxLength: { value: 11, message: t("PHONE_NUMBER_INVALID") },
        minLength: { value: 10, message: t("PHONE_NUMBER_INVALID") },
      },
    },
    website: {
      name: "website",
      validate: {
        required: {
          value: false,
          message: t("WEBSITE_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    facebookLink: {
      name: "facebookLink",
      validate: {
        required: {
          value: false,
          message: t("FACEBOOK_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    twitterLink: {
      name: "twitterLink",
      validate: {
        required: {
          value: false,
          message: t("TWITTER_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    instagramLink: {
      name: "instagramLink",
      validate: {
        required: {
          value: false,
          message: t("INSTAGRAM_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    linkedinLink: {
      name: "linkedinLink",
      validate: {
        required: {
          value: false,
          message: t("LINKEDIN_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    Livelink: {
      name: "Livelink",
      validate: {
        required: {
          value: true,
          message: t("BUSINESS_LIVE_LINK_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    online: {
      name: "online",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };

  /**
   * useEffect hook that sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("header")[0]?.offsetHeight);
    SetTitleHeight(
      document.getElementsByClassName("add_no_business")[0]?.offsetHeight
    );
    SetAddBusinessHeight(
      document.getElementsByClassName("add_business_title")[0]?.offsetHeight
    );
    SetButtonHeight(
      document.getElementsByClassName("custombtnfield")[0]?.offsetHeight
    );
  }, []);

  /**
   * Handles the form submission event and performs the necessary actions.
   * @param {Event} e - The form submission event.
   * @returns None
   */
  const onFormSubmit = async (e) => {
    let data = [];
    dayListing.map((item, index) => {
      if (item.endTime === "--:--") {
        item["endTime"] = "23:59";
      }
      item["startTime"] = item.isDisable
        ? "00:00"
        : moment(`${moment().format("yyyy/MM/DD")}  ${item.startTime}`).format(
          "HH:mm"
        );
      item["endTime"] = item.isDisable
        ? "23:59"
        : moment(`${moment().format("yyyy/MM/DD")} ${item.endTime}`).format(
          "HH:mm"
        );
      data.push(item);
    });

    let url = "https://";

    let facebook = "";
    let instagram = "";
    let twitter = "";
    let linked = "";
    let websiteData = "";

    if (e.facebookLink !== "undefined" && e.facebookLink !== "") {
      facebook =
        e?.facebookLink?.startsWith("http" || "https") === false
          ? url.concat(e?.facebookLink)
          : e?.facebookLink;
    }

    if (e.instagramLink !== "undefined" && e.instagramLink !== "") {
      instagram =
        e?.instagramLink?.startsWith("http" || "https") === false
          ? url.concat(e?.instagramLink)
          : e?.instagramLink;
    }

    if (e.twitterLink !== "undefined" && e.twitterLink !== "") {
      twitter =
        e?.twitterLink?.startsWith("http" || "https") === false
          ? url.concat(e?.twitterLink)
          : e?.twitterLink;
    }

    if (e.linkedinLink !== "undefined" && e.linkedinLink !== "") {
      linked =
        e?.linkedinLink?.startsWith("http" || "https") === false
          ? url.concat(e?.linkedinLink)
          : e?.linkedinLink;
    }

    if (e.website !== "undefined" && e.website !== "") {
      websiteData =
        e?.website?.startsWith("http" || "https") === false
          ? url.concat(e?.website)
          : e?.website;
    }

    let params = {
      name: e.businessName.trim(),
      description: e.descripton.trim(),
      categoryId: selectedItem && selectedItem?._id,
      email: e.email,
      phoneNumber: e.phoneNumber,
      facebookLink: facebook,
      instagramLink: instagram,
      twitterLink: twitter,
      linkedLink: linked,
      websiteLink: websiteData,
      status: Constant.STATUS.ENABLE,
      availableDayTime: data,
      towns: e.towns.map(town => town.value),
      onlineStatus: e.online,
    };

    if (e.online) params.liveLink = e.Livelink
    if (e.address) params.address = e.address
    if (e?.portLocation?.location?.long && e?.portLocation?.location?.lat) {
      params.longitude = e.portLocation.location.long
      params.latitude = e.portLocation.location.lat
    }
    console.log("add params ==>", params);
    try {
      setBusy(true);
      const res = await dispatch(addBusiness(params))
      props.callback && callback();
      onClickCancel();
      props.onReload && onReload();
      setDayListing([...listing]);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  return (
    <div className="CustomAddBusiness">
      <div className="add_Business">
        <div className="add_business_container">
          {/* {count > 0 ? (
            <div />
          ) : (
            <div className="add_no_business">
              {t("_PLEASE_FILL_THE_FOLLOWING_FORM_TO_ADD_ONE")}
            </div>
          )} */}
          <div className="add_business_card">
            <div className="add_business_title">{t("ADD_SERVICE")}</div>
            <HookForm
              defaultForm={{}}
              init={(form) => setLoginForm(form)}
              onSubmit={(e) => onFormSubmit(e)}
            >
              {(formMethod) => {
                return (
                  <>
                    <Scrollbars
                      className="MainScrolldiv"
                      style={{
                        height:
                          dimensions.height -
                          headerHeight -
                          titleHeight -
                          addbusinessHeight -
                          300,
                      }}
                    >
                      <div className="padding_inner">
                        <Label title={t("SERVICE_INFORMATION")} />
                        <div className="businessName_count_wrapper">
                          <TextField
                            formMethod={formMethod}
                            rules={addBusinessForm.businessName.validate}
                            name={addBusinessForm.businessName.name}
                            errors={formMethod?.formState.errors}
                            // autoFocus={true}
                            type="text"
                            textFieldClassName="fullTextBox"
                            autoComplete="on"
                            placeholder={t("Service_name")}
                            maxLength={100}
                            onChange={(e) =>
                              setBusinessLiveLink(e.target.value.length)
                            }
                          />
                          <div className="businessName_count">
                            {businessLiveLink}/100
                          </div>
                        </div>

                        <div className="discription_txt">
                          <TextField
                            formMethod={formMethod}
                            rules={addBusinessForm.descripton.validate}
                            name={addBusinessForm.descripton.name}
                            errors={formMethod?.formState.errors}
                            onChange={(e) =>
                              setcharacterCount(e.target.value.length)
                            }
                            // autoFocus={true}
                            autoComplete="on"
                            placeholder={t("Description")}
                            textarea="textarea"
                            maxLength={550}
                          />
                          <div className="textarea_count">
                            {characterCount}/550
                          </div>
                        </div>
                        <div className="input_row">
                          <div className="colorDropdownWrapper">
                            <span
                              className="cricle_select_view"
                              style={{ backgroundColor: selectedItem?.color }}
                            ></span>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <CustomDropdown
                                  dropDownItems={serviceName}
                                  placeholder={t("SELECT_CATEGORY")}
                                  selectedValue={selectedItem?.categoryName}
                                  defaultValue={selectedItem?.categoryName}
                                  ColorSelected={true}
                                  onSelect={(evt) => {
                                    onChange(evt);
                                    const data = {
                                      color: evt.color,
                                      _id: evt.id,
                                      categoryName: evt.value,
                                    };

                                    setSelectedItem(data);
                                    setCategoryId(evt.id);
                                  }}
                                ></CustomDropdown>
                              )}
                              control={formMethod.control}
                              name={addBusinessForm.categroy.name}
                              rules={addBusinessForm.categroy.validate}
                            />
                            <span className="arrow-dropdown icon-sorting"></span>
                          </div>
                        </div>
                        <div className="input_row">
                          <div className="townWrapper">
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <CustomeSelectBox
                                  placeholder={
                                    <>
                                      {t("SELECT_TOWN")}
                                      <span style={{ color: 'red' }}>*</span>
                                    </>
                                  }
                                  defaultValue={selectedTowns}
                                  options={townList || []}
                                  isMulti={true}
                                  className={
                                    selectedTowns?.length > 1 &&
                                    "multipleSelectBox"
                                  }
                                  onChange={(evt) => {
                                    onChange(evt);
                                    setSelectedTowns(evt);
                                  }}
                                />
                              )}
                              control={formMethod.control}
                              name={addBusinessForm.town.name}
                              rules={addBusinessForm.town.validate}
                              errors={formMethod?.formState.errors}
                            />
                            <span className="arrow-dropdown icon-sorting"></span>
                          </div>
                        </div>
                        <div className="input_row">
                          <div className="onlineWrapper">
                            <Controller
                              defaultValue={false}
                              control={formMethod?.control}
                              name={addBusinessForm.online.name}
                              rules={addBusinessForm.online.validate}
                              render={({ field: { onChange, value } }) => (
                                <div className="OnlineSwitchWrapper">
                                  <p>{t("ONLINE")}</p>
                                  <CustomToggleButton
                                    id=""
                                    checked={value}
                                    // onCheckChange={onChange}
                                    onCheckChange={(e) => { onChange(e); setOnlineStatus(e); }}
                                  />
                                </div>
                              )}
                            />
                            {formMethod.getValues("online") &&
                              <TextField
                                formMethod={formMethod}
                                rules={addBusinessForm.Livelink.validate}
                                name={addBusinessForm.Livelink.name}
                                errors={formMethod?.formState.errors}
                                type="text"
                                autoComplete="on"
                                placeholder={t("Live_link")}
                                maxLength={250}
                              />
                            }
                          </div>
                        </div>

                        <div className="ServiceaddressWrapper">
                          <Label
                            className="title_top"
                            title={t("Service_address_and_location")}
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={addBusinessForm.address.validate}
                            name={addBusinessForm.address.name}
                            errors={onlineStatus ? {} : formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("LOCATION")}
                            maxLength={150}
                            isRequired={onlineStatus ? false : true}
                          />
                          <div className="location_part">
                            <div>
                              <Controller
                                control={formMethod?.control}
                                name={addBusinessForm.portLocation.name}
                                rules={addBusinessForm.portLocation.validate}
                                render={({ field: { onChange, value } }) => (
                                  <GooglePlaceDropDown
                                    isRequired={onlineStatus ? false : true}
                                    defaultValue={{
                                      label: portLocation?.location?.address || '',
                                      value: portLocation?.location?.address || '',
                                    }}
                                    getLocation={(place) => {
                                      if (place === null) {
                                        setPortLocation(null)
                                      } else {
                                        setPortLocation({
                                          location: {
                                            latitude: place?.location?.lat || 1,
                                            longitude: place?.location?.long || 1,
                                            address: place.address || "",
                                          }
                                        })
                                      }
                                      onChange(place);
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="map_view">
                              <iframe
                                title={t("map")}
                                className="map"
                                src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                  }&q=${get(
                                    portLocation,
                                    "location.latitude",
                                    1
                                  )},${get(
                                    portLocation,
                                    "location.longitude",
                                    1
                                  )}`}
                                width="100%"
                                height="330px"
                                frameBorder="0"
                                allowFullScreen={false}
                                aria-hidden={false}
                                tabIndex={0}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="servicetimingWrapper">
                          <Label className="title_top" title={t("SERVICE_TIMING")} />
                          <div className="businesstiming">
                            <div className="timing_heading">
                              <ul>
                                <li>{t("AVAILABLE_DAYS")}</li>
                                <li>{t("START_TIME")}</li>
                                <li>{t("END_TIME")}</li>
                                <li>{t("24_HOURS")}</li>
                              </ul>
                            </div>
                            <div className="timing_list">
                              {dayListing?.map((item, index) => {
                                return (
                                  <ul key={index}>
                                    <li className="list_col">
                                      <div className="inputbox">
                                        <div className="day">{item.day}</div>
                                        <CustomToggleButton
                                          id={"day" + index}
                                          checked={item?.isDayActive}
                                          onCheckChange={(checked) => {
                                            dayListing[index].isDayActive =
                                              checked;
                                            setDayListing([...dayListing]);
                                          }}
                                        />
                                      </div>
                                    </li>
                                    <li className="list_col">
                                      <div
                                        className={
                                          dayMe["wholeday" + index]
                                            ? "inputbox disable"
                                            : "inputbox"
                                        }
                                      >
                                        <Controller
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <TimePicker
                                              selected={startTime?.[index]}
                                              onChange={(e) => {
                                                let data =
                                                  moment(e).format("HH:mm");

                                                dayListing[index].startTime =
                                                  data;
                                                setDayListing([...dayListing]);
                                                onChangeStartTime(e, index);
                                                onChange(e);
                                                dayListing[index].endTime =
                                                  "--:--";
                                              }}
                                              value={
                                                dayListing[index].startTime
                                              }
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              customInput={<StartTime />}
                                              showTimeSelect={true}
                                              showTimeSelectOnly={true}
                                              timeIntervals={15}
                                              isClearable={false}
                                              disabled={
                                                dayMe["wholeday" + index]
                                              }
                                              minTime
                                              maxTime={setHours(
                                                setMinutes(new Date(), 30),
                                                20
                                              )}
                                            />
                                          )}
                                          control={formMethod.control}
                                          name={addBusinessForm.starttime.name}
                                          rules={
                                            addBusinessForm.starttime.validate
                                          }
                                          defaultValue={""}
                                        />
                                      </div>
                                    </li>
                                    <li className="list_col">
                                      <div
                                        className={
                                          dayMe["wholeday" + index]
                                            ? "inputbox disable"
                                            : "inputbox"
                                        }
                                      >
                                        <Controller
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <TimePicker
                                              selected={endTime?.[index]}
                                              onChange={(e) => {
                                                let data =
                                                  moment(e).format("HH:mm");

                                                dayListing[index].endTime =
                                                  data;
                                                setDayListing([...dayListing]);
                                                onChangeEndTime(e, index);
                                                onChange(e);
                                              }}
                                              value={dayListing[index].endTime}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              customInput={<EndTime />}
                                              showTimeSelect={true}
                                              showTimeSelectOnly={true}
                                              timeIntervals={15}
                                              isClearable={false}
                                              minTime={
                                                new Date(
                                                  moment(
                                                    new Date(
                                                      formMethod.watch(
                                                        "starttime"
                                                      )
                                                    )
                                                  )
                                                    .add(15, "minutes")
                                                    .format()
                                                )
                                              }
                                              maxTime={setHours(
                                                setMinutes(new Date(), 59),
                                                23
                                              )}
                                              disabled={
                                                dayMe["wholeday" + index]
                                                  ? true
                                                  : !startTime?.[index]
                                              }
                                            />
                                          )}
                                          control={formMethod.control}
                                          name={addBusinessForm.endtime.name}
                                          rules={
                                            addBusinessForm.endtime.validate
                                          }
                                          defaultValue={""}
                                        />
                                      </div>
                                    </li>
                                    <li className="list_col">
                                      <div className="inputbox">
                                        <CheckBox
                                          id={"wholeday" + index}
                                          checked={dayMe["wholeday" + index]}
                                          onCheckedChange={(checked) => {
                                            let temp_check = { ...dayMe };
                                            dayListing[index].is24Hours =
                                              checked;
                                            dayListing[index].isDisable =
                                              checked;
                                            setDayListing([...dayListing]);
                                            temp_check["wholeday" + index] =
                                              checked;
                                            setDayMe(temp_check);
                                          }}
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="ContactinformationWrapper">
                          <Label
                            className="title_top"
                            title={t("CONTACT_INFORMATION")}
                          />
                          <TextFieldNoRequired
                            formMethod={formMethod}
                            rules={addBusinessForm.email.validate}
                            name={addBusinessForm.email.name}
                            errors={formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("EMAIL")}
                            iconLeftShow={true}
                            iconClassLeft="icon-email"
                            textFieldClassName="fullTextBox"
                          />
                          <div className="countryPhoneGroup">
                            <Controller
                              defaultValue={"44"}
                              control={formMethod?.control}
                              name={addBusinessForm.cunrtyCode.name}
                              rules={addBusinessForm.cunrtyCode.validate}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <span className="icon-phone"></span>
                                  <PhoneInput
                                    country={"gb"}
                                    disableSearchIcon="false"
                                    placeholder={t("+44")}
                                    enableSearch="true"
                                    value={formMethod.watch(
                                      addBusinessForm.cunrtyCode.name
                                    )}
                                    onChange={onChange}
                                  />
                                </>
                              )}
                            />
                            <TextFieldNoRequired
                              redStar={true}
                              noTextfield={true}
                              onChange={(e) =>
                                this.onPhoneChange(formMethod, e)
                              }
                              maskType="99999999999"
                              formMethod={formMethod}
                              rules={addBusinessForm.phoneNumber.validate}
                              name={addBusinessForm.phoneNumber.name}
                              errors={formMethod?.formState.errors}
                              placeholder={t("PHONE_NUMBER")}

                            />
                          </div>
                          <TextFieldNoRequired
                            formMethod={formMethod}
                            rules={addBusinessForm.website.validate}
                            name={addBusinessForm.website.name}
                            errors={formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("WEBSITE_URL")}
                            iconLeftShow={true}
                            textFieldClassName="fullTextBox"
                            iconClassLeft="icon-link"
                          />
                          <TextFieldNoRequired
                            formMethod={formMethod}
                            rules={addBusinessForm.facebookLink.validate}
                            name={addBusinessForm.facebookLink.name}
                            errors={formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("FACEBOOK_URL")}
                            iconLeftShow={true}
                            textFieldClassName="fullTextBox"
                            iconClassLeft="icon-facebook"
                          />
                          <TextFieldNoRequired
                            formMethod={formMethod}
                            rules={addBusinessForm.twitterLink.validate}
                            name={addBusinessForm.twitterLink.name}
                            errors={formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("TWITTER_URL")}
                            iconLeftShow={true}
                            textFieldClassName="fullTextBox"
                            iconClassLeft="icon-twitter"
                          />
                          <TextFieldNoRequired
                            formMethod={formMethod}
                            rules={addBusinessForm.instagramLink.validate}
                            name={addBusinessForm.instagramLink.name}
                            errors={formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("INSTAGRAM_URL")}
                            iconLeftShow={true}
                            textFieldClassName="fullTextBox"
                            iconClassLeft="icon-instagram"
                          />
                          <TextFieldNoRequired
                            formMethod={formMethod}
                            rules={addBusinessForm.linkedinLink.validate}
                            name={addBusinessForm.linkedinLink.name}
                            errors={formMethod?.formState.errors}
                            type="text"
                            autoComplete="on"
                            placeholder={t("LINKEDIN_URL")}
                            iconLeftShow={true}
                            textFieldClassName="fullTextBox"
                            iconClassLeft="icon-linkedin"
                          />
                        </div>
                      </div>
                    </Scrollbars>
                    <div className="custombtnfield">
                      <CustomButton
                        type="submit"
                        title={t("ADD")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                      <CustomButton
                        type="button"
                        onClick={onClickCancel}
                        className="outline_btn"
                        title={t("CANCEL")}
                      />
                    </div>
                  </>
                );
              }}
            </HookForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAddBusiness;
