/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import AxiosInstance from "../../services/axios";

import Header from "../../layout/header/header";
import Filter from "../../components/filter/filter";
import ViewDetails from "../../components/viewDetails/viewDetails";

import "./bookmark.scss";
import Constant from "../../util/constant";
import { Helmet } from 'react-helmet';

/**
 * A functional component that represents a bookmark page.
 * @returns JSX elements representing the bookmark page.
 */
const Bookmark = () => {
  const { t } = useTranslation();
  let serviceFilter = localStorage.getItem("serviceFilter");
  let eventFilter = localStorage.getItem("eventFilter");
  const bookMarkBookList =
    JSON.parse(localStorage.getItem("bookmark_data")) || [];
  let filterWrap = JSON.parse(localStorage.getItem("homeFilter"));
  let selectedTown = localStorage.getItem("selectedTown") || 'All';

  const [toggleFilter, setToggleFilter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [viewPage, setViewPage] = useState({});
  const [bookmark, setBookmark] = useState([]);
  const [categories, setCategories] = useState([]);

  const [checkToggle, setCheckToggle] = useState(true);
  const [service, setService] = useState(null);
  const [event, setEvent] = useState(null);
  const [town, setTown] = useState('All')
  const [bookMarkCount, setBookMarkCount] = useState(0);

  /**
   * useEffect hook that filters the bookMarkBookList based on the type and eventEndDate.
   * Updates the state variables bookmark and bookMarkCount with the filtered results.
   * @param {boolean} showModal - Indicates whether the modal is being shown or not.
   * @returns None
   */
  useEffect(() => {
    let filteredBookmark = bookMarkBookList.map((item) => {
      if (item.type === "event" && item.eventEndDate && item.eventEndTime) {
        const date = moment(item.eventEndDate).utc();
        const time = moment(item.eventEndTime, 'HH:mm');
        const result = date.add(time.hours(), 'hours').add(time.minutes(), 'minutes');
        const isFutureEvent = result.diff(moment(), "minutes") > 0;
        return isFutureEvent ? { ...item, isEnded: false } : { ...item, isEnded: true, isActive: 'Ended' };
      } else {
        return { ...item, isEnded: false }; // Include items other than events
      }
    });
    setBookmark(filteredBookmark);
    setBookMarkCount(filteredBookmark?.length);
  }, [showModal]);

  /** 
   * Runs the getFilterCategory function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    setService(serviceFilter === 'true' ? true : serviceFilter === 'false' ? false : true)
    setEvent(eventFilter === 'true' ? true : eventFilter === 'false' ? false : true)
    getFilterCategory();
    setTown(selectedTown)
  }, []);

  /**
   * Executes the filterBookMarkData function whenever there is a change in the categories,
   * service, event, or checkToggle dependencies.
   * @returns None
   */
  useEffect(() => {
    if (service !== null && event !== null) {
      filterBookMarkData();
    }
  }, [categories, service, event, checkToggle, town]);

  useEffect(() => {
    if (event === true) {
      const uncategorisedExits = categories.some((cat) => cat.categoryName === 'Uncategorised')
      if (!uncategorisedExits) {
        let obj = {
          categoryName: "Uncategorised",
          color: "#000000",
          status: Constant.STATUS.ENABLE,
          ischecked: true,
        };
        setCategories([obj, ...categories])

      }
    } else {
      let updatedData = categories?.filter((item) => {
        return !(item.categoryName === "Uncategorised");
      });
      setCategories(updatedData)
    }
  }, [event, categories.length])

  /**
   * Filters the bookmark data based on certain conditions and sets the filtered data as the new bookmark state.
   * @returns None
   */
  const filterBookMarkData = async () => {
    let mainBookmark = JSON.parse(localStorage.getItem("bookmark_data"));
    let filteredBookmark = mainBookmark?.map((item) => {
      if (item.type === "event" && item.eventEndDate && item.eventEndTime) {
        const date = moment(item.eventEndDate).utc();
        const time = moment(item.eventEndTime, 'HH:mm');
        const result = date.add(time.hours(), 'hours').add(time.minutes(), 'minutes');
        const isFutureEvent = result.diff(moment(), "minutes") > 0;
        return isFutureEvent ? { ...item, isEnded: false } : { ...item, isEnded: true, isActive: 'Ended' };
      } else {
        return { ...item, isEnded: false }; // Include items other than events
      }
    });

    if (town !== 'All') {
      filteredBookmark = filteredBookmark?.filter(data => data?.towns.includes(town))
    }
    const data = filteredBookmark?.filter((item, index) => {
      return categories?.some(function (o2) {
        if (event == true && service == true) {
          return o2.status == Constant.STATUS.ENABLE && (item.categoryName ? item.categoryName === o2.categoryName : o2.categoryName === 'Uncategorised')
        } else if (event == true && service == false) {
          return (
            o2.status == Constant.STATUS.ENABLE &&
            item.type == "event" &&
            (item.categoryName ? item.categoryName === o2.categoryName : o2.categoryName === 'Uncategorised')
          );
        } else if (event == false && service == true) {
          return (
            o2.status == Constant.STATUS.ENABLE &&
            item.type == "service" &&
            (item.categoryName ? item.categoryName === o2.categoryName : o2.categoryName === 'Uncategorised')
          );
        } else {
          // return o2.status == Constant.STATUS.ENABLE && (item.categoryName ? item.categoryName === o2.categoryName : true);
          return false
        }
      });
    });

    setBookmark(data);
    setBookMarkCount(data?.length);
  };

  /**
   * Retrieves the filter category data from the server and updates the state with the retrieved data.
   * @returns None
   */
  const getFilterCategory = async () => {
    try {
      await AxiosInstance.get("user/getCategory").then((response) => {

        let temp = response?.payload;
        if (event !== false) {
          let obj = {
            categoryName: t("Uncategorised"),
            color: "#000000",
            status: Constant.STATUS.ENABLE,
          };
          temp.unshift(obj);
        }

        const tempArray = temp?.map((item, index) => {
          const filterData = filterWrap?.some(
            (value) => value._id === item._id
          );
          if (filterData) {
            item["status"] = Constant.STATUS.DISABLE;
          }
          return item;
        });

        setCategories(tempArray);
      });
    } catch (error) { }
  };

  /**
   * Determines the current status (open or closed) based on the given dayTimeArray and status.
   * @param {Array} dayTimeArray - An array of objects containing day and time information.
   * @param {string} status - The current status.
   * @returns {string} - The current status (open or closed).
   */
  const timingStatus = (dayTimeArray = [], status) => {
    const today = moment().utc();
    const day = today.format("ddd");
    let currentDay = {};
    let endTime;
    let startTime;
    let closeText = "Closed";
    currentDay = dayTimeArray.find((d) => d.day === day);
    if (Object.keys(currentDay).length > 0) {
      startTime = moment(
        `${today.format("DD/MM/YYYY")} ${currentDay.startTime}`,
        "DD/MM/YYYY HH:mm"
      );
      endTime = moment(
        `${today.format("DD/MM/YYYY")} ${currentDay.endTime}`,
        "DD/MM/YYYY HH:mm"
      );
      if (today.isBetween(startTime, endTime) && status !== "Close") {
        closeText = "Open";
      }
    }
    return closeText;
  };

  /**
   * Renders a component that displays a list of bookmarks. The component includes
   * meta tags for SEO purposes, a filter section, and a list of bookmarked items.
   * @returns The rendered JSX component.
   */
  return (
    <>
      <Helmet>
        <title>Bookmarks | Angus Recovery Road Map</title>
      </Helmet>
      <div className={toggleFilter ? "filterWrap" : "filterWrap active"}>
        <Filter
          BookmarkFilter={bookMarkCount}
          onclick={() => setToggleFilter(!toggleFilter)}
          iconChange={toggleFilter ? "icon-filter" : "icon-close"}
          categoriesData={categories}
          isFromToggle={true}
          onCheckedServices={(value) => {
            setService(value);
          }}
          onCheckedEvent={(value) => {
            setEvent(value);
          }}
          onUpdatedCategoryData={(value) => {
            setCategories([...value]);
          }}
          onUpdatedTown={(value) => {
            setTown(value);
          }}
          selectedTown={town}
          filterToggleWrap={checkToggle}
          filterServiceWrap={service}
          filterEventWrap={event}
        />
      </div>
      <div className={toggleFilter ? "mainWrap" : "mainWrap move"}>
        <Header />
        <ViewDetails
          mapData={viewPage}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
        <div className="bookmarkWrap listVieWrap">
          {!bookmark || bookmark?.length === 0 ? (
            <div className="no_data">
              <i className="icon-info_circle_outline"></i>
              <p>{t("OOPS_NO_SERVICE_CENTRES_OR_EVENTS_BOOKMARKED_YET")}</p>
            </div>
          ) : (
            bookmark?.map((item, index) => {
              let displayTime;
              let startDate;
              let endDate;
              let st = "Open";
              if (item.type === "event") {
                startDate = moment(`${moment(item?.eventStartDate)
                  .utc()
                  .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
                if (item?.eventEndDate) {
                  endDate = moment(`${moment(item?.eventEndDate)
                    .utc()
                    .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                } else {
                  endDate = "--:--";
                }
              } else {
                let currentDay = moment().format("ddd");
                let data = item?.availableDayTime?.filter((businessItem) => {
                  if (businessItem?.day === currentDay) {
                    return businessItem;
                  } else if (businessItem?.day !== currentDay) {
                  }
                });
                st = timingStatus(item?.availableDayTime, item?.isActive);
                displayTime = data[0]?.isDayActive
                  ? data[0].is24Hours
                    ? "24 Hours available"
                    : `${data[0].startTime} to ${data[0].endTime}`
                  : "Closed";
              }

              return (
                <div
                  key={index}
                  onClick={() => {
                    setShowModal(true);
                    setViewPage(item);
                    item["isbookmarked"] = true;
                  }}
                  style={{ color: item.color }}
                  className={item?.isEnded === true ? "eventBox disabled " : "eventBox "}
                >
                  {item?.type === "event" ? (
                    <span className="icon icon-activity-pin"></span>
                  ) : (
                    <span className="icon icon-service-pin"></span>
                  )}
                  <div className="cardContent">
                    <div className="eventTop ">
                      <h2>
                        {item?.name}
                        {(item?.distance || item.distance == 0) && (
                          <span className="distance">
                            {parseFloat(item?.distance).toFixed(2)} miles
                          </span>
                        )}
                      </h2>
                      <span className="category">{item?.categoryName}</span>
                      {item?.towns?.length > 0 &&
                        <div className="TownUl">
                          <ul>
                            {item?.towns?.map((town, id) => <li key={id}>{town}</li>)}
                          </ul>
                        </div>
                      }
                    </div>
                    {item?.isActive !== "Open" && item?.isActive !== "open" ? (
                      <div className="timing">
                        <span>Timing - </span> {item?.isActive}
                      </div>
                    ) : (
                      <div className="timing">
                        {" "}
                        <span>{item.timing}</span>
                      </div>
                    )}
                    <div className="date">
                      {item?.type === "event"
                        ? `${startDate} - ${endDate}`
                        : displayTime}
                    </div>
                    <div className="btn-wrap">
                      {item.location &&
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="btn btn-primary direction"
                          href={`https://www.google.com/maps/?q=${item.location.coordinates[1]},${item.location.coordinates[0]}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon icon-coolicon"></span>
                          {t("DIRECTIONS")}
                        </a>
                      }
                      <Button
                        className="bookmark"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowModal(false);
                          const getbookMarkBookArr = JSON.parse(
                            localStorage.getItem("bookmark_data")
                          );
                          const isBookMarkAdded = getbookMarkBookArr?.some(
                            (itemData) => item._id === itemData._id
                          );
                          if (isBookMarkAdded) {
                            if (
                              getbookMarkBookArr &&
                              getbookMarkBookArr.length > 0
                            ) {
                              const newData = getbookMarkBookArr?.filter(
                                (val) => item._id !== val._id
                              );
                              setBookmark(newData);
                              setBookMarkCount(newData?.length)
                              localStorage.setItem(
                                "bookmark_data",
                                JSON.stringify(newData)
                              );
                            }
                          }
                        }}
                      >
                        <span className="icon icon-bin"></span>
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
export default Bookmark;
