import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./calender.scss";

const Calender = (props) => {

  /**
   * Destructures the properties from the given object and assigns them to individual variables.
   * @param {object} props - The object containing the properties to destructure.
   * @returns None
   */
  let {
    showYearDropdown,
    showMonthDropdown,
    wrapperClassName,
    startDate,
    endDate,
    value,
    selectsRange,
    showMonthYearPicker,
    showYearPicker,
    maxDate,
    minDate,
    isClearable,
  } = props;

   /**
     * Renders a custom calendar component with various props.
     * @param {Object} props - The props for the calendar component.
     * @param {Date} props.selected - The selected date.
     * @param {string} props.dateFormat - The format of the date to display.
     * @param {string} props.placeholderText - The placeholder text to display when no date is selected.
     * @param {Function} props.onChange - The function to call when the date is changed.
     * @param {boolean} props.showYearDropdown - Whether to show the year dropdown.
     * @param {boolean} props.showMonthDropdown - Whether to show the month dropdown.
     * @param {boolean} props.showTimeInput - Whether to show the time input.
     */
  return (
    <div className="calenderCustomContainer">
      <DatePicker
        selected={props.selected}
        dateFormat={props.dateFormat}
        placeholderText={props.placeholderText}
        onChange={(e) => props.onChange(e)}
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        showTimeInput={props.showTimeInput}
        timeInputLabel={props.timeInputLabel}
        readOnly={props.readOnly}
        customInput={props.customInput}
        wrapperClassName={wrapperClassName}
        startDate={startDate}
        endDate={endDate}
        selectsRange={selectsRange}
        showMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        maxDate={maxDate}
        minDate={minDate}
        isClearable={isClearable}
        value={value}
      />
    </div>
  );
};

Calender.defaultProps = {};

export default Calender;
