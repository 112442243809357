import React from 'react';
import { Fade, Modal } from 'react-bootstrap';
import './modalCustom.scss';

const ModalCustom = (props) => {
  return (
    <Modal
      className={'ModalCustom ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      {props?.children}
    </Modal>
  )

}
ModalCustom.defaultProps = {
  className: 'ModalCustom',
  btnVisible: true
}

export default (ModalCustom);