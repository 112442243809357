import * as types from "./types";

export const login = (payload) => (
    {
        type: types.LOGIN,
        meta: {
            async: true,
            blocking: false,
            path: "auth/login",
            method: "POST",
            body: payload
        },
    });

export const initializeSession = (payload) => ({
    type: types.INITIALIZE,
    payload
});

export const destroySession = (payload) => ({
    type: types.DESTROY,
    payload
});

export const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "auth/forgotPassword",
        method: "POST",
        body: payload
    },
});

export const resetPassword = (token, payload) => ({
    type: types.RESET_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: `auth/resetPassword?token=${token}`,
        method: "POST",
        body: payload
    },
});

export const verifyResetPasswordLink = (payload) => ({
    type: types.VERIFY_RESET_PASSWORD_LINK,
    meta: {
        async: true,
        blocking: true,
        path: `auth/checkResetLink`,
        body: payload,
        method: "POST"
    },
});
