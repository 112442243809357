import Login from "../pages/admin/login/login";
import NotFound from "../pages/notFound/notFound";
import Dashboard from "../pages/admin/dashboard/dashboard";
import ResetPassword from "../pages/admin/resetPassword/resetPassword";
import ChangePassword from "../pages/admin/changePassword/changePassword";
import ForgotPassword from "../pages/admin/forgotPassword/forgotPassword";
import EventManagement from "../pages/admin/eventManagement/eventManagement";

// Web Rotes
import Event from "../pages/event/event";
import Bookmark from "../pages/bookmark/bookmark";
import HomeMapView from "../pages/homeMapView/homeMapView";

/**
 * An array of objects representing the routes for authentication-related pages in an admin panel.
 * Each object in the array has a `path` property representing the URL path and an `element` property
 * representing the React component to render for that path.
 * @type {Array}
 * @property {string} path - The URL path for the route.
 * @property {React.Component} element - The React component to render for the route.
 */
const Authroutes = [
  { path: "/admin", element: <Login /> },
  { path: "/admin/login", element: <Login /> },
  { path: "/admin/forgotpassword", element: <ForgotPassword /> },
  { path: "/admin/resetpassword", element: <ResetPassword /> },
  { path: "/admin/changepassword", element: <ChangePassword /> },
  { path: "*", element: <NotFound /> },
];

/**
 * Defines the routes for the admin layout.
 * @type {Array<{ path: string, element: React.ReactNode }>}
 */
const AdminLayoutRoute = [
  { path: "/admin/service", element: <Dashboard /> },
  { path: "/admin/event", element: <EventManagement /> },
];

/**
 * An array of route objects that define the paths and components for a router.
 * @type {Array<Object>}
 * @property {string} path - The path of the route.
 * @property {React.Component} component - The component to render for the route.
 */
const routes = [
  { path: "/", component: <HomeMapView /> },
  { path: "/bookmark", component: <Bookmark /> },
  { path: "/homemapview", component: <HomeMapView /> },
  { path: "/event", component: <Event /> },
];

export { AdminLayoutRoute, Authroutes, routes };
