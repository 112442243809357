import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import AdminHeader from "../adminHeader/adminHeader";
import Header from "../header/header";

/**
 * Renders the layout for the admin section of the application.
 * @param {Object} props - The props passed to the component.
 * @returns The rendered layout component.
 */
function AdminLayout(props) {
  /**
   * Retrieves the current pathname from the browser's location object.
   * @returns {string} The current pathname.
   */
  const pathname = useLocation();

  return (
    <>
      <React.Fragment>
        <div id="layout-wrapper">
          {pathname.pathname.includes("/admin") ? <AdminHeader /> : <Header />}
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default AdminLayout;
