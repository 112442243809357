import React from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import "../customeEventCalender/customeEventCalender.scss";


const CustomeEventCalender = (props) => {

  const { Event } = props;

  const renderEventContent = (eventInfo) => {
    return (
      <div
        style={{
          backgroundColor: eventInfo.event.backgroundColor,
          color: eventInfo.event.textColor,
          paddingLeft: '5px',
          paddingTop: '1px',
          paddingBottom: '1px',
          marginBottom: '1px',
          borderRadius: '4px',
          border: 'none',
        }}
        onClick={(e) => {
          e.preventDefault()
          // console.log("event", eventInfo.event)
          props.handleCalenderEventClick(eventInfo.event?.extendedProps?.eventDetails)
        }}
      >
        {eventInfo.event.title}
      </div>
    );
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      events={Event}
      eventContent={renderEventContent}
      dayMaxEventRows={true}  // Enable displaying multiple event rows
    // dayMaxEvents={3}
    />
  )
}


export default CustomeEventCalender;