import React from 'react';
import { Link } from 'react-router-dom';
import CustomToggleButton from '../customToggleButton/customToggleButton';
import './customSideBar.scss';

/**
 * A custom sidebar component that displays a title, content, and optional action header.
 * @param {Object} props - The props object containing the following properties:
 *   - {string} title - The title of the sidebar.
 *   - {boolean} showSidebar - Determines whether the sidebar is shown or hidden.
 *   - {boolean} checked - Determines the checked state of the toggle button in the action header.
 *   - {function} onCheckChange - The callback function to handle the toggle button state change.
 *   - {function} onClickDelete - The callback function to handle the delete button click.
 *   - {string} className - The additional CSS class name for the sidebar.
 *   - {boolean}
 */
const CustomSideBar = (props) => {
  let { title, showSidebar, checked, onCheckChange, onClickDelete, className, actionHeader, closeIcon } = props;
  return (
    <div className={(showSidebar ? "CustomSideBar show " + className : "CustomSideBar " + className)}>
      <div className={"sidebarInner"}>
        <div className="headerBar">
          <button onClick={() => props.onHide()} className="icon-back"></button>
          <span className="title">{title}</span>
          {closeIcon &&
            <Link onClick={() => props.onHide()} to="#" className="iconClose">
              <i className="icon-close"></i>
            </Link>
          }
        </div>
        {actionHeader &&
          <div className="action_header">
            <button onClick={onClickDelete} className="icon-delete"></button>
            <CustomToggleButton id="" checked={checked} onCheckChange={onCheckChange} />
          </div>
        }
        <div className="centerContentBox">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default (CustomSideBar);
