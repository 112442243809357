import moment from "moment";
import { isEmpty } from "lodash"

/**
 * Checks if a value is valid.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is valid, false otherwise.
 */
export const isValidValue = (value) => {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return false
  }
  return true
}

/**
 * Converts a timestamp to a formatted release time string.
 * @param {number} timestamp - The timestamp to convert.
 * @returns {string} - The formatted release time string.
 */
export const getReleaseTime = timestamp => {
  timestamp = timestamp * 1000;
  let dt = new Date(timestamp);
  return `${moment(dt).format("DD MMM, YYYY")} at ${moment(dt).format(
    "hh:mm A"
  )}`;
};

/**
 * Converts a timestamp to a human-readable format for displaying on a timeline.
 * @param {number} timestamp - The timestamp to convert, in seconds.
 * @returns {string} - The formatted time string.
 */
export const getTimelineTime = timestamp => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return moment(dt).fromNow();
  } else {
    return `${moment(dt).format("DD MMM, YYYY")} at ${moment(dt).format(
      "hh:mm A"
    )}`;
  }
};

/**
 * Generates a unique identifier (UID) using the window.crypto.getRandomValues method.
 * @returns {string} A unique identifier string.
 */
export const UID = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

/**
 * Formats a given date using the specified format.
 * @param {Date} date - The date to format.
 * @param {string} [format='YYYY/MM/DD'] - The format to use for the date. Defaults to 'YYYY/MM/DD'.
 * @returns {string} The formatted date string.
 */
export const dateFormat = (date, format = 'YYYY/MM/DD') => {
  return moment(date).format(format)
}

/**
 * Generates a query string from the given parameters object.
 * @param {object} params - The parameters object.
 * @returns {string} - The generated query string.
 */
export const getParams = (params) => {
  if (isEmpty(params)) return ''
  let query = []
  Object.keys(params).forEach(key => {
    if (isValidValue(params[key])) {
      query.push(`${key}=${params[key]}`)
    }
  })
  return query.join('&')
}

/**
 * Checks if the value is undefined, null, or an empty string. If it is, returns an empty string.
 * Otherwise, concatenates the value with the given params.
 * @param {any} params - The params to concatenate with the value.
 * @param {any} value - The value to check and concatenate.
 * @returns {string} - The concatenated string or an empty string.
 */
export function isCheckValueAndSetParams(params, value) {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return ''
  }
  return params + value
}