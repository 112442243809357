/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";

import Profile from "../../components/profile/profile";
import CustomSideBar from "../../components/customSideBar/customSideBar";
import AppConfigurations from "../../components/appConfigurations/appConfigurations";
import { showPopup } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";

import "./adminHeader.scss";
import { getItem } from "../../services/localStorageService";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";
import userEvent from "@testing-library/user-event";

const AdminHeader = (props) => {
  const dimensions = useWindowDimension();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth.session);
  const isPopup = useSelector((state) => state?.utils?.hidePopup);

  let [image, setImage] = useState();
  const [sidebarHeaderHeight, SetSidebarHeaderHeight] = useState("");
  let [showProfile, setShowProfile] = useState(false);
  let [changePasswordModel, setChangePasswordModel] = useState(false);
  let [showAppConfigurations, setShowAppConfigurations] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(t("Profile"));
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [isEventSelected, setIsEventSelected] = useState(true);
  const userDetails = getItem("userDetails") || user;

  /**
   * useEffect hook that sets the height of the sidebar header based on the height of the
   * first element with the class "headerBar" in the document.
   * @returns None
   */
  useEffect(() => {
    SetSidebarHeaderHeight(
      document.getElementsByClassName("headerBar")[0].offsetHeight
    );
  }, []);

  /**
   * useEffect hook that runs when the value of isPopup changes.
   * If isPopup is true, it sets the values of setShowAppConfigurations and setShowProfile to false.
   * @param {boolean} isPopup - The value that triggers the effect.
   * @returns None
   */
  useEffect(() => {
    if (isPopup) {
      setShowAppConfigurations(false);
      setShowProfile(false);
      setChangePasswordModel(false);
    }
  }, [isPopup]);

  let activeMenu = "";
  if (props.location?.pathname.indexOf("service") !== -1) {
    activeMenu = "service";
  } else if (
    props.location?.pathname.indexOf("profile") !== -1 ||
    props.location?.pathname.indexOf("changepassword") !== -1
  ) {
    activeMenu = "profile";
  } else {
    activeMenu = "profile";
  }

  /**
   * Generates an image word based on the user's name.
   * @returns {string} The image word.
   */
  let imageWord = () => {
    if (userDetails) {
      const wordWrapName = userDetails?.name;
      let w = wordWrapName
        ?.split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")
        .trim(2);
      let y = w?.substring(0, 2);
      return y;
    }
    return "";
  };

  return (
    <div className={"header"}>
      <Navbar>
        <Navbar.Collapse id="header-nav">
          <Nav activeKey={activeMenu}>
            <NavLink
              eventKey="service"
              as={Link}
              to={{
                pathname: "admin/service",
                state: { serviceCount: isEventSelected },
              }}
              onClick={() => {
                // document.body.classList.remove('rightSidebarshow');
                setShowAppConfigurations(false);
                setShowProfile(false);
                setChangePasswordModel(false);
                setHeaderTitle("Profile");
              }}
              className={"nav-link"}
            >
              <i className="icon icon-business"></i>
              {t("Service")}
            </NavLink>
            <NavLink
              eventKey="profile"
              as={Link}
              to="admin/event"
              className={"nav-link"}
              onClick={() => {
                // document.body.classList.remove('rightSidebarshow');
                setShowAppConfigurations(false);
                setShowProfile(false);
                setChangePasswordModel(false);
                setHeaderTitle("Profile");
              }}
            >
              <i className="icon icon-event"></i>
              {t("Event")}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
        <div className="profiledropdown">
          <div className="profilebtn">
            <button
              className="app_menu"
              onClick={() => {
                // document.body.classList.remove('rightSidebarshow')
                dispatch(showPopup());
                setShowAppConfigurations(true);
                // document.body.classList.add('rightSidebarshow');
              }}
            >
              <span className="txt">{t("App_configuration")}</span>
              <div className="icon-setting"></div>
            </button>
            <button
              className="avtar-name"
              onClick={() => {
                if (showAppConfigurations) {
                  dispatch(showPopup());
                  setShowAppConfigurations(false);
                  setShowProfile(true);
                  setHeaderTitle("Profile");
                } else {
                  dispatch(showPopup());
                  setShowProfile(true);
                  setHeaderTitle("Profile");

                  // document.body.classList.add('rightSidebarshow');
                }
              }}
            >
              <span>Hi, {userDetails && userDetails?.name}</span>
              <div className="icon-arrow-right"></div>
            </button>
          </div>
          <div className="avtar">
            {(userDetails && userDetails?.image) || image ? (
              <img src={image ? image : userDetails?.image} alt="viewImg" />
            ) : (
              <span className="sortName">{imageWord()}</span>
            )}
          </div>
        </div>
      </Navbar>

      <CustomSideBar
        title={headerTitle}
        showSidebar={showProfile}
        onHide={() => {
          setShowProfile(false);
          setChangePasswordModel(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        className={headerTitle === "Change password" ? "profile changePasswordModel" : "profile"}
        closeIcon={headerTitle === "Change password" ? false : true}
      >
        {headerTitle === "Change password" && (
          <Button
            onClick={() => {
              setChangePasswordModel(false);
              setHeaderTitle("Profile");
            }}
            className="SidebarClose"
          >
            <i className="icon-close"></i>
          </Button>
        )}
        <Scrollbars style={{ height: dimensions.height - sidebarHeaderHeight }}>
          <Profile
            onHeaderChange={(header) => setHeaderTitle(header)}
            setShowChangePassword={(val) => setChangePasswordModel(val)}
            changePasswordModel={changePasswordModel}
            onProfileUpdate={() => setIsProfileUpdate(!isProfileUpdate)}
            closeChangePasswordModel={() => setChangePasswordModel(false)}
          />
        </Scrollbars>
      </CustomSideBar>

      <CustomSideBar
        title={t("App_Configurations")}
        showSidebar={showAppConfigurations}
        onHide={() => {
          setShowAppConfigurations(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        closeIcon={true}
        className="AppConfigurations"
      >
        <Scrollbars style={{ height: dimensions.height - sidebarHeaderHeight }}>
          <AppConfigurations
            onHide={() => { setShowAppConfigurations(false); }}
            showAppConfigurations={showAppConfigurations}
          />
        </Scrollbars>
      </CustomSideBar>
    </div>
  );
};

export default AdminHeader;
