import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import draftToHtml from 'draftjs-to-html';
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ContactUs from "../../components/contactUs/contactUs";
import AxiosInstance from "../../services/axios";
import OurSupporters from "../../components/ourSupporters/ourSupporters";
import PrivacyModalBox from "../../components/privacyModalBox/privacyModalBox";
import "./header.scss";

/**
 * A functional component that represents a header section of a website.
 * @param {object} props - The props passed to the component.
 * @returns The rendered header section.
 */
const Header = (props) => {
  const { t } = useTranslation();
  const [privModalOpen, setPrivModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactList, setContactList] = useState('');
  const [policyList, setPolicyList] = useState('');
  const { pathname } = useLocation();

  let activeMenu = '';

  /**
   * Determines the active menu based on the current pathname.
   * @param {string} pathname - The current pathname.
   * @returns {string} The active menu.
   */
  if (pathname.indexOf("/") !== -1) { activeMenu = "/"; }
  if (pathname.indexOf("/event") !== -1) { activeMenu = "event"; }
  if (pathname.indexOf("/bookmark") !== -1) { activeMenu = "bookmark"; }


  /**
   * Closes the modal by setting the corresponding state variables to false.
   * @returns None
   */
  const modalClose = () => {
    setPrivModalOpen(false)
    setSupportModalOpen(false)
    setContactModalOpen(false)
  };

  /**
   * Executes the getContact function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    getContact()
  }, [])

  /**
   * Runs the getPolicies function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    getPolicies()
  }, [])

  /**
   * Retrieves the contact information from the server asynchronously using Axios.
   * @returns None
   */
  const getContact = async () => {
    await AxiosInstance.get(`user/getContactInfo`)
      .then((res) => {
        setContactList(res.payload);
      }).catch((e) => {
      })
  }


  /**
   * Retrieves the privacy policies from the server and sets the policy list state.
   * @returns None
   */
  const getPolicies = async () => {
    await AxiosInstance.get(`user/getPrivacyPolicy`)
      .then((res) => {

        let data = JSON.parse(res.payload.privacyPolicy);
        let privacyString = draftToHtml(data);
        setPolicyList(privacyString);
      }).catch((e) => {
      })
  }

  /**
   * Renders a navigation bar with various links and dropdown menus.
   * @returns JSX element representing the navigation bar.
   */
  return (
    <div className="navWrap">
      <Navbar bg="white" expand="lg">
        <Container fluid className="p-0">
          <Navbar id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={activeMenu}>
              <Nav.Link eventKey="/" as={Link} to="/">
                <span className="icon icon-home"></span> {t("HOME")}
              </Nav.Link>
              <Nav.Link eventKey="event" as={Link} to="/event" >
                <span className="icon icon-event"></span>{t("EVENT")}
              </Nav.Link>
              <Nav.Link
                eventKey="bookmark" as={Link} to="/bookmark"
              >
                <span className="icon icon-bookmark"></span> {t("BOOKMARK")}
              </Nav.Link>
            </Nav>
            <NavDropdown
              className="ms-auto"
              title={<span className="icon-dots"></span>}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => setContactModalOpen(true)}>
                <span className="icon icon-phone"></span> {t("CONTACTUS")}
              </NavDropdown.Item>

              <NavDropdown.Item onClick={() => setPrivModalOpen(true)}>
                <span
                  className="icon icon-privacy-policy"
                ></span>
                {t("PRIVACY_POLICY")}
              </NavDropdown.Item>
              {/* 
              <NavDropdown.Item onClick={() => setSupportModalOpen(true)}>
                <span className="icon icon-supporters"></span> {t("OURSUPPORTERS")}
              </NavDropdown.Item>
               */}
            </NavDropdown>
          </Navbar>
        </Container>
      </Navbar>

      {/* <privacyModalBox showModal={privModalOpen} modalClose={modalClose} /> */}
      <PrivacyModalBox showModal={privModalOpen} modalClose={modalClose} policyData={policyList} />
      <OurSupporters showModal={supportModalOpen} modalClose={modalClose} />
      <ContactUs showModal={contactModalOpen} modalClose={modalClose} contactData={contactList} />


    </div>
  );
};

export default Header;
