import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

/**
 * A reusable form component that handles form submission and initialization.
 * @param {Object} props - The props object containing the onSubmit and init functions.
 * @returns The form component with the provided children and form submission handling.
 */
function HookForm(props) {
    let { onSubmit, init } = props;

    const method = useForm({
        mode: "onChange",
        defaultValues: props.defaultForm,
        criteriaMode: "all"
    });

    useEffect(() => {
        init && init(method);
    }, [init, method])

    return (
        <form onSubmit={method.handleSubmit(onSubmit)}>
            {props.children(method)}
        </form>
    );
}

HookForm.defaultProps = {
    defaultValues: {},
    onSubmit: () => { }
}
export default HookForm;
