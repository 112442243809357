import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import configureStore from "./state/store";
import * as serviceWorker from "./serviceWorker";
import { getItem } from "./services/localStorageService";


import "./i18n";
import "./index.scss";
import App from "./app";

let user = getItem("userDetails");
const token = getItem("token");

/**
 * Creates a Redux store with the provided initial state.
 * @param {object} auth - The initial state for the auth slice of the store.
 * @param {boolean} auth.isAuthenticated - Indicates whether the user is authenticated.
 * @param {object} auth.session - The session object containing user and token information.
 * @param {object} user - The user object.
 * @param {string} token - The authentication token.
 * @returns The configured Redux store.
 */
export const store = configureStore({
  auth: {
    isAuthenticated: !!user,
    session: { user, token },
  },
});

/**
 * Creates a root element for rendering React components.
 * @param {HTMLElement} element - The root element to render the components into.
 * @returns A root object that can be used to render React components.
 */
const root = ReactDOM.createRoot(document.getElementById("root"));

/**
 * Renders the root component with the provided Redux store and necessary providers.
 * @param {Object} store - The Redux store object.
 * @returns None
 */
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
serviceWorker.unregister();
