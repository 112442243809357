/* eslint-disable no-useless-escape */
const Constant = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  REGEX: {
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    SPECIALCHARACTERS: /[!@#$%^&*)(+=._-]/g,
    NUMBER: /[0-9]/,
    NAME: /^[a-zA-Z\s\u00C0-\u00FF]*$/,
    NAME1: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
    ALPHANUMERIC: /^[a-zA-Z0-9\-~\s\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F!@#$%^&*()_+=[\]{};:'",.<>/?\\|]+$/i,
    ALPHAHYPENAPOSTROPHES: /^[a-zA-Z0-9\s'-]+$/i,
    UPPERCASELOWERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,
    NUMERIC: /^\d*\.?\d*$/,
    NUMONLY: /^\d*$/,
    URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#:]{2,256}\.[A-Za-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//:]*)/,
    ASCII: 'xxx[\x00-\x7F]+xxx',
  },
  IMAGESURL: {
    AUTHBANNER: 'https://res.cloudinary.com/zudu/image/upload/v1633001188/Parish_Nursing/Parish-Nursing-Web/Project-images/bg_logo.svg',
    LOGO: 'https://res.cloudinary.com/zudu/image/upload/v1633057611/Parish_Nursing/Parish-Nursing-Web/Project-images/inner_bg_logo.svg'
  },
  ROLES: {
    SUPERADMIN: "SuperAdmin",
  },
  GOOGLEMAPAPI: {
    APIKEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
  },
  STATUS: {
    DISABLE: "Disable",
    ENABLE: "Enable"
  },
  REDIRECTURL: {
    SVRU: "http://www.svru.co.uk/",
    QNIS: "https://www.qnis.org.uk/",
    STREETSOCCERSCOTLAND: "https://streetsoccerscotland.org/",
    DUNDEEANDANGUS: "https://dundeeandangus.foodbank.org.uk/",
    THESTEEPLECHURCH: "https://thesteeplechurch.org.uk/parish-nursing/",
    DVVA: "https://dvva.scot/"
  },
  TOWN_COORDINATES: {
    Monifieth: {
      lat: 56.480233,
      lng: -2.818861,
    },
    Kirriemuir: {
      lat: 56.675095,
      lng: -3.003608,
    },
    Montrose: {
      lat: 56.707222,
      lng: -2.465887,
    },
    Brechin: {
      lat: 56.733614,
      lng: -2.655153,
    },
    Arbroath: {
      lat: 56.559007,
      lng: -2.591697,
    },
    Forfar: {
      lat: 56.643554,
      lng: -2.888791,
    },

    Carnoustie: {
      lat: 56.502349,
      lng: -2.700877,
    },
    Angus: {
      lat: 56.6980,
      lng: -2.9124,
    }
  }
};
export default Constant;
