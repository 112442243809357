/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { CirclePicker } from 'react-color';
import { useSelector } from 'react-redux';
import reactCSS from 'reactcss';
import './customSelectPicker.scss';
import categoryColor from '../../util/color';
import { useTranslation } from 'react-i18next';



const CustomSelectPicker = (props) => {
    let { onChange } = props;
    const { t } = useTranslation();
    const [displayColorPicker, setShowDisplayColorPicker] = useState(false);

    const [color, setColor] = useState(props.defaultValue || "#ECECEC");
    const [isColorSelected, setColorSelected] = useState(props.defaultValue ? true : false);

    const [selectedColorlist, setSelectedColorList] = useState([])
    const [updatedColorList, setupdateColorList] = useState([])

    const data = useSelector(state => state?.CategoryDetails?.getCategoryListForColor?.categoryList)

    /**
     * useEffect hook that updates the selected color list and update color list
     * based on the data array.
     * @param {Array} data - The data array to map and filter.
     * @returns None
     */
    useEffect(() => {
        let value = data.map((item) => {
            return item.color
        })
        setSelectedColorList([...value])
        const data1 = categoryColor.filter((item) => {
            return !value.includes(item.toLocaleLowerCase())
        })
        setupdateColorList([...data1])
    }, [data])

    /**
     * Handles the click event and sets the state to show the display color picker.
     * @returns None
     */
    const handleClick = () => {
        setShowDisplayColorPicker(true)
    };

    /**
     * Handles the change event of a color picker.
     * @param {string} color - The selected color in hexadecimal format.
     * @returns None
     */
    const handleChange = (color) => {
        setColor(color.hex)
        setColorSelected(true)
        setShowDisplayColorPicker(!displayColorPicker)
        onChange && onChange(color.hex)
    };

    /**
     * Generates a CSS style object using the reactCSS library.
     * @param {object} styles - An object containing the CSS styles for different elements.
     * @returns {object} - A CSS style object that can be used in React components.
     */
    const styles = reactCSS({
        'default': {
            txt_placeholder: {
                fontSize: 14,
                color: "#BBBBBB"
            },
            color: {
                width: '24px',
                height: '24px',
                borderRadius: '4px',
                background: `${color}`,
            },
            fullColor: {
                width: '100%',
                height: '24px',
                borderRadius: '4px',
                background: `${color}`,
            },
            swatch: {
                padding: '12px 16px',
                background: '#fff',
                borderRadius: '8px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'flex',
                justifyContent: "space-between",
                alignItems: ' center',
                cursor: 'pointer',
                position: 'relative',
                zIndex: '111',
            },
            popover: {
                position: 'relative',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div className="CustomSelectPicker">
            <div>
                <a onClick={handleClick} className='CustomSelectPickerinput'>
                    {!isColorSelected ? <>
                        <div style={styles.txt_placeholder}>{t("Select_category_color")}
                            <span style={{ color: "#FF4539" }}>*</span>
                        </div>
                        <div style={styles.color} />
                    </> :
                        <div style={styles.fullColor} />
                    }
                </a>
                <div className={displayColorPicker ? "dropdownColor show" : "dropdownColor hide"} style={styles.popover}>
                    {/* <div style={styles.cover} onClick={handleClose} /> */}
                    {/* <CirclePicker color={color || props.defaultValue} onChange={handleChange} /> */}
                    <CirclePicker colors={updatedColorList || props.defaultValue} onChange={handleChange} />
                </div>

            </div>
        </div>
    )
}

CustomSelectPicker.defaultProps = {

}

export default CustomSelectPicker;


