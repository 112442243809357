/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import SearchBox from "../../components/searchBox/searchBox";
import ListEvent from "../../components/listEvent/listEvent";

import ModalCustom from "../../components/modalCustom/modalCustom";
import CustomButton from "../../components/customButton/customButton";
import ConfirmModal from "../../components/confirmModal/confirmModal";
import EventDetails from "../../components/eventDetails/eventDetails";
import CustomSideBar from "../../components/customSideBar/customSideBar";
import { Scrollbars } from "react-custom-scrollbars-2";

import { showToast, hidePopup } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";
import {
  getEventList,
  eventStatusUpdate,
  deleteEvent,
} from "../../state/ducks/eventListDetails/actions";

import "./customEventListing.scss";
import moment from "moment";
import CustomAddEvent from "../../components/customAddEvent/customAddEvent";
import { useTranslation } from "react-i18next";
import Constant from "../../util/constant";
import Loading from "../loading/loading";

const CustomEventListing = (props) => {
  let { color, title, id, onRefresh, mainItem, setShowEventDetailsModal, showEventDetailsModal } = props;
  const CLOUDINARY_URL =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const limit = 10

  const dimensions = useWindowDimension();
  const [headerHeight, SetHeaderHeight] = useState("");
  const [pageTitleHeight, SetPageTitleHeight] = useState("");
  const [searchHeight, SetSearchHeight] = useState("");
  const [sidebarHeaderHeight, SetSidebarHeaderHeight] = useState("");
  const [sidebarActionHeight, SetSidebarActionHeight] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showBusinessDeleteModal, setShowBusinessDeleteModal] = useState(false);
  const [showBusinessDisableModal, setShowBusinessDisableModal] = useState(false);
  const [showcategroyModal, setShowCategroyModal] = useState({
    show: false,
    type: "",
  });
  const [changeIndex, setChangeIndex] = useState({});
  const [categoryID, setCategoryID] = useState();
  const [categoryStatus, setCategoryStatus] = useState();
  const [eventName, setEventName] = useState("");
  const [selectedListItem, setSelectedListItem] = useState("");

  const [eventCount, setEventCount] = useState(0);
  const [upcomingEventCount, setUpComingEventCount] = useState(0);
  const [expiredEventCount, setExpiredEventCount] = useState(0);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [eventListSearchData, setEventListSearchDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const [isExpired, SetIsExpired] = useState();
  const [scrollApiCalling, setScrollApiCalling] = useState(true);
  const [busy, setBusy] = useState(false);
  const scrollBar = useRef();

  /**
   * useEffect hook that sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("header")[0].offsetHeight);
    SetPageTitleHeight(
      document.getElementsByClassName("business_title")[0].offsetHeight
    );
    SetSearchHeight(
      document.getElementsByClassName("search_row")[0].offsetHeight
    );

    SetSidebarHeaderHeight(
      document.getElementsByClassName("headerBar")[0].offsetHeight
    );
    SetSidebarActionHeight(
      document.getElementsByClassName("action_header")[0].offsetHeight
    );
  }, []);

  /**
   * Executes a side effect when the value of `id` changes.
   * Sets the value of `page` to 1.
   * @returns None
   */
  useEffect(() => {
    setPage(1)
  }, [id])

  useEffect(() => {
    if (reload) {
      setPage(1)
      setSearchValue('')
      if (page === 1 && searchValue === '') getEventListData(searchValue, page);
      if (selectedIndex > 9) setSelectedIndex(0)
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    getEventListData(searchValue, page);
  }, [id, page, searchValue, mainItem.status]);

  /**
   * Runs the `onRefresh` function whenever the `businessCount` value changes.
   * @returns None
   */
  useEffect(() => {
    onRefresh();
  }, [eventCount]);

  const getEventListData = async (searchData, pageNumber) => {
    if (id) {
      setScrollApiCalling(false);
      if (pageNumber === 1) setBusy(true)
      try {
        const res = await dispatch(getEventList(id, searchData, limit, pageNumber))
        if (res.payload) {
          setUpComingEventCount(res?.payload?.upcomingEventCount);
          setExpiredEventCount(res?.payload?.expiredEventCount);
          setEventCount(res?.payload?.totalCount);
          if (res?.payload?.getEventList.length > 0) {
            if (
              res?.payload?.getEventList[0].eventDetails.categoryId == null ||
              !res?.payload?.getEventList[0].eventDetails.categoryId
            ) {
              let data = localStorage.getItem("Uncategorised");
              let uncategorisedCategory = JSON.parse(data);
              uncategorisedCategory["eventCount"] = res?.payload?.totalCount;


              window.localStorage.setItem(
                "Uncategorised",
                JSON.stringify(uncategorisedCategory)
              );
            }
          }

          if (pageNumber == 1) {
            setEventListSearchDetails(res?.payload?.getEventList);
            if (scrollBar.current) scrollBar.current.scrollToTop();
            setBusy(false)
          } else {
            setEventListSearchDetails((prev) => [...prev, ...res.payload.getEventList]);
          }

          if (res.payload.getEventList.length > 0) setScrollApiCalling(true);
        } else {
          setScrollApiCalling(true);
          setPage(1)
          setReload(true)
        }
      } catch (error) {
        console.log("getEventListData error => ", error);
        setBusy(false)
        setReload(true)
        dispatch(
          showToast({
            message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
            type: "error",
          })
        );
      }
    }
  };

  /**
   * Deletes event data by dispatching a deleteEvent action and updating the side menu.
   * @returns None
   * @throws {Error} If an error occurs during the deletion process.
   */
  const deleteEventData = async () => {
    try {
      const res = await dispatch(deleteEvent(categoryID))

      if (!res.payload.categoryId || res.payload.categoryId === null) {
        let data = localStorage.getItem("Uncategorised");
        let uncategorisedCategory = JSON.parse(data);

        uncategorisedCategory["eventCount"] = uncategorisedCategory.eventCount - 1;
        window.localStorage.setItem(
          "Uncategorised",
          JSON.stringify(uncategorisedCategory)
        );
      }

      setReload(true);
      props.callback && props.callback();
      setShowBusinessDeleteModal(false);

      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      console.log("error => ", error);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Updates the status of an event with the given ID.
   * @param {string} id - The ID of the event to update.
   * @param {boolean} status - The new status of the event. True for enabled, false for disabled.
   * @returns None
   * @throws {Error} If there is an error updating the event status.
   */
  const eventStatusChange = async (id, status) => {
    let params = {
      status: status ? Constant.STATUS.ENABLE : Constant.STATUS.DISABLE,
    };

    try {
      const res = await dispatch(eventStatusUpdate(id, params))
      setShowDisableModal(!showDisableModal);
      setReload(true);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      console.log("error => ", error);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  return (
    <div className="CustomEventListing">
      <div className="business_container">
        <div className="business_title">
          {t("Event_Listing")}<span className="line_border">|</span>
          <span className="category_name" style={{ color: color }}>
            {title}
          </span>
        </div>
        <div className="search_row">
          <SearchBox
            placeholder={t("Event_name")}
            value={searchValue}
            onSearch={(value) => {
              setSearchValue(value);
              setPage(1);
            }}
            onClear={() => {
              setSearchValue("");
              setPage(1);
            }}
          />
          <div className="right_btn_business">
            <div className="total_business_row">
              <div className="count_title">{upcomingEventCount > 1 ? t("Total_Events") : t("Total_Event")}</div>
              <div className="count_number">{upcomingEventCount}</div>
            </div>
            <CustomButton
              onClick={() => {
                setShowCategroyModal({ show: true, type: "Add" });
              }}
              className="outline_btn"
              title={`+ ${t("ADD_EVENTS")}`}
            />
          </div>
        </div>
        <div className="data_list">
          {busy ?
            <div className="loader"><Loading show={busy} type="spin" color="#073180" className="adminPanel" /></div> :
            <Scrollbars
              style={{
                height:
                  dimensions.height -
                  headerHeight -
                  pageTitleHeight -
                  searchHeight,
              }}
              onScroll={(e) => {
                const { scrollTop, scrollHeight, clientHeight } = scrollBar.current.getValues();
                if (scrollApiCalling && scrollTop + clientHeight >= scrollHeight - 10) {
                  setPage((prevPage) => prevPage + 1);
                }
              }}
              ref={scrollBar}
            >
              {eventListSearchData?.length === 0 ? (
                <div className="no_business_row">
                  <img
                    className="no_categroy_logo"
                    src={CLOUDINARY_URL + "no-categroy.png"}
                    alt="no-categroy"
                  />
                  <div className="no_categroy_txt">
                    {t("The_entered_Event_is_not_found_Try_something_else")}
                  </div>
                </div>
              ) : (
                eventListSearchData?.map((item, index) => {
                  let StartDate = moment(
                    `${moment(item?.eventDetails?.eventStartDate).format(
                      "yyyy/MM/DD"
                    )}  ${item?.eventDetails?.eventStartTime}`
                  ).format("DD MMM YYYY HH:mm");
                  let endDate = "";
                  item.eventDetails.isExpired = false
                  if (item?.eventDetails?.eventEndDate) {
                    endDate = moment(
                      `${moment(item?.eventDetails?.eventEndDate).format(
                        "yyyy/MM/DD"
                      )}  ${item?.eventDetails?.eventEndTime}`
                    ).format("DD MMM YYYY HH:mm");
                    const eventEndDate = moment(item?.eventDetails?.eventEndDate).set({
                      hour: parseInt(item?.eventDetails?.eventEndTime.split(':')[0]),
                      minute: parseInt(item?.eventDetails?.eventEndTime.split(':')[1]),
                    });
                    const currentDateTime = moment();

                    // Compare the two moments
                    if (eventEndDate.isBefore(currentDateTime)) {
                      item.eventDetails.isExpired = true
                    }
                  } else {
                    endDate = "--:--";
                  }
                  return (
                    <>
                      <ListEvent
                        expiredCount={expiredEventCount}
                        className={
                          item?.eventDetails?.status === Constant.STATUS.ENABLE
                            ? ""
                            : " disable"
                        }
                        modal_dis={item.modal_dis}
                        onClickDelete={() => {
                          setShowDeleteModal(true);
                          setCategoryID(item?.eventDetails?._id);
                          setEventName(item?.eventDetails?.name);
                          if (item?.eventDetails?.isExpired === true) {
                            setShowDeleteModal(false);
                          }
                        }}
                        onListItemClick={() => {
                          dispatch(hidePopup());
                          item?.eventDetails?.status === Constant.STATUS.ENABLE && setShowEventDetailsModal(true)
                          // document.body.classList.add('rightSidebarshow')
                          setSelectedIndex(index);
                          SetIsExpired(item?.eventDetails?.isExpired);
                          setSelectedListItem(item?.eventDetails);
                          setCategoryID(item?.eventDetails?._id);
                          setCategoryStatus(item?.eventDetails?.status);
                        }}
                        onClickEdit={() => {
                          setShowCategroyModal({ show: true, type: "Edit" });
                        }}
                        SortName={item?.eventDetails?.SortName}
                        title={item?.eventDetails?.name}
                        datetime={`${StartDate} - ${endDate}`}
                        selected={index == selectedIndex}
                        color={color}
                        location={item?.eventDetails?.address}
                        btnShow={
                          item?.eventDetails?.isExpired === false ? true : false
                        }
                        onlineStatus={item?.eventDetails?.onlineStatus}
                        expiredShow={item?.eventDetails?.isExpired}
                        showHeader={
                          item?.eventDetails?.isExpired &&
                          index ===
                          eventListSearchData
                            .map((e) => e.eventDetails?.isExpired)
                            .indexOf(true)
                        }
                        checked={item?.eventDetails?.status == Constant.STATUS.ENABLE}
                        onCheckChange={(checkedStatus) => {
                          setChangeIndex({ value: checkedStatus, index: index });
                          setCategoryID(item?.eventDetails?._id);
                          setCategoryStatus(checkedStatus);
                          setEventName(item.eventDetails?.name);
                          setShowDisableModal(!showDisableModal);
                          if (checkedStatus) {
                            eventStatusChange(
                              item?.eventDetails?._id,
                              checkedStatus
                            );
                          }
                        }}
                      />
                    </>
                  );
                })
              )}
            </Scrollbars>
          }
        </div>
        <div className="data_list"></div>
      </div>
      <ConfirmModal
        modalTitle={t("DELETE_TITLE")}
        btnHide={false}
        leftBtnTitle={t("YES_DELETE")}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onProcced={() => {
          setShowDeleteModal(false);
          deleteEventData();
          setShowEventDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        className={"confirm-delete"}
      >
        <p>{t("Are_you_sure_you_want_to_delete")} {eventName}?</p>
      </ConfirmModal>

      <ConfirmModal
        modalTitle={t(
          `${categoryStatus === Constant.STATUS.DISABLE ? "ENABLE_TITLE" : "DISABLE_TITLE"}`
        )}
        btnHide={false}
        leftBtnTitle={t(
          `${categoryStatus === Constant.STATUS.DISABLE ? "YES_ENABLE" : "YES_DESABLE"}`
        )}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showDisableModal && !categoryStatus}
        onHide={() => setShowDisableModal(false)}
        onProcced={() => {
          eventStatusChange(categoryID, categoryStatus);
          setSelectedListItem(selectedListItem);
          setShowEventDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        className={"confirm-disable"}
      >
        <p>{t("Are_you_sure_you_want_to_disable")} {eventName}?</p>
      </ConfirmModal>
      <CustomSideBar
        title={t("Event_Details")}
        closeIcon={true}
        actionHeader={selectedListItem?.isExpired ? false : true}
        showSidebar={showEventDetailsModal}
        onHide={() => {
          setShowEventDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow') 
        }}
        onClickDelete={() => {
          setShowBusinessDeleteModal(true);
          setCategoryStatus(selectedListItem?.status);
          setEventName(selectedListItem?.name);
        }}
        checked={selectedListItem?.status === Constant.STATUS.ENABLE}
        onCheckChange={(checkedStatus) => {
          setEventName(selectedListItem?.name);
          if (checkedStatus === true) {
            setCategoryStatus(checkedStatus);
            selectedListItem.status = Constant.STATUS.ENABLE;
            setSelectedListItem(selectedListItem);
            eventStatusChange(selectedListItem?._id, checkedStatus);
          } else {
            setCategoryStatus(checkedStatus);
            setShowDisableModal(true);
          }
        }}
        className="business_details"
      >
        <Scrollbars
          style={{
            height:
              dimensions.height - sidebarHeaderHeight - sidebarActionHeight,
          }}
        >
          <EventDetails
            color={color}
            itemIndex={selectedIndex}
            eventID={categoryID}
            expiredStatus={isExpired}
            onRefreshEventList={() => {
              setPage(1)
              if (page === 1) getEventListData(searchValue, 1);
              if (selectedIndex > 9) {
                setShowEventDetailsModal(false)
                setSelectedIndex(0)
              }
            }}
            onRefreshCustomLeftPart={() => {
              props.callback && props.callback()
              setShowEventDetailsModal(false)
              setSelectedIndex(0)
            }}
          />
        </Scrollbars>
      </CustomSideBar>
      <ConfirmModal
        modalTitle={t("DELETE_TITLE")}
        btnHide={false}
        leftBtnTitle={t("YES_DELETE")}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showBusinessDeleteModal}
        onHide={() => setShowBusinessDeleteModal(false)}
        onProcced={() => {
          setShowBusinessDeleteModal(false);
          deleteEventData();
          setShowEventDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        className={"confirm-delete"}
      >
        <p>{t("Are_you_sure_you_want_to_delete")} {eventName}?</p>
      </ConfirmModal>
      <ConfirmModal
        modalTitle={t(
          `${changeIndex.value ? "ENABLE_TITLE" : "DISABLE_TITLE"}`
        )}
        btnHide={false}
        leftBtnTitle={t(`${changeIndex.value ? "YES_ENABLE" : "YES_DESABLE"}`)}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showBusinessDisableModal}
        onHide={() => setShowBusinessDisableModal(false)}
        onProcced={() => {
          eventStatusChange(categoryID, categoryStatus);
          setShowBusinessDisableModal(false);
        }}
        className={"confirm-disable"}
      >
        <p>{t("Are_you_sure_you_want_to_disable_this")} ${eventName} ?</p>
      </ConfirmModal>

      <ModalCustom
        showModal={showcategroyModal.show}
        onHide={() => setShowCategroyModal({ show: false, type: "" })}
        className={"add_business_modal"}
      >
        <CustomAddEvent
          onClickCancel={() => setShowCategroyModal({ show: false, type: "" })}
          title_heading={t(showcategroyModal.type === "Add" ? "ADD_EVENTS" : "ADD_EVENTS")}
          item={mainItem}
          onReload={() => {
            props.callback()
            setReload(true)
          }
          }
        />
      </ModalCustom>
    </div>
  );
};
export default CustomEventListing;
