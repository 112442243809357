/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactLoading from "react-loading";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Header from "../../layout/header/header";
import AxiosInstance from "../../services/axios";
import Filter from "../../components/filter/filter";
import useGeoLocation from "../../hooks/useGeoLocation";
import ViewDetails from "../../components/viewDetails/viewDetails";

import "../homeMapView/homeMapView.scss";
import CustomeEventCalender from "../../components/customeEventCalender/customeEventCalender";
import Constant from "../../util/constant";
import { showToast } from "../../state/ducks/utils/actions";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import Loading from "../../components/loading/loading";
import domToPdf from 'dom-to-pdf';
import { Helmet } from 'react-helmet';

const Event = () => {

  const ogTitle = "Events In Tayside & Angus | Angus Recovery Roadmap App";
  const title = "Events In Tayside & Angus | Angus Recovery Roadmap App";
  /**
   * Initializes various state variables used in the component.
   * @returns None
   */
  const { t } = useTranslation();
  const dispatch = useDispatch()

  /**
   * Retrieves the user's current geolocation using the browser's Geolocation API.
   * @returns An object containing the user's current latitude and longitude.
   */
  const location = useGeoLocation();

  const [toggleFilter, setToggleFilter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [viewPage, setViewPage] = useState({});
  const [mapView, setMapView] = useState([]);
  const [eventShowCount, setEventShowCount] = useState(0);
  const [bookmarkBtn, setBookmarkBtn] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [toggleView, setToggleView] = useState(true);
  const [searchText, setsearchText] = useState("");
  const [eventsCalandar, setEventsCalandar] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [sliceData, setSliceData] = useState([]);

  const [categories, setCategories] = useState([]);
  const [town, setTown] = useState('All')
  const [checkToggle, setCheckToggle] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)

  let filterWrap = JSON.parse(localStorage.getItem("homeFilter"));
  let filterToggleWrap = JSON.parse(localStorage.getItem("toggleFilter")) || false;
  let selectedTown = localStorage.getItem("selectedTown") || 'All';

  /**
   * Runs the getFilterCategory function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    getFilterCategory();
    setCheckToggle(filterToggleWrap);
    setTown(selectedTown)
  }, []);

  /**
   * useEffect hook that updates the events calendar based on the filtered data source.
   * @param {Array} filteredDataSource - The filtered data source containing event information.
   * @returns None
   */
  useEffect(() => {
    let events = [];

    filteredDataSource?.forEach((item) => {
      const title = item.name;
      const start = moment(item.eventStartDate).utc().format();
      const end = item.eventEndDate
        ? moment(item.eventEndDate).utc().format()
        : moment(item.eventStartDate).add(10, 'years').utc().format(); // Use start date if end date is not available
      const backgroundColor = item?.color ? item.color : '#000000';
      const textColor = item?.color ? '#454545' : '#ffffff';

      const duration = moment.duration(moment(end).diff(moment(start)));
      const daysDifference = duration.asDays();

      if (daysDifference > 0) {
        // If the event spans multiple days, create individual events for each day
        for (let i = 0; i <= daysDifference; i++) {
          const currentDay = moment(start).add(i, 'days').utc().format();

          events.push({
            title,
            start: currentDay,
            end: currentDay,
            backgroundColor,
            textColor,
            eventDetails: item,
          });
        }
      } else {
        // If the event is for a single day, create one event
        const backgroundColor = item?.color ? item.color : '#000000';
        const textColor = item?.color ? '#454545' : '#ffffff';

        events.push({
          title,
          start,
          end,
          backgroundColor,
          textColor,
          eventDetails: item,
        });
      }
    });

    setEventsCalandar(events);
  }, [filteredDataSource.length]);

  /**
   * Executes the getMapView function whenever the categories array changes.
   * @param {Array} categories - The array of categories.
   * @param {string} service - The service to use for the map view.
   * @param {string} event - The event to trigger the map view.
   * @param {boolean} checkToggle - The toggle to check before executing the map view.
   * @returns None
   */
  useEffect(() => {
    if (categories.length > 0) {
      getMapView(categories, checkToggle, town);
    }
  }, [categories, town, checkToggle, location]);

  /**
   * Retrieves the filter category data asynchronously from the server.
   * @returns None
   * @throws {Error} If there is an error retrieving the category data.
   */
  const getFilterCategory = async () => {
    try {
      await AxiosInstance.get("user/getCategory").then((response) => {

        let temp = response?.payload;
        let obj = {
          categoryName: 'Uncategorised',
          color: "#000000",
          status: Constant.STATUS.ENABLE,
        };
        let newArr1 = [obj, ...temp];

        let tempArray = newArr1.map((item, index) => {
          let filterData = filterWrap?.some((value) => value._id === item._id);
          if (filterData) {
            item["status"] = Constant.STATUS.DISABLE;
          }
          return item;
        });

        setCategories(tempArray);
      });
    } catch (error) { }
  };

  /**
   * Retrieves a map view based on the provided parameters.
   * @param {Array} category - The category array to filter.
   * @param {boolean} serviceDetail - Indicates whether to include service details.
   * @param {boolean} eventDetail - Indicates whether to include event details.
   * @param {boolean} toggleDetail - Indicates whether to toggle details.
   * @returns None
   */
  const getMapView = async (
    category,
    toggleDetail,
    town
  ) => {
    let temp = category
      ?.filter((item, index) => {
        if (item.status === Constant.STATUS.ENABLE) {
          return item;
        }
      })
      .map((item) => item._id);
    try {
      let params = {
        category: category ? temp : [],
        isOnlyBusiness: false,
        isOnlyEvents: true,
        isOpen: toggleDetail,
        isUncategorised: true,
        town
      };
      if (location !== null) {
        location?.coordinates?.lng &&
          (params["longitude"] = location?.coordinates?.lng);
        location?.coordinates?.lat &&
          (params["latitude"] = location?.coordinates?.lat);
        // location?.coordinates?.lng &&
        //   (params["longitude"] = -2.9124);
        // location?.coordinates?.lat &&
        //   (params["latitude"] = 56.6980);
      }
      setIsLoading(true)
      await AxiosInstance.post("user/getServices", params).then((response) => {
        let eventList = response?.payload?.eventList || [];
        let eventCounts = response?.payload.eventCount;
        const uncategorisedStatus = categories.some(
          (cat) =>
            cat.categoryName === "Uncategorised" && cat.status === Constant.STATUS.DISABLE
        );

        if (uncategorisedStatus) {
          eventList = eventList.filter((evt) => evt.categoryId !== null);
        }
        let tempArray = [];
        const chunkSize = 50;
        for (let i = 0; i < eventList.length; i += chunkSize) {
          const chunk = eventList.slice(i, i + chunkSize);
          tempArray.push(chunk);
        }
        setMapView(eventList);
        setSliceData(tempArray)
        setEventShowCount(eventCounts);
        setFilteredDataSource(eventList);
        setIsLoading(false)
      });
    } catch (error) {
      setIsLoading(false)
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Determines the status of a business based on its opening and closing times for the current day.
   * @param {Array} dayTimeArray - An array of objects containing the opening and closing times for each day of the week.
   * @param {string} status - The current status of the business.
   * @returns {string} - The status of the business (either "Open" or "Closed").
   */
  const timingStatus = (dayTimeArray = [], status) => {
    const today = moment().utc();
    const day = today.format("ddd");
    let currentDay = {};
    let endTime;
    let startTime;
    let closeText = "Closed";
    currentDay = dayTimeArray.find((d) => d.day === day);
    if (Object.keys(currentDay).length > 0) {
      startTime = moment(
        `${today.format("DD/MM/YYYY")} ${currentDay.startTime}`,
        "DD/MM/YYYY HH:mm"
      );
      endTime = moment(
        `${today.format("DD/MM/YYYY")} ${currentDay.endTime}`,
        "DD/MM/YYYY HH:mm"
      );
      if (today.isBetween(startTime, endTime) && status !== "Close") {
        closeText = "Open";
      }
    }
    return closeText;
  };

  /**
   * Downloads multiple PDF files based on the sliceData array.
   * @returns None
   */

  const downloadPDF = async () => {
    console.log('Start downloading PDFs');

    setIsDownloading(true);
    for (let i = 0; i < sliceData.length; i++) {
      const input = document.getElementById(`events${i}`);
      const extraHeight = document.getElementById(`extraHeight`);
      const originalDisplay = input.style.display;
      input.style.display = 'block';
      extraHeight.style.display = 'block';
      const options = {
        filename: `all_events_${i}.pdf`,
        compression: 'MEDIUM'
      };

      await domToPdf(input, options);
      console.log("download completed - ", i);
      input.style.display = originalDisplay;
      extraHeight.style.display = originalDisplay;
    }
    setIsDownloading(false)
  };

  let localstorageBooks =
    JSON.parse(localStorage.getItem("bookmark_data")) || [];

  /**
   * Renders a component that displays a list of events and their details.
   * @returns JSX element
   */
  return (<>
    <div>
      <Helmet>
        <title>Events In Angus | Angus Recovery Road Map</title>
      </Helmet>
      <div className="viewWrap">
        <Button
          className={toggleView ? "mapview-btn active" : "mapview-btn "}
          onClick={() => setToggleView(true)}
        >
          {t("LIST_VIEW")}
        </Button>
        <Button
          className={!toggleView ? "listview-btn active" : "listview-btn "}
          onClick={() => {
            setToggleView(false);
            setsearchText("");
          }}
        >
          {t("Calendar_View")}
        </Button>
      </div>

      <div className={toggleFilter ? "filterWrap" : "filterWrap active"}>
        <Filter
          countedEvents={eventShowCount}
          onclick={() => setToggleFilter(!toggleFilter)}
          iconChange={toggleFilter ? "icon-filter" : "icon-close"}
          categoriesData={categories}
          isFromEvent={true}
          onCheckedToggle={(value) => {
            setCheckToggle(value);
          }}
          onUpdatedCategoryData={(value) => {
            setCategories([...value]);
          }}
          onUpdatedTown={(value) => {
            setTown(value);
          }}
          selectedTown={town}
          filterToggleWrap={checkToggle}
        />
      </div>
      <div className={toggleFilter ? "mainWrap" : "mainWrap move"}>
        <Header />
        <ViewDetails
          mapData={viewPage}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
        {toggleView &&
          <>
            <div className="eventViewrap listVieWrap">
              {isLoading ? <Loading show={isLoading} /> :
                <>
                  {filteredDataSource?.length === 0 ? (
                    <div className="no_data">
                      <i className="icon-info_circle_outline"></i>
                      <p>{t("OOPS_NO_EVENTS_FOUND")}</p>
                    </div>
                  ) : (
                    filteredDataSource?.map((item, index) => {
                      item["isbookmarked"] = localstorageBooks?.some(
                        (val) => val?._id === item?._id
                      );

                      let startDate;
                      let endDate;
                      let st = "Open";
                      if (item.type === "event") {
                        startDate = moment(`${moment(item?.eventStartDate)
                          .utc()
                          .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
                        if (item?.eventEndDate) {
                          endDate = moment(`${moment(item?.eventEndDate)
                            .utc()
                            .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                        } else {
                          endDate = "--:--";
                        }
                      } else {
                        let currentDay = moment().format("ddd");
                        let data = item?.availableDayTime?.filter((businessItem) => {
                          if (businessItem?.day === currentDay) {
                            return businessItem;
                          } else if (businessItem?.day !== currentDay) {
                          }
                        });
                        st = timingStatus(item?.availableDayTime, item?.isActive);
                      }

                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setShowModal(true);
                            setViewPage(item);
                          }}
                          style={{ color: item.color }}
                          className={"eventBox HomeEventWrapper"}
                        >
                          {item?.type === "event" ? (
                            <span className="icon icon-activity-pin"></span>
                          ) : (
                            <span className="icon icon-service-pin"></span>
                          )}
                          <div className="cardContent">
                            <div className="eventTop ">
                              <h2>
                                {item?.name}
                                {(item?.distance || item.distance == 0) && (
                                  <span className="distance">
                                    {parseFloat(item?.distance).toFixed(2)} miles
                                  </span>
                                )}
                              </h2>
                              <span className="category" style={{ color: item.color }}>
                                {item?.categoryName}
                              </span>
                              {item?.towns?.length > 0 &&
                                <div className="TownUl">
                                  <ul>
                                    {item?.towns?.map((town, id) => <li key={id}>{town}</li>)}
                                  </ul>
                                </div>
                              }
                            </div>
                            {item?.isActive !== "Open" && item?.isActive !== "open" ? (
                              <div className="timing">
                                <span>{t("TIMING")} - </span> {item?.isActive}
                              </div>
                            ) : (
                              <div className="timing">
                                {" "}
                                <span>{item?.isActive}</span>
                              </div>
                            )}
                            <div className="date">{`${startDate} - ${endDate}`}</div>
                            <div className="btn-wrap">
                              {item?.location &&
                                <a
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className="btn btn-primary direction"
                                  href={`https://www.google.com/maps/?q=${item?.location?.coordinates[1]},${item?.location?.coordinates[0]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="icon icon-coolicon"></span>
                                  {t("DIRECTIONS")}
                                </a>
                              }
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBookmarkBtn(true);
                                  const getbookMarkBookArr = JSON.parse(
                                    localStorage.getItem("bookmark_data")
                                  );
                                  const isBookMarkAdded = getbookMarkBookArr?.some(
                                    (itemData) => item._id === itemData._id
                                  );
                                  let localList = [...filteredDataSource];

                                  if (isBookMarkAdded) {
                                    if (
                                      getbookMarkBookArr &&
                                      getbookMarkBookArr.length > 0
                                    ) {
                                      const newData = getbookMarkBookArr.filter(
                                        (val) => item._id !== val._id
                                      );
                                      localList[index]["isbookmarked"] = false;
                                      item["isbookmarked"] = false;
                                      localStorage.setItem(
                                        "bookmark_data",
                                        JSON.stringify(newData)
                                      );
                                    }
                                  } else {
                                    let bookMarkArr = JSON.parse(
                                      localStorage.getItem("bookmark_data")
                                    );
                                    localList[index]["isbookmarked"] = true;
                                    item["isbookmarked"] = true;
                                    if (
                                      getbookMarkBookArr &&
                                      getbookMarkBookArr.length > 0
                                    ) {
                                      getbookMarkBookArr.push(item);
                                      localStorage.setItem(
                                        "bookmark_data",
                                        JSON.stringify(getbookMarkBookArr)
                                      );
                                    } else {
                                      localStorage.setItem(
                                        "bookmark_data",
                                        JSON.stringify([item])
                                      );
                                    }
                                  }
                                  setFilteredDataSource([...localList]);
                                }}
                                className={
                                  item.isbookmarked === true ? "isbookmarked bookmark" : "bookmark"
                                }
                              >
                                <span className="icon icon-bookmark-outline"></span>
                                {t("BOOKMARK")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                  <Button
                    className="download"
                    onClick={async () => {
                      downloadPDF()
                    }}
                  >
                    {isDownloading ? <ReactLoading type="balls" color={'#fff'} height={25} width={25} /> : <span className="icon icon-download"></span>}
                  </Button>
                </>
              }
            </div>
          </>
        }
        {!toggleView &&
          <div className="CustomeEventWrapper">
            <div className="CustomeEventSec">
              <CustomeEventCalender
                Event={eventsCalandar}
                handleCalenderEventClick={(item) => {
                  setShowModal(true);
                  setViewPage(item);
                }}
              />
            </div>
          </div>
        }
      </div>

      <div id="extraHeight"></div>
      {sliceData?.map((data, i) => {
        return (
          <div key={i} className="showData" id={`events${i}`}>
            <>
              <div className="titleText">
                <div className="service">
                  <span>{t("EVENTS")}</span>
                </div>
                <div style={{ height: 16 }}></div>
              </div>
              <div className="showTable">
                {data?.map((item, index) => {
                  let startDate;
                  let endDate;
                  let st = "Open";
                  if (item?.type === "event") {
                    startDate = moment(`${moment(item?.eventStartDate)
                      .utc()
                      .format("yyyy/MM/DD")}
              ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
                    if (item?.eventEndDate) {
                      endDate = moment(`${moment(item?.eventEndDate)
                        .utc()
                        .format("yyyy/MM/DD")}
                  ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                    } else {
                      endDate = "--:--";
                    }
                  } else {
                    let currentDay = moment().format("ddd");
                    let data = item?.availableDayTime?.filter((businessItem) => {
                      if (businessItem?.day === currentDay) {
                        return businessItem;
                      } else if (businessItem?.day !== currentDay) {
                      }
                    });
                  }

                  return (
                    <>
                      <div key={index} className="servicesEventsDetails">
                        <div className="categoryList">
                          <div className="category">
                            {item?.categoryName}
                            {item?.onlineStatus && <span>{t("ONLINE")}</span>}
                          </div>
                          <div className="name">{item?.name}</div>
                          <div className="description">
                            {item?.description}
                          </div>
                          {item?.towns?.length > 0 && (
                            <>
                              <div className="title">{t("TOWNS")} </div>
                              <div className="TownList">
                                <ul>
                                  {item?.towns?.map((item, i) => (
                                    <li key={i}>{item}</li>
                                  ))}
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="categoryList categoryList1"></div>
                        <div className="categoryList categoryList2">
                          <div className="location">
                            <span>{t("EVENT_TIMING")}: </span>
                            {`${startDate} - ${endDate}`}
                          </div>
                          <div className="location">
                            <span>{t("LOCATION")}: </span>
                            {item?.address ? item?.address : t("NOT_AVAILABLE")}
                          </div>
                        </div>
                      </div>
                      <div style={{ height: 16 }}></div>
                    </>
                  );
                })}
              </div>
            </>
          </div>
        );

      })
      }
    </div>
  </>
  );
};

export default Event;
