/* eslint-disable no-unused-vars */

import "./filter.scss";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CheckBox from "../checkbox/checkBox";
import CategoryCheckbox from "../categoryCheckbox/categoryCheckbox";
import CustomToggleButton from "../customToggleButton/customToggleButton";
import CustomeSelectBox from "../customeSelectBox/customeSelectBox";
import Constant from "../../util/constant";
import { useDispatch, useSelector } from "react-redux";
import { getTownList } from "../../state/ducks/townDetails/action"

/**
 * A functional component that represents a filter.
 * @param {object} props - The props passed to the component.
 * @returns The rendered filter component.
 */
const Filter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const townList = useSelector(state => state?.townDetails?.townList?.payload || [])

  let tempCount = props?.count || 0;
  let FromEvent = props?.isFromEvent;
  let FromBookmark = props?.isFromToggle;
  let bookmarked = props?.BookmarkFilter || 0;
  let tempCategory = props?.categoriesData;
  let eventTempCount = props?.countedEvents || 0;
  let selectedTown = props?.selectedTown || 'All'
  let filterEventWrap = props?.filterEventWrap;
  let filterToggleWrap = props?.filterToggleWrap;
  let filterServiceWrap = props?.filterServiceWrap;

  const [townsOptions, setTownsOptions] = useState([]);
  const [selectedTownOption, setSelectedTownOption] = useState({});

  /**
   * useEffect hook that updates the towns options when the townList length changes.
   * @param {function} effect - The effect function to be executed.
   * @param {Array} dependencies - An array of dependencies that trigger the effect when changed.
   * @returns None
   */
  useEffect(() => {
    const towns = [{ value: 'All', label: 'All Areas' }, ...townList]
    setTownsOptions(towns)
  }, [townList.length])

  /**
   * Executes the specified effect function once, immediately after the component is mounted.
   * The effect function dispatches the action to get the town list using the Redux dispatch function.
   * @param {function} dispatch - The Redux dispatch function.
   * @returns None
   */
  useEffect(() => {
    dispatch(getTownList())
  }, [])

  /**
   * useEffect hook that updates the selected town option whenever the townsOptions array length changes.
   * @param {function} setSelectedTownOption - The function to update the selected town option.
   * @param {Array} townsOptions - The array of town options.
   * @param {string} selectedTown - The currently selected town.
   * @returns None
   */
  useEffect(() => {
    setSelectedTownOption(townsOptions?.find(town => town?.value === selectedTown))
  }, [townsOptions.length])

  const cloudnaryImage =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER;

  /**
   * Renders a filter component with various options for filtering data.
   * @param {Object} props - The props object containing the necessary data and event handlers.
   * @returns The JSX code for the filter component.
   */
  const handleTownChange = (town) => {
    localStorage.setItem("selectedTown", town.value);
    props.onUpdatedTown && props.onUpdatedTown(town.value)
  }

  return (
    <>
      <Button onClick={props.onclick}>
        <span className={props.iconChange}></span>
      </Button>
      <div className="innerWrap">
        <div className="banner text-center">
          <div className="WebsidebarFilter">
            <div className="bgSidebar">
              <img src={cloudnaryImage + "banner_angus.png"} />
            </div>
            <div className="Sidebarlogo">
              <img src={cloudnaryImage + "Logo.png"} />
            </div>
          </div>
        </div>
        <div className="filterTypeWrap">
          <h1>
            {t("FILTER")}
            <span>
              {eventTempCount
                ? eventTempCount
                : bookmarked
                  ? bookmarked
                  : tempCount}
            </span>
          </h1>

          <div className="location-wrapper">
            <h2 className="filter-title">{t("LOCATION")}</h2>
            <div className="lacationFilterWrapper">
              {selectedTownOption && (
                <CustomeSelectBox
                  placeholder={t("SELECT_TOWN")}
                  defaultValue={selectedTownOption}
                  onChange={handleTownChange}
                  options={townsOptions}
                  isMulti={false}
                  className={"multipleSelectBox"}
                  components={false}
                />
              )}
              <span className="arrow-dropdown icon-sorting"></span>
            </div>

          </div>

          <div className="categories filterCategories">
            <h2 className="filter-title">{t("CATEGORIES")}</h2>
            <ul>
              {tempCategory?.map((item, index) => {
                return (
                  <div key={index} className="checkboxRow">
                    <CategoryCheckbox
                      id={`categories${item._id}`}
                      color={item.color}
                      labelTitle={item.categoryName}
                      checked={item.status === Constant.STATUS.ENABLE ? true : false}
                      onCheckedChange={(checked) => {
                        let newArr = tempCategory;
                        newArr[index].status = checked ? Constant.STATUS.ENABLE : Constant.STATUS.DISABLE;
                        props.onUpdatedCategoryData(newArr);
                        let filteredData = tempCategory?.filter((item) => {
                          return !(item?.status === Constant.STATUS.ENABLE);
                        });
                        localStorage.setItem(
                          "homeFilter",
                          JSON.stringify(filteredData)
                        );
                      }}
                    ></CategoryCheckbox>
                  </div>
                );
              })}
            </ul>
          </div>

          {FromEvent !== true ? (
            <div className="domains filterDomain">
              <h2 className="filter-title">{t("DOMAINS")}</h2>
              <CheckBox
                labelTitle={t("Service")}
                name="service"
                id="service"
                checked={filterServiceWrap}
                onCheckedChange={(serviceValue) => {
                  localStorage.setItem("serviceFilter", serviceValue);
                  props.onCheckedServices(serviceValue);
                }}
              />
              <CheckBox
                labelTitle={t("Event")}
                name="event"
                id="event"
                checked={filterEventWrap}
                onCheckedChange={(checked) => {
                  localStorage.setItem("eventFilter", checked);
                  props.onCheckedEvent(checked);
                }}
              />
            </div>
          ) : null}
          {FromBookmark !== true ? (
            <div className="onGoing onGoing-Wrapper">
              <p className="title">{t("ONGOING_NOW")}</p>
              <CustomToggleButton
                onCheckChange={(checked) => {
                  props.onCheckedToggle(checked);
                  localStorage.setItem("toggleFilter", checked);
                }}
                checked={filterToggleWrap}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Filter;
