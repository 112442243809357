import React from 'react';
import CustomToggleButton from '../customToggleButton/customToggleButton';
import './listBusiness.scss';


/**
 * A functional component that renders a business item in a list.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.SortName - The sort name of the business.
 * @param {string} props.title - The title of the business.
 * @param {string} props.location - The location of the business.
 * @param {boolean} props.checked - Indicates whether the business is checked.
 * @param {function} props.onCheckChange - The function to call when the check state changes.
 * @param {string} props.color - The color associated with the business.
 * @param {function} props.onClickDelete - The function to call when the delete button is clicked.
 */
const ListBusiness = (props) => {

  let { SortName, title, location, checked, onCheckChange, color, onClickDelete, className, onListItemClick, selected, onlineStatus } = props
  return (
    <div className={"ListBusinessMain" + className} >
      <button className={selected ? "full_btn list_selected_bg" : "full_btn"} style={{ borderColor: selected ? color : "#fff" }} type="button" onClick={onListItemClick}>
        <span className="active_bg" style={{ backgroundColor: color }}></span>
        <div className="title_left">
          <span className="cricle_bg" style={{ backgroundColor: color }}>
            {SortName}
            <span className="selected_bg" style={{ backgroundColor: color }}></span>
          </span>
          <div className="title_row">
            <div className="title_txt">{title}</div>
            <div className="location"><span className="icon-pin"></span>{onlineStatus && !location ? 'Online' : location}</div>
          </div>
        </div>
        <div className="action_right" onClick={(e) => { e.stopPropagation() }}>
          <button onClick={onClickDelete} className="icon-delete"></button>
          <CustomToggleButton id="" checked={checked} onCheckChange={onCheckChange} />
        </div>
      </button>
    </div>
  );
}

export default ListBusiness;
