/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Editor } from "react-draft-wysiwyg";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Constant from "../../util/constant";
import HookForm from "../hookForm/hookForm";
import { showToast } from "../../state/ducks/utils/operations";
import { getItem, setItem } from "../../services/localStorageService";

import CustomButton from "../customButton/customButton";
import ConfirmModal from "../confirmModal/confirmModal";
import TextFieldNoRequired from "../textFieldNoRequired/textFieldNoRequired";
import GooglePlaceDropDown from "../googlePlaceDropDown/googlePlaceDropDown";
import {
  updatePrivacyPolicy,
  getProfileDetails,
  updateProfileDetails,
} from "../../state/ducks/profile/action";
import "./appConfigurations.scss";

const AppConfigurations = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth.session)
  const userDetails = getItem("userDetails") || user;

  const [form, setLoginForm] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [busy, setBusy] = React.useState(false);
  const [portLocation, setPortLocation] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showEditPrivacyPolicy, setShowEditPrivacyPolicy] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  /**
   * Runs the getProfileDetailsData function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    getProfileDetailsData();
  }, []);

  useEffect(() => {
    if (!props.showAppConfigurations) {
      setShowContactDetails(false)
    }
  }, [props.showAppConfigurations])
  /**
   * Retrieves profile details data asynchronously.
   * @returns None
   * @throws {Error} If an error occurs while fetching the profile details data.
   */
  const getProfileDetailsData = async () => {
    try {
      const res = await dispatch(getProfileDetails())
      // console.log("getProfileDetailsData res ==> ", res);
      setItem("userDetails", res?.payload);
      let longitude = res?.payload?.location?.coordinates[0];
      setLongitude(longitude);
      let latitude = res?.payload?.location?.coordinates[1];
      setLatitude(latitude);
      try {
        let locationAddress = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
        );
        setCurrentAddress(locationAddress?.data?.results[0]?.formatted_address);
      } catch (error) {
      }
      if (res.payload.privacyPolicy) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(res?.payload?.privacyPolicy))
          )
        );
      }
    } catch (error) {
      console.log("profile details error ==> ", error);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Handles the submission of an update to the privacy policy.
   * @param {Event} e - The event object containing the form data.
   * @returns None
   */
  const onUpdateSubmit = async (e) => {
    let params = {
      contactEmail: e?.email,
      phoneNumber: e?.phoneNumber,
      address: e?.address,
      countryCode: e?.cunrtyCode,
      longitude: e?.portLocation?.location?.long
        ? e?.portLocation?.location?.long
        : longitude,
      latitude: e?.portLocation?.location?.lat
        ? e?.portLocation?.location?.lat
        : latitude,
    };
    try {
      setBusy(true);
      const res = await dispatch(updateProfileDetails(params))
      setItem("userDetails", res?.payload);
      let longitude = res?.payload?.location?.coordinates[0];
      setLongitude(longitude);
      let latitude = res?.payload?.location?.coordinates[1];
      setLatitude(latitude);
      try {
        let locationAddress = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
        );
        setCurrentAddress(
          locationAddress?.data?.results[0]?.formatted_address
        );
      } catch (error) {
        console.log("error in google map api ==>", error);
      }
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
      setBusy(false);
      setShowContactDetails(false);
      props.onHide()
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Handles the submission of the privacy policy update form.
   * @param {Event} e - The event object representing the form submission.
   * @returns None
   */
  const onPrivacyUpdateSubmit = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    let params = {
      privacyPolicy: JSON.stringify(rawContentState),
    };
    try {
      setBusy(true);
      const res = await dispatch(updatePrivacyPolicy(params))
      setItem("userDetails", res?.payload);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
      setBusy(false);
      setShowEditPrivacyPolicy(false)
      // props.onHide()
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * An object representing a contact details form with validation rules for each field.
   * @property {object} email - The email field configuration.
   * @property {string} email.name - The name of the email field.
   * @property {object} email.validate - The validation rules for the email field.
   * @property {object} email.validate.required - The required validation rule for the email field.
   * @property {boolean} email.validate.required.value - Whether the email field is required.
   * @property {string} email.validate.required.message - The error message to display if the email field is required.
   * @property {object} email.validate.pattern - The pattern validation rule for the email field.
   * @property {string} email.validate.pattern
   */
  const contactDetailsForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("EMAIL_INVALID"),
        },
      },
    },
    cunrtyCode: {
      name: "cunrtyCode",
      validate: {},
    },
    phoneNumber: {
      name: "phoneNumber",
      validate: {
        required: {
          value: true,
          message: t("PHONE_NUMBER_REQUIRED"),
        },
        maxLength: { value: 13, message: t("PHONE_NUMBER_INVALID") },
        minLength: { value: 10, message: t("PHONE_NUMBER_INVALID") },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: true,
          message: t("ADDRESS_REQUIRED"),
        },
      },
    },
    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: false,
          message: t("required"),
        },
      },
    },
  };

  /**
   * Represents a privacy policy form with a description field.
   * @type {Object}
   * @property {Object} description - The description field.
   * @property {string} description.name - The name of the description field.
   * @property {Object} description.validate - The validation rules for the description field.
   * @property {Object} description.validate.pattern - The pattern validation rule for the description field.
   * @property {RegExp} description.validate.pattern.value - The regular expression pattern for the description field.
   * @property {string} description.validate.pattern.message - The error message to display if the description field does not match the pattern.
   */
  const privacyPolicyForm = {
    description: {
      name: "description",
      validate: {
        pattern: {
          value: /.*/s,
          message: t("URL_INVALID"),
        },
      },
    },
  };

  /**
   * Renders a component that displays contact details and allows editing of those details.
   * @returns JSX element
   */
  return (
    <div className="AppConfigurationsPage">
      {!showContactDetails ? (
        <div className="contact_details">
          <div className="contact_box">
            <div className="title_row">
              <div className="title_txt">{t("CONTACT_DETAILS")}</div>
              <button
                onClick={() => setShowContactDetails(true)}
                className="icon-pencil"
              ></button>
            </div>
            <div className="field_row">
              <div className="input_title">{t("EMAIL_ADDRESS")}</div>
              <div className="input_txt">
                {userDetails && userDetails.contactEmail}
              </div>
            </div>
            <div className="field_row">
              <div className="input_title">{t("PHONE_NUMBER")}</div>
              <div className="input_txt">{`+${userDetails?.countryCode ? userDetails?.countryCode : "44"
                } ${userDetails && userDetails.phoneNumber}`}</div>
            </div>
            <div className="field_row">
              <div className="input_title">{t("LOCATION")}</div>
              <div className="input_txt outlined">
                {userDetails && userDetails.address}
              </div>
              <div className="input_txt outlined">
                {(userDetails && currentAddress) && currentAddress}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contact_details edit_contact_details">
          <div className="title_txt">{t("CONTACT_DETAILS")}</div>
          <HookForm
            init={(form) => setLoginForm(form)}
            onSubmit={(e) => onUpdateSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="input_title">{t("EMAIL_ADDRESS")}</div>
                  <div className="inputBoxWrapper">
                    <TextFieldNoRequired
                      formMethod={formMethod}
                      rules={contactDetailsForm.email.validate}
                      name={contactDetailsForm.email.name}
                      errors={formMethod?.formState.errors}
                      autoFocus={true}
                      defaultValue={userDetails.contactEmail}
                      type="text"
                      autoComplete="on"
                      textFieldClassName="fullTextBox"
                      placeholder={t("EMAIL_ADDRESS")}
                    />
                  </div>

                  <div className="input_title">{t("PHONE_NUMBER")}</div>
                  <div className="phone_view">
                    <div className="countryPhoneGroup">
                      <Controller
                        defaultValue={
                          userDetails?.countryCode
                            ? userDetails?.countryCode
                            : "44"
                        }
                        control={formMethod?.control}
                        name={contactDetailsForm.cunrtyCode.name}
                        rules={contactDetailsForm.cunrtyCode.validate}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <PhoneInput
                              country={"gb"}
                              disableSearchIcon="false"
                              placeholder={t("+44")}
                              enableSearch="true"
                              value={formMethod.watch(
                                contactDetailsForm.cunrtyCode.name
                              )}
                              onChange={onChange}
                            />
                          </>
                        )}
                      />
                      <TextFieldNoRequired
                        redStar={true}
                        noTextfield={true}
                        onChange={(e) => { }}
                        maskType="99999999999"
                        formMethod={formMethod}
                        rules={contactDetailsForm.phoneNumber.validate}
                        name={contactDetailsForm.phoneNumber.name}
                        errors={formMethod?.formState.errors}
                        placeholder={t("PHONE_NUMBER")}
                        textFieldClassName="fullTextBox"
                        defaultValue={userDetails?.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="input_title">{t("LOCATION")}</div>
                  <div className="location_section">
                    <TextFieldNoRequired
                      formMethod={formMethod}
                      rules={contactDetailsForm.address.validate}
                      name={contactDetailsForm.address.name}
                      errors={formMethod?.formState.errors}
                      autoFocus={true}
                      defaultValue={userDetails?.address}
                      type="text"
                      autoComplete="on"
                      textFieldClassName="fullTextBox"
                      placeholder={t("LOCATION")}
                      maxLength={150}
                    />

                    <div className="location_part">
                      <div>
                        <Controller
                          control={formMethod?.control}
                          name={contactDetailsForm.portLocation.name}
                          rules={contactDetailsForm.portLocation.validate}
                          render={({ field: { onChange } }) => (
                            <GooglePlaceDropDown
                              defaultValue={{
                                label: currentAddress,
                                value: currentAddress ? currentAddress : {},
                              }}
                              getLocation={(place) => {
                                place === null
                                  ? setPortLocation(null)
                                  : setPortLocation({
                                    portLocation: {
                                      latitude: get(place, "location.lat", 1),
                                      longitude: get(
                                        place,
                                        "location.long",
                                        1
                                      ),
                                      address: get(place, "address", ""),
                                    },
                                  });
                                onChange(place);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="map_view">
                        <iframe
                          title={t("map")}
                          className="map"
                          src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                            }&q=${get(
                              portLocation,
                              "portLocation.latitude",
                              latitude ? latitude : 1
                            )},${get(
                              portLocation,
                              "portLocation.longitude",
                              longitude ? longitude : 1
                            )}`}
                          width="100%"
                          height="330px"
                          frameBorder="0"
                          allowFullScreen={false}
                          aria-hidden={false}
                          tabIndex={0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={t("UPDATE")}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                    />
                    <CustomButton
                      type="button"
                      className="outline_btn"
                      title={t("CANCEL")}
                      onClick={() => {
                        setShowContactDetails(false);
                      }}
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}
      <div className="other_details">
        <div className="other_title">{t("OTHER")}</div>
        <div className="contact_box">
          <div className="title_row">
            <div className="title_txt">{t("PRIVACY_POLICY")}</div>
            <div className="privacyWrapper">
              <div className="title_txt">{t("Last_updated")}{" "}
                {t(moment(userDetails?.PrivacyPolicyUpdateTime).format("DD MMM YYYY"))}
              </div>
              <button
                onClick={() => setShowEditPrivacyPolicy(true)}
                className="icon-pencil"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        modalTitle={t("PRIVACY_POLICY")}
        leftBtnTitle={t("UPDATE")}
        rightBtnTitle={t("CANCEL")}
        showModal={showEditPrivacyPolicy}
        onHide={() => setShowEditPrivacyPolicy(false)}
        onProcced={() => setShowEditPrivacyPolicy(false)}
        modalContent={false}
        btnVisible={false}
        className={"edit_privacy_policy"}
      >
        <HookForm defaultForm={{}}>
          {(formMethod) => {
            return (
              <>
                <div className="date_txt">
                  Last updated:{" "}
                  {moment(userDetails?.PrivacyPolicyUpdateTime).format(
                    "DD MMM YYYY HH:MM A"
                  )}
                </div>
                <div className="textareaBorder">
                  <Controller
                    control={formMethod?.control}
                    name={privacyPolicyForm.description.name}
                    render={({ field: { onChange, value } }) => (
                      <Editor
                        placeholder={t("PRIVACY_POLICY")}
                        editorStyle={{ resize: "vertical" }}
                        toolbar={{
                          options: ["inline", "list", "link"],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["bold", "italic", "underline"],
                            bold: { className: undefined },
                            italic: { className: undefined },
                            underline: { className: undefined },
                            strikethrough: { className: undefined },
                            monospace: { className: undefined },
                            superscript: { className: undefined },
                            subscript: { className: undefined },
                          },
                          blockType: {
                            inDropdown: true,
                            options: [
                              "Normal",
                              "H1",
                              "H2",
                              "H3",
                              "H4",
                              "H5",
                              "H6",
                              "Blockquote",
                              "Code",
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          fontSize: {
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          fontFamily: {
                            options: [
                              "Arial",
                              "Georgia",
                              "Impact",
                              "Tahoma",
                              "Times New Roman",
                              "Verdana",
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["unordered", "ordered"],
                            unordered: { className: undefined },
                            ordered: { className: undefined },
                            indent: { className: undefined },
                            outdent: { className: undefined },
                          },
                          textAlign: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["left", "center", "right", "justify"],
                            left: { className: undefined },
                            center: { className: undefined },
                            right: { className: undefined },
                            justify: { className: undefined },
                          },
                          link: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            dropdownClassName: undefined,
                            showOpenOptionOnHover: true,
                            defaultTargetOption: "_self",
                            options: ["link"],
                            link: { className: undefined },
                            unlink: { className: undefined },
                            linkCallback: undefined,
                          },
                          embedded: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            embedCallback: undefined,
                            defaultSize: {
                              height: "auto",
                              width: "auto",
                            },
                          },
                          image: {
                            className: "display-none",
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: undefined,
                            previewImage: false,
                            inputAccept:
                              "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                            alt: { present: false, mandatory: false },
                            defaultSize: {
                              height: "0px",
                              width: "0px",
                            },
                          },
                          remove: {
                            className: undefined,
                            component: undefined,
                          },
                          history: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["undo", "redo"],
                            undo: { className: undefined },
                            redo: { className: undefined },
                          },
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="resizeClass"
                        editorState={editorState}
                        onEditorStateChange={(e) => {
                          setEditorState(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="custombtnfield">
                  <CustomButton
                    type="submit"
                    title={t("UPDATE")}
                    disabled={!formMethod?.formState.isValid}
                    loading={busy}
                    onClick={() => {
                      onPrivacyUpdateSubmit();
                    }}
                  />
                  <CustomButton
                    type="button"
                    className="outline_btn"
                    title={t("CANCEL")}
                    onClick={() => {
                      setShowEditPrivacyPolicy(false);
                    }}
                  />
                </div>
              </>
            );
          }}
        </HookForm>
      </ConfirmModal>
    </div>
  );
};

export default AppConfigurations;
