import * as types from './types';

export const getTownList = () => (
    {
        type: types.GET_TOWN_LIST,
        meta: {
            async: true,
            blocking: false,
            path: `user/getTownList`,
            method: 'GET',
        }
    })
