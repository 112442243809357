import React from 'react';
import './customDropdown.scss';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

const CustomDropdown = (props) => {
    let { selectedValue, dropDownItems = [], placeholder, onSelect, defaultValue, disabled, ColorSelected } = props;
    const getTitle = () => {

        if (selectedValue && selectedValue.id) {
            const item = dropDownItems.find(i => i.id === selectedValue.id || i.name === selectedValue)
            return (item && item.value) || placeholder
        } else {
            return defaultValue ? defaultValue : placeholder
        }
    }

    return (
        <div className={disabled ? "customDropdown disable" : "customDropdown"} >
            <DropdownButton
                key={selectedValue?.id}

                id={`dropdown-variants-${selectedValue?.id}`}
                title={getTitle()}
                onSelect={evt => onSelect(dropDownItems.find(i => i.id === evt))}
                className={selectedValue ? "selected" : ""}
            >
                <div className="dropdownData">
                    {dropDownItems.map((item) => <Dropdown.Item eventKey={item.id} >
                        {ColorSelected && 
                        <span className="cricle_view" style={{ backgroundColor: item.color }}></span>
                        }
                        {item.value}</Dropdown.Item>)}
                </div>
            </DropdownButton>
        </div>
    )
}

CustomDropdown.propTypes = {
    onClick: PropTypes.func
};

export default CustomDropdown;
