/* eslint-disable import/no-anonymous-default-export */
const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

/**
 * Retrieves the access token from the browser's local storage.
 * @returns {string | null} The access token, or null if it does not exist.
 */
export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

/**
 * Retrieves the refresh token from the browser's local storage.
 * @returns {string | null} The refresh token, or null if it does not exist.
 */
export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

/**
 * Saves the access and refresh tokens to the local storage.
 * @param {Object} token - The token object containing the access and refresh tokens.
 * @param {string} token.access.token - The access token.
 * @param {string} token.refresh.token - The refresh token.
 * @returns None
 */
export const saveToken = token => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token.access.token);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh.token);
};

/**
 * Removes the access token and refresh token from the browser's local storage.
 * @returns None
 */
export const destroyToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, getRefreshToken };
