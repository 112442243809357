import React, { useState, useEffect } from 'react';
import './checkBox.scss';

/**
 * A checkbox component that allows users to select or deselect an option.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.labelTitle - The title of the checkbox label.
 * @param {string} props.id - The unique identifier for the checkbox.
 * @param {function} props.onCheckedChange - The callback function triggered when the checkbox is checked or unchecked.
 * @param {function} props.register - The function used to register the checkbox with a form library.
 * @param {string} props.name - The name of the checkbox.
 * @param {boolean} props.disabled - Determines if the checkbox is disabled or not.
 * @param {string} props.uniqueId -
 */
const CheckBox = (props) => {
    const { labelTitle, id, onCheckedChange, register, name, disabled, uniqueId } = props;
    const [checked, setChecked] = useState(!props.checked);

    /**
     * useEffect hook that updates the state of the component whenever the value of props.checked changes.
     * @param {function} setChecked - The state setter function for the checked state.
     * @param {boolean} props.checked - The value of the checked prop.
     * @returns None
     */
    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    /**
     * Handles the event when the checkbox is checked or unchecked.
     * @param {Event} e - The event object.
     * @returns None
     */
    const onChecked = (e) => {
        setChecked(e.target.checked);
        onCheckedChange && onCheckedChange(e.target.checked);
    };

    return (
        <div className="checkboxMain" key={`check_${uniqueId}`}>
            <input type="checkbox" ref={register} disabled={disabled} name={name} value={labelTitle} id={id} className='checkbox' checked={checked} onChange={onChecked} onClick={props.onClick} />
            <label className='checkboxLabel' id="checkLabel" htmlFor={id}>{labelTitle}</label>
        </div>
    );
};

CheckBox.defaultProps = {
    id: '',
    checked: false,
    labelTitle: ''
};

export default CheckBox;
