import React, { useState, useEffect } from "react";
import './customToggleButton.scss';

const CustomToggleButton = (props) => {
    let { onCheckChange } = props
    const [checked, setChecked] = useState(props.checked)
    let { id } = props

    /**
     * useEffect hook that updates the state of the checked property whenever it changes.
     * @param {boolean} props.checked - The checked property to update the state with.
     * @returns None
     */
    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    /**
     * Handles the event when the toggle button is checked or unchecked.
     * @param {Event} e - The event object.
     * @returns {void}
     */
    const onToggleButtonChecked = (e) => {
        return (
            onCheckChange && onCheckChange(e.target.checked)
        )
    }
    return (
        <div className="CustomToggleButton-container">
            <label className="CustomToggleButton-main">
                <input type="checkbox" id={id} checked={checked} onChange={onToggleButtonChecked} />
                <span className="slider-button round"></span>
            </label>
        </div>
    )
}

//minro change
CustomToggleButton.defaultProps = {
    id: '',
    checked: false,
    labelTitle: '',
}

export default CustomToggleButton;