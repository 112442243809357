/* eslint-disable react/jsx-no-target-blank */
import { Container, Modal } from "react-bootstrap";
import React from "react";
import "./oursupporters.scss";
import { useTranslation } from "react-i18next";
import Constant from "../../util/constant";

/**
 * A functional component that renders a modal displaying the supporters of a cause.
 * @param {object} props - The component props.
 * @param {boolean} props.showModal - Determines whether the modal is shown or hidden.
 * @param {function} props.modalClose - Callback function to close the modal.
 * @returns The JSX code for rendering the supporters modal.
 */
const OurSupporters = (props) => {
  const {t} = useTranslation()

  const cloudnaryImage = process.env.REACT_APP_CLOUDNARY_URL + process.env.REACT_APP_CLOUDNARY_NAME + process.env.REACT_APP_CLOUDNARY_SUBFLODER

  return (
    <>
      {/**
       * Renders a modal component with a list of supporters.
       * @param {boolean} showModal - Determines whether the modal is shown or hidden.
       * @param {function} modalClose - Callback function to close the modal.
       * @returns A modal component displaying a list of supporters.
       */}
      <Modal
        centered
        className="supportersModal"
        show={props.showModal}
        onHide={() => {
          props.modalClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="support-head">{t("OURSUPPORTERS")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="d-flex flex-wrap text-center align-items-center justify-content-between">
              <a href={Constant.REDIRECTURL.SVRU} target="_blank">
                <img
                  src={cloudnaryImage + "violence-reduction-unit.jpg"}
                  alt="Violance Reduction Unit"
                  height="46"
                  width="140"
                />
              </a>
              <a href={Constant.REDIRECTURL.QNIS} target="_blank">
                <img
                  src={cloudnaryImage + "qnis.jpg"}
                  alt="The Queens Nurising Institute Scotland"
                  height="91"
                  width="150"
                />
              </a>
              <a href={Constant.REDIRECTURL.STREETSOCCERSCOTLAND} target="_blank">
                <img
                  src={cloudnaryImage + "street-soccer-scotland.jpg"}
                  alt="Street Soccer Scotland"
                  height="91"
                  width="194"
                />
              </a>
            </div>
            <div className="d-flex flex-wrap text-center align-items-center justify-content-between">
              <a href={Constant.REDIRECTURL.DUNDEEANDANGUS} target="_blank">
                <img
                  src={cloudnaryImage + "foodbank.webp"}
                  alt="Dundee And Angus foodbank"
                  height="91"
                  width="150"
                />
              </a>
              <a
                href={Constant.REDIRECTURL.THESTEEPLECHURCH}
                target="_blank"
              >
                <img
                  src={cloudnaryImage + "dundelogo.jpg"}
                  alt="Parish Nurising Dundee"
                  height="95"
                  width="105"
                />
              </a>
              <a href={Constant.REDIRECTURL.DVVA} target="_blank">
                <img
                  src={cloudnaryImage + "dundee-volunteer.jpg"}
                  alt="Dundee Volunteer and Volantary Action"
                  height="93"
                  width="234"
                />
              </a>
            </div>

            <div className="d-flex flex-wrap text-center align-items-center justify-content-start">
              <img
                src={cloudnaryImage + "Kolorkey_Digital_Logo-tagline.png"}
                alt="Kolorkey Digital"
                height="150"
                width="185"
              />
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default OurSupporters;
