import React, { useState, useEffect, useRef } from "react";
import { get } from "lodash";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Constant from "../../util/constant";
import "./googlePlaceDropDown.scss";
import { useTranslation } from "react-i18next";

const GooglePlaceDropDown = (props) => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  let { getLocation, defaultValue } = props;
  const { t } = useTranslation()
  const [value, setValue] = useState("New York, NY, USA");
  const [error, setError] = useState(false);
  const prevDefaultValue = usePrevious(defaultValue);

  /**
   * A React useEffect hook that updates the value based on changes in the defaultValue and prevDefaultValue.
   * If the label property of defaultValue is different from the label property of prevDefaultValue, 
   * the value is set to defaultValue. If the label property of defaultValue is an empty string, 
   * the value is set to an empty string.
   * @param {any} defaultValue - The new default value.
   * @param {any} prevDefaultValue - The previous default value.
   * @returns None
   */
  useEffect(() => {
    if (get(defaultValue, "label", "") !== get(prevDefaultValue, "label", ""))
      setValue(defaultValue);
    else if (get(defaultValue, "label", "") === "") {
      setValue("");
    }

  }, [defaultValue, prevDefaultValue]);

  useEffect(() => {
    // console.log("==>", defaultValue, props.isRequired);
    if (defaultValue === undefined && props.isRequired) setError(true)
    else setError(false)
  }, [props.isRequired, defaultValue])

  /**
   * Retrieves the latitude and longitude of a location based on its place ID.
   * @param {string} placeId - The place ID of the location.
   * @returns {Promise<{ lat: number, long: number }>} - A promise that resolves to an object containing the latitude and longitude of the location.
   */
  const getLocationAction = async (placeId) => {
    let location = {
      lat: 1,
      long: 1,
    };
    try {
      let place = placeId && (await geocodeByPlaceId(placeId));
      if (place.length > 0) {
        location["lat"] = place[0].geometry.location.lat();
        location["long"] = place[0].geometry.location.lng();
      }
    } catch (error) { }
    return location;
  };

  return (
    <div className={(error) ? "googleDropdown error" : "googleDropdown"}>
      <GooglePlacesAutocomplete
        apiKey={Constant.GOOGLEMAPAPI.APIKEY}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["uk"],
          },
        }}
        selectProps={{
          value,
          backspaceRemovesValue: true,
          controlShouldRenderValue: true,
          isClearable: true,
          placeholder:
            <>
              {t("ADDRESS")}
              {props.isRequired && <span style={{ color: 'red' }}>*</span>}
            </>
          ,
          onChange: async (e) => {
            setValue(e);
            if (e) {
              let location = await getLocationAction(e.value?.place_id);
              let address = {
                address: e.label,
                location,
              };
              getLocation && getLocation(address);
            } else {
              setError(true)
              getLocation(null);
            }
          },
        }}
      />
    </div>
  );
};

GooglePlaceDropDown.defaultProps = {};

export default GooglePlaceDropDown;
