import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "react-bootstrap";
import './toastComponent.scss';
import { hideToast } from "../../state/ducks/utils/actions";

/**
 * A functional component that renders a toast notification based on the state
 * of the ToastReducer. The toast can be of type "success", "error", or "warning",
 * and displays a corresponding icon and message. The toast can be closed by clicking
 * on the close button or automatically after a delay of 1000ms.
 * @returns The rendered toast component.
 */
const ToastComponent = () => {
  const toast = useSelector((state) => state.utils.toast);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const dispatch = useDispatch();

  /**
   * useEffect hook that updates the state of `showToast` whenever the `toast.show` value changes.
   * @returns None
   */
  useEffect(() => {
    // console.log("toast", toast);
    setShowToast(toast.show);
    setToastMessage(toast.message)
  }, [toast.show, toast.message]);

  let toastClass;

  /**
   * Determines the appropriate CSS classes for the given toast type and assigns them to the
   * corresponding variables.
   * @param {object} toast - The toast object containing the type property.
   * @returns None
   */
  if (toast.type === "success") {
    toastClass = "success-txt";
  } else if (toast.type === "error") {
    toastClass = "error-txt";
  } else if (toast.type === "warning") {
    toastClass = "warning-txt";
  }

  return (
    /**
     * Renders a Toast component with the specified props.
     * @param {function} onClose - Callback function to be executed when the Toast is closed.
     * @param {string} className - Additional CSS class name(s) to be applied to the Toast.
     * @param {boolean} show - Determines whether the Toast is visible or hidden.
     * @param {number} delay - The duration in milliseconds for which the Toast will be displayed before automatically hiding.
     * @param {boolean} autohide - Determines whether the Toast will automatically hide after the specified delay.
     * @returns The rendered Toast component.
     */
    showToast && <Toast
      onClose={() => {
        setShowToast(false);
        dispatch(hideToast());
      }}
      className={toastClass}
      show={showToast}
      delay={3000}
      autohide
    >
      <div className="tostcenter">
        <p>{toastMessage}</p>
      </div>
    </Toast>
  );
};

export default ToastComponent;
