import React from "react";
import './label.scss';

/**
 * A functional component that renders a label with a given title.
 * @param {Object} props - The props object containing the title and className.
 * @param {string} props.title - The title of the label.
 * @param {string} props.className - The class name to apply to the label.
 * @returns The rendered label component.
 */
const Label = (props) => {
    let { title } = props;
    return (
        <p className={'labelTitle ' + props.className}>{title}</p>
    )
}

Label.defaultProps = {
    className: '',
}
export default Label;