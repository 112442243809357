import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import * as reducers from "./ducks";
import { apiService, createLogger } from "./middlewares";
import { createMultilanguageReducer } from "redux-multilanguage";

export default function configureStore(initialState) {
  const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
    ...reducers,
  });
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(apiService, thunkMiddleware, createLogger(true))
  );
}