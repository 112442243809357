import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that retrieves the user's current geolocation coordinates.
 * @returns {Object} location - The user's geolocation information.
 * @returns {boolean} location.loaded - Indicates whether the geolocation data has been loaded.
 * @returns {Object} location.coordinates - The latitude and longitude coordinates of the user's location.
 * @returns {number} location.coordinates.lat - The latitude coordinate of the user's location.
 * @returns {number} location.coordinates.lng - The longitude coordinate of the user's location.
 * @returns {Object} location.error - The error object if there was an error retrieving the geolocation data.
 */
const useGeoLocation = () => {

    const { t } = useTranslation();

    const [location, setLocation] = useState({
        loaded: false,
    });

    /**
     * Callback function to handle successful retrieval of user's location.
     * @param {Object} location - The location object containing coordinates.
     * @param {number} location.coords.latitude - The latitude coordinate.
     * @param {number} location.coords.longitude - The longitude coordinate.
     * @returns None
     */
    const onSuccess = (location) => {
        setLocation({
            loaded: true,
            coordinates: {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
            },
        });
    };

    /**
     * Handles an error by updating the location state with the error information.
     * @param {Error} error - The error object to handle.
     * @returns None
     */
    const onError = error => {
        setLocation({
            loaded: false,
            error,
        });
    }

    /**
     * useEffect hook that checks if geolocation is supported in the browser and retrieves the current position.
     * @returns None
     */
    useEffect(() => {
        if (!("geolocation" in navigator)) {
            onError({
                code: 0,
                message: t("Gellocation_not_supported"),
            })
        }
        navigator.geolocation.getCurrentPosition(onSuccess, onError)
    }, []);

    return location;
}


export default useGeoLocation