/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import moment from "moment";
import "../../../node_modules/react-select-search/style.css";
import HookForm from "../../components/hookForm/hookForm";

import Label from "../../components/label/label";
import CheckBox from "../../components/checkbox/checkBox";
import Calender from "../../components/calender/calender";
import TextField from "../../components/textField/textField";
import TimePicker from "../../components/timePicker/timePicker";
import CustomButton from "../../components/customButton/customButton";
import CustomDropdown from "../../components/customDropdown/customDropdown";
import GooglePlaceDropDown from "../../components/googlePlaceDropDown/googlePlaceDropDown";

import Constant from "../../util/constant";
import { showToast } from "../../state/ducks/utils/operations";
import {
  getEventDetails,
  updateEventDetails,
} from "../../state/ducks/eventListDetails/operations";
import { getAllBusinessList } from "../../state/ducks/businessListDetails/operations";

import "./eventDetails.scss";
import axios from "axios";
import SelectSearch from "react-select-search";
import { useTranslation } from "react-i18next";
import CustomeSelectBox from "../customeSelectBox/customeSelectBox";
import CustomToggleButton from "../customToggleButton/customToggleButton";
import { getTownList } from "../../state/ducks/townDetails/action";

const EventDetails = (props) => {
  const [form, setLoginForm] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const townList = useSelector(state => state?.townDetails?.townList?.payload)

  const [portLocation, setPortLocation] = useState(null);
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [characterCount, setcharacterCount] = useState(0);
  const [categroy, setCategroy] = useState();
  const [eventInfoEdit, setEventInfoEdit] = useState(false);
  const [eventTimingEdit, setEventTimingEdit] = useState(false);
  const [busy, setBusy] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [customValue, setCustomValue] = useState(null);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endDate, setEndDate] = useState();
  const [endTime, setEndTime] = useState();
  const [eventData, setEventData] = useState({});
  const [currentAddress, setCurrentAddress] = useState(""); //Event Address
  const [categoryID, setCategoryId] = useState();
  const [businessId, setBusinessId] = useState();
  const [allBusinessDetails, setAllBusinessDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [businessAddress, setBusinessAddress] = useState(""); //for Business Address
  const [businessLocation, setBusinessLocation] = useState(""); //for Business Location
  const [eventAddress, setEventAddress] = useState();
  // const [checkBusinessName,setCheckBusinessName]=useState()
  const [serviceName, setServiceName] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(false);

  var todayDate = new Date();
  var dd = String(todayDate.getDate()).padStart(2, "0");
  var mm = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = todayDate.getFullYear();
  const today = dd + "/" + mm + "/" + yyyy;

  const colorList = useSelector(
    (state) => state?.CategoryDetails?.getCategoryListForColor?.categoryList
  );

  /**
   * Executes the getDropDownValue function when the length of the colorList array changes.
   * @returns None
   */
  useEffect(() => {
    getDropDownValue();
  }, [colorList?.length]);

  /**
   * Retrieves the dropdown value from the local storage and updates the state with the new value.
   * @returns None
   */
  const getDropDownValue = async () => {
    let data = localStorage.getItem("Uncategorised");
    let uncategorisedCategory = JSON.parse(data);

    let newArray = [];

    let uncategorised = {
      businessCount: 0,
      _id: uncategorisedCategory?._id,
      categoryName: uncategorisedCategory?.categoryName,
      color: uncategorisedCategory?.color,
      eventCount: uncategorisedCategory?.eventCount,
      insensitive: uncategorisedCategory?.insensitive,
      status: Constant.STATUS.ENABLE,
    };
    if (typeof colorList !== "undefined" && colorList.length > 0) {
      newArray = [...colorList, uncategorised];
      let name = newArray?.map((item) => {
        let colorObj = {
          id: item._id,
          value: item.categoryName,
          color: item.color,
        };
        return colorObj;
      });
      setServiceName(name);
    }
  };

  /**
   * Executes the specified functions when the component mounts or when the value of props.eventID changes.
   * @returns None
   */
  useEffect(() => {
    props.eventID && getEventData();
    getBusinessDetails();
    setEventTimingEdit(false)
    setEventInfoEdit(false)
  }, [props.eventID]);

  /**
   * Retrieves business details asynchronously.
   * @returns None
   */
  const getBusinessDetails = async () => {
    try {
      setBusy(true);
      const res = await dispatch(getAllBusinessList())
      let data = res?.payload;
      const tempdata = [];
      let businessData = data?.map(async (item, index) => {
        let blongitude = item?.location?.coordinates[0];
        let blatitude = item?.location?.coordinates[1];

        try {
          if (blatitude && blongitude) {

            let locationAddress =
              await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${blatitude},
                        ${blongitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`);
            let businessObj = {
              value: index,
              name: item.name,
              color: item.categoryColor,
              address: item.address,
              location: {
                latitude: item?.location?.coordinates[1],
                longitude: item?.location?.coordinates[0],
                place: locationAddress?.data?.results[0]?.formatted_address,
              },
              categoryTitle: item.categoryName,
              categoryID: item.categoryId,
              bId: item._id,
            };
            tempdata.push(businessObj);
            setAllBusinessDetails(tempdata);
            return businessObj;
          }
        } catch (error) { }
      });
      setBusy(false);
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Retrieves event data from the server and updates the state accordingly.
   * @returns None
   */
  const getEventData = async () => {
    try {
      setBusy(true);
      const res = await dispatch(getEventDetails(props?.eventID))
      let id = res?.payload?.eventDetails?.businessId;
      setBusinessId(id);
      setBusy(false);
      setEventData(res?.payload);
      setEventAddress(res?.payload?.eventDetails?.address);
      setOnlineStatus(res?.payload?.eventDetails?.onlineStatus);
      // console.log("event res", res.payload);
      if (res?.payload?.eventDetails?.location) {
        let longitude = res?.payload?.eventDetails?.location?.coordinates[0];
        setLongitude(longitude);

        let latitude = res?.payload?.eventDetails?.location?.coordinates[1];
        setLatitude(latitude);

        try {
          let locationAddress = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
          );
          // console.log("locationAddress", locationAddress);
          // setCurrentAddress(locationAddress?.data?.results[0]?.formatted_address);
          setCurrentAddress({
            label: locationAddress?.data?.results[0]?.formatted_address,
            value: { ...locationAddress?.data?.results[0] }
          });
        } catch (error) {
        }
      } else {
        setLongitude()
        setLatitude()
        setCurrentAddress()
      }

      // If Business Is Present
      if (
        res?.payload?.eventDetails?.businessId &&
        res?.payload?.eventDetails?.businessId !== null
      ) {
        let business = res?.payload?.businessAddress;
        let businessName = allBusinessDetails.find(bus => bus.name === res?.payload?.businessName)
        let businesslong = res?.payload?.businessLocation?.coordinates[0];
        let businesslati = res?.payload?.businessLocation?.coordinates[1];

        try {
          let locationAddress =
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${businesslati},
                   ${businesslong}&key=${Constant.GOOGLEMAPAPI.APIKEY}`);

          setBusinessLocation(
            locationAddress?.data?.results[0]?.formatted_address
          );
        } catch (error) {
          console.log("error in google map API ==>", error);
        }
        setBusinessAddress(business);
        setCustomValue(businessName?.value.toString());
        setCategoryDisabled(true);
      } else {
        setCustomValue("");
        setCategoryDisabled(false);
      }

      let count = res?.payload?.eventDetails?.description?.length;
      setcharacterCount(count);

      let start = res?.payload?.eventDetails?.eventStartDate;
      let startData = moment(start).format("DD MMM YYYY");
      setStartDate(new Date(startData));

      let end = res?.payload?.eventDetails?.eventEndDate;
      let endData = end ? moment(end).format("DD MMM YYYY") : "";
      setEndDate(end ? new Date(endData) : "");

      let sTime = res?.payload?.eventDetails?.eventStartTime;
      let startT = new Date(`01/01/1970 ${sTime}`);
      setStartTime(startT);

      let eTime = res?.payload?.eventDetails?.eventEndTime;
      let endT = eTime ? new Date(`01/01/1970 ${eTime}`) : "";
      setEndTime(endT);

      if (res?.payload?.eventDetails?.categoryId) {
        let data = {
          color: res?.payload?.categoryColor,
          id: res?.payload?.eventDetails?.categoryId,
          categoryName: res?.payload?.categoryName,
        };
        setSelectedItem(data);
      } else {
        let service = serviceName?.find((cat) => cat.value === 'Uncategorised')
        setSelectedItem(service)
      }
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Renders a custom date input button component.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the date input.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered custom date input button component.
   */
  const StartDate = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-date-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">e.g.15 Sep 2021</span>
        )}
      </button>
    );
  };

  /**
   * Renders a start time component.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the start time.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered start time component.
   */
  const StartTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? value : <span className="custom-date-placeholder">09:00</span>}
      </button>
    );
  };

  /**
   * Renders a custom end date input button component.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the end date.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered custom end date input button component.
   */
  const EndDate = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-date-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">e.g.17 Sep 2021</span>
        )}
      </button>
    );
  };

  /**
   * Renders a button component for selecting an end time.
   * @param {Object} props - The component props.
   * @param {string} props.value - The selected end time value.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered button component.
   */
  const EndTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? value : <span className="custom-date-placeholder">17:00</span>}
      </button>
    );
  };

  const onChangeStartDate = (dates) => {
    setStartDate(dates);
  };

  const onChangeStartTime = (times) => {
    setStartTime(times);
  };

  const onChangeEndDate = (dates) => {
    setEndDate(dates);
  };

  const onChangeEndTime = (times) => {
    setEndTime(times);
  };

  /**
   * An object representing the form fields and their validation rules for the event information form.
   * @property {object} eventName - The event name field.
   * @property {string} eventName.name - The name of the event name field.
   * @property {object} eventName.validate - The validation rules for the event name field.
   * @property {object} eventName.validate.required - The required validation rule for the event name field.
   * @property {boolean} eventName.validate.required.value - Whether the event name field is required.
   * @property {string} eventName.validate.required.message - The error message to display if the event name field is required.
   * @property {object} eventName.validate.pattern - The pattern validation rule for the event name field.
   *
   */
  const eventInformationForm = {
    eventName: {
      name: "eventName",
      validate: {
        required: {
          value: true,
          message: t("EVENT_NAME_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: t("ONLY_ALPHABETS_NUMBER_ACCENTS"),
        },
      },
    },
    searchServiceName: {
      name: "searchServiceName",
      validate: {
        required: {
          value: false,
        },
      },
    },
    categroy: {
      name: "categroy",
      validate: {
        required: {
          value: true,
          message: t("required"),
        },
      },
    },
    town: {
      name: "towns",
      validate: {
        required: {
          value: true,
          message: t("TOWN_REQUIRED"),
        },
      },
    },
    descripton: {
      name: "descripton",
      validate: {
        required: {
          value: true,
          message: t("DESCRIPTION_REQUIRED"),
        },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: !onlineStatus,
          message: t("ADDRESS_REQUIRED"),
        },
      },
    },
    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: false,
          message: t("required"),
        },
      },
    },
    Livelink: {
      name: "Livelink",
      validate: {
        required: {
          value: true,
          message: t("LINKEDIN_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    online: {
      name: "online",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };

  /**
   * Represents a business timing form object with fields for start date, start time,
   * end date, and end time.
   * @type {Object}
   * @property {Object} startdate - The start date field.
   * @property {string} startdate.name - The name of the start date field.
   * @property {Object} startdate.validate - The validation rules for the start date field.
   * @property {boolean} startdate.validate.required - Indicates if the start date field is required.
   * @property {Object} starttime - The start time field.
   * @property {string} starttime.name - The name of the start time field.
   * @property {Object} starttime.validate - The validation rules for the start time field.
   */
  const businessTimingForm = {
    startdate: {
      name: "startdate",
      validate: {
        required: {
          value: false,
        },
      },
    },
    starttime: {
      name: "starttime",
      validate: {
        required: {
          value: false,
        },
      },
    },
    enddate: {
      name: "enddate",
      validate: {
        required: {
          value: false,
        },
      },
    },
    endtime: {
      name: "endtime",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };

  /**
   * Handles the submission of the form for editing business information.
   * @param {Event} e - The event object representing the form submission.
   * @returns None
   */
  const onFormEventInfoEditSubmit = async (e) => {
    let params = {
      name: e.eventName,
      description: e.descripton,
      businessId: businessId,
      isBusinessRemoved: false,
      categoryId: e.categroy?.id,
      onlineStatus: e.online,
      towns: e.towns?.map(town => town.value),
    };

    if (categoryDisabled) {
      params.isBusinessRemoved = false
    } else {
      params.isBusinessRemoved = true
    }
    if (e.online) params.liveLink = e.Livelink
    if (e.address) params.address = e.address
    if (e?.portLocation?.location?.long || longitude) {
      params.longitude = e.portLocation ? e?.portLocation?.location?.long : longitude
    }
    if (e?.portLocation?.location?.lat || latitude) {
      params.latitude = e.portLocation ? e?.portLocation?.location?.lat : latitude
    }
    // console.log("event edit params ==>", params);
    try {
      setBusy(true);
      const res = await dispatch(updateEventDetails(props.eventID, params))
      getEventData(props.eventID);
      setBusy(false);
      setEventInfoEdit(false);
      props.onRefreshEventList();
      if (e.categroy?.id !== eventData.eventDetails.categoryId) props.onRefreshCustomLeftPart();
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Handles the submission of a form for business timing.
   * @param {Event} e - The event object representing the form submission.
   * @returns None
   */
  const onFormBusinessTimingSubmit = async (e) => {
    //dates
    let startD = e?.startdate;
    let formateStartDate = moment(startD).format("YYYY-MM-DD");
    let endD = e?.enddate;
    let formateEndDate = endD && moment(endD).format("YYYY-MM-DD");

    //times
    let startT = e?.starttime;
    let formatedStartTime = moment(startT).format("HH:mm");
    let endT = e?.endtime;
    let formatedEndTime = endT && moment(endT).format("HH:mm");

    let params = {};
    if (endT === "" || endD === "") {
      params = {
        eventStartDate: formateStartDate,
        // eventEndDate: formateEndDate,
        eventStartTime: formatedStartTime,
        // eventEndTime: formatedEndTime,
      };
    } else {
      params = {
        eventStartDate: formateStartDate,
        eventEndDate: formateEndDate,
        eventStartTime: formatedStartTime,
        eventEndTime: formatedEndTime,
      };
    }
    try {
      setBusy(true);
      const res = await dispatch(updateEventDetails(props.eventID, params))
      getEventData(props.eventID);
      setBusy(false);
      setEventTimingEdit(false);
      props.onRefreshEventList();
      dispatch(
        showToast({
          message: res?.message,
          type: "success",
        })
      );
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  return (
    <div className="EventDetails">
      {!eventInfoEdit ? (
        <div className="first_row">
          <div className="contain_row">
            <div className="left_contain">
              <div className="TagBothWrapper">
                <span className="TagWrapper">
                  <div className="list_name">{eventData?.eventDetails?.name}</div>
                  {eventData?.eventDetails?.onlineStatus && <span className="tagWrapper sucess-bg">{t("ONLINE")}</span>}
                </span>
                <div className="list_discription">
                  {eventData?.eventDetails?.description}
                </div>
              </div>

              <div className="townWrapper">
                <div className="list_name">{t("Town")}</div>
                <ul>
                  {eventData?.eventDetails?.towns?.length > 0
                    ? eventData?.eventDetails?.towns?.map((item, key) => <li key={key}>{item}</li>)
                    : <li>No Towns Selected</li>}
                </ul>
              </div>
              {
                eventData?.businessName && (
                  <div className="AssociatedservicesWrapper">
                    <div className="list_name">{t("Associated_services")}</div>
                    <div className="eventData_name">{eventData?.businessName}</div>
                    <div className="list_discription">
                      {eventData?.businessDescription}
                    </div>
                  </div>
                )
              }
              <div className="location-part">
                <div className="list_name">{t("LOCATION")}</div>
                <div className="address_text">
                  <span className="icon-pin-outline"></span>
                  {eventData?.eventDetails?.address ? eventData?.eventDetails?.address : t("ONLINE")}
                </div>
                {(eventData?.eventDetails?.location) &&
                  <div className="map_view">
                    <iframe
                      title={t("map")}
                      className="map"
                      src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                        }
                        &q=${get(
                          portLocation,
                          "location.latitude",
                          latitude ? latitude : 1
                        )},
                        ${get(
                          portLocation,
                          "location.longitude",
                          longitude ? longitude : 1
                        )}`}
                      width="100%"
                      height="330px"
                      frameBorder="0"
                      allowFullScreen={false}
                      aria-hidden={false}
                      tabIndex={0}
                    />
                  </div>
                }
              </div>
            </div>
            {props.expiredStatus !== true && (
              <button
                onClick={() => {
                  setEventInfoEdit(true);
                }}
                className="icon-pencil"
              ></button>
            )}
          </div>
        </div>
      ) : (
        <div className="edit_row">
          <HookForm
            defaultForm={{}}
            init={(form) => setLoginForm(form)}
            onSubmit={(e) => onFormEventInfoEditSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="first_row">
                    <div className="title_button_row">
                      <div className="field_name">{t("Event_information")}</div>
                      <div className="custombtnfield">
                        <CustomButton
                          type="submit"
                          title={t("UPDATE")}
                          disabled={!formMethod?.formState.isValid || (!onlineStatus && (!currentAddress))}
                          loading={busy}
                        />
                        <CustomButton
                          onClick={() => setEventInfoEdit(false)}
                          type="button"
                          className="outline_btn"
                          title={t("CANCEL")}
                        />
                      </div>
                    </div>
                    <div className="padding_inner">
                      <TextField
                        formMethod={formMethod}
                        rules={eventInformationForm.eventName.validate}
                        name={eventInformationForm.eventName.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        defaultValue={eventData?.eventDetails?.name}
                        autoComplete="on"
                        placeholder={t("Service_name")}
                        textFieldClassName="fullTextBox"
                        maxLength={100}
                      />
                      <div className="discription_txt">
                        <TextField
                          formMethod={formMethod}
                          rules={eventInformationForm.descripton.validate}
                          name={eventInformationForm.descripton.name}
                          errors={formMethod?.formState.errors}
                          onChange={(e) =>
                            setcharacterCount(e.target.value.length)
                          }
                          autoComplete="on"
                          defaultValue={eventData?.eventDetails?.description}
                          placeholder={t("Description")}
                          textarea="textarea"
                          maxLength={550}
                        />
                        <div className="textarea_count">
                          {characterCount}/550
                        </div>
                      </div>
                      <div className="searchMainDropdown">
                        <Controller
                          rules={
                            eventInformationForm.searchServiceName.validate
                          }
                          name={eventInformationForm.searchServiceName.name}
                          control={formMethod?.control}
                          defaultValue={customValue ? customValue : ''}
                          value={customValue ? customValue : ''}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <SelectSearch
                                search={true}
                                options={allBusinessDetails}
                                // filterOptions={fuzzySearch}
                                defaultValue={customValue}
                                value={customValue}
                                placeholder={t("SEARCH_SERVICE_NAME_TO_LINK_EVENT")}
                                onChange={async (evt, item) => {
                                  setBusinessId(item.bId);
                                  setBusinessAddress(item.address);

                                  let longitude = item?.location?.longitude;
                                  let latitude = item?.location?.latitude;

                                  try {
                                    let locationAddress =
                                      await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},
                                                                 ${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`);
                                    setBusinessLocation(
                                      locationAddress?.data?.results[0]
                                        ?.formatted_address
                                    );
                                  } catch (error) { }

                                  setCustomValue(evt === null ? '0' : evt);

                                  if (checked) {
                                    formMethod.setValue(
                                      eventInformationForm.address.name,
                                      item.address
                                    );
                                  }
                                  setCategoryDisabled(true);

                                  formMethod.setValue(
                                    eventInformationForm.searchServiceName
                                      .name,
                                    item
                                  );
                                  let d = allBusinessDetails?.find((value) => {
                                    return value.name === item.name;
                                  });
                                  const data = {
                                    color: d.color,
                                    id: d.categoryID,
                                    categoryName: d.categoryTitle,
                                  };

                                  formMethod.setValue(
                                    eventInformationForm.categroy.name,
                                    data
                                  );
                                  setCategroy(data);
                                  setCategoryId(data.id);
                                  setSelectedItem(data);
                                  onChange(evt, item);
                                }}
                                disabled={false}
                              />
                              {customValue && (
                                <button
                                  type="reset"
                                  className="icon-close"
                                  onClick={() => {
                                    setCustomValue("");
                                    setCategoryDisabled(false);
                                  }}
                                ></button>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="input_row">
                        <div className="colorDropdownWrapper">
                          <span
                            className="cricle_select_view"
                            style={{
                              backgroundColor: categroy
                                ? categroy?.color
                                : selectedItem?.color,
                            }}
                          ></span>
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <CustomDropdown
                                dropDownItems={serviceName}
                                placeholder={t("SELECT_CATEGORY")}
                                selectedValue={selectedItem}
                                disabled={categoryDisabled}
                                defaultValue={selectedItem}
                                onSelect={(evt) => {
                                  onChange(evt);
                                  const data = {
                                    color: evt.color,
                                    id: evt.id,
                                    categoryName: evt.value,
                                  };
                                  setSelectedItem(data);

                                  setCategoryId(evt.id);
                                }}
                              ></CustomDropdown>
                            )}
                            control={formMethod.control}
                            defaultValue={selectedItem}
                            name={eventInformationForm.categroy.name}
                            rules={eventInformationForm.categroy.validate}
                          />
                          <span className="arrow-dropdown icon-sorting"></span>
                        </div>

                      </div>
                      <div className="input_row">
                        <div className="townWrapper">
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <CustomeSelectBox
                                placeholder={
                                  <>
                                    {t("SELECT_TOWN")}
                                    <span style={{ color: 'red' }}>*</span>
                                  </>
                                }
                                defaultValue={eventData?.eventDetails?.towns?.map(town => ({ label: town, value: town }))}
                                options={townList || []}
                                isMulti={true}
                                className={
                                  value?.length > 1 &&
                                  "multipleSelectBox"
                                }
                                onChange={(evt) => {
                                  onChange(evt);
                                }}
                              />
                            )}
                            defaultValue={eventData?.eventDetails?.towns?.map(town => ({ label: town, value: town }))}
                            control={formMethod.control}
                            name={eventInformationForm.town.name}
                            rules={eventInformationForm.town.validate}
                            errors={formMethod?.formState.errors}
                          />
                          <span className="arrow-dropdown icon-sorting"></span>
                        </div>
                      </div>
                      <div className="input_row">
                        <div className="onlineWrapper">
                          <Controller
                            defaultValue={eventData?.eventDetails?.onlineStatus}
                            control={formMethod?.control}
                            name={eventInformationForm.online.name}
                            rules={eventInformationForm.online.validate}
                            render={({ field: { onChange, value } }) => (
                              <div className="OnlineSwitchWrapper">
                                <p>{t("ONLINE")}</p>
                                <CustomToggleButton
                                  id=""
                                  checked={value}
                                  onCheckChange={(e) => { onChange(e); setOnlineStatus(e); }}
                                />
                              </div>
                            )}
                          />
                          {formMethod.getValues("online") &&
                            <TextField
                              formMethod={formMethod}
                              rules={eventInformationForm.Livelink.validate}
                              defaultValue={eventData?.eventDetails?.liveLink}
                              name={eventInformationForm.Livelink.name}
                              errors={formMethod?.formState.errors}
                              // autoFocus={true}
                              type="text"
                              autoComplete="on"
                              placeholder={t("Live_link")}
                              textFieldClassName="fullTextBox"
                              maxLength={250}
                            />
                          }
                        </div>
                      </div>
                      <div className="check_row">
                        <Label className="title_top" title={t("LOCATION")} />
                        {categoryDisabled && (
                          <CheckBox
                            labelTitle={t("Address_same_as_service_address")}
                            id="address_checkbox"
                            checked={checked}
                            onCheckedChange={(checkedStatus) => {
                              setChecked(checkedStatus);
                              checkedStatus
                                ? formMethod.setValue(
                                  eventInformationForm.address.name,
                                  businessAddress
                                )
                                : formMethod.setValue(
                                  eventInformationForm.address.name,
                                  eventAddress
                                );
                            }}
                          />
                        )}
                      </div>
                      <div
                        className={
                          checked ? "address_part disable" : "address_part"
                        }
                      >
                        <TextField
                          formMethod={formMethod}
                          rules={eventInformationForm.address.validate}
                          name={eventInformationForm.address.name}
                          errors={onlineStatus ? {} : formMethod?.formState.errors}
                          defaultValue={eventAddress}
                          disabled={checked}
                          type="text"
                          autoComplete="on"
                          placeholder={t("LOCATION")}
                          textFieldClassName="fullTextBox"
                          value={checked ? businessAddress : eventAddress}
                          maxLength={150}
                          isRequired={onlineStatus ? false : true}
                        />
                      </div>
                      <div
                        className={
                          checked ? "location_part disable" : "location_part"
                        }
                      >
                        <div>
                          <Controller
                            control={formMethod?.control}
                            name={eventInformationForm.portLocation.name}
                            rules={
                              eventInformationForm.portLocation.validate
                            }
                            render={({ field: { onChange } }) => (
                              <GooglePlaceDropDown
                                isRequired={onlineStatus ? false : true}
                                defaultValue={checked ? {
                                  label: businessLocation,
                                  value: businessLocation,
                                } : currentAddress}
                                getLocation={(place) => {
                                  console.log(place, "place")
                                  if (place === null) {
                                    setPortLocation(null)
                                    setLatitude()
                                    setLongitude()
                                    setCurrentAddress()
                                  } else {
                                    setPortLocation({
                                      location: {
                                        latitude: place?.location?.lat || 1,
                                        longitude: place?.location?.long || 1,
                                        address: place.address || "",
                                      }
                                    })
                                    setCurrentAddress({
                                      label: place.address,
                                      value: { ...place }
                                    });
                                  }
                                  onChange(place);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="map_view">
                          <iframe
                            title={t("map")}
                            className="map"
                            src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY}
                            &q=${get(
                              portLocation,
                              "location.latitude",
                              latitude
                                ? latitude
                                : 1
                            )},
                            ${get(
                              portLocation,
                              "location.longitude",
                              longitude
                                ? longitude
                                : 1
                            )}`}
                            width="100%"
                            height="330px"
                            frameBorder="0"
                            allowFullScreen={false}
                            aria-hidden={false}
                            tabIndex={0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}

      {!eventTimingEdit ? (
        <div className="first_row">
          <div className="title_button_row">
            <div className="field_name eventdatetime">{t("Event_date_time")}</div>
            {props.expiredStatus !== true && (
              <button
                onClick={() => setEventTimingEdit(true)}
                className="icon-pencil"
              ></button>
            )}
          </div>
          <div className="day_time_row">
            <div className="day_text">
              <span className="icon-calender"></span>
              {moment(`${moment(startDate).format("DD MMM YYYY")} 
                             ${moment(startTime).format("HH:mm")}`).format(
                "DD MMM YYYY HH:mm"
              )}
            </div>
            {endDate != "" && (
              <div className="day_text">
                <span className="icon-calender"></span>
                {endDate != ""
                  ? moment(`${moment(endDate).format("DD MMM YYYY")} 
                             ${moment(endTime).format("HH:mm")}`).format(
                    "DD MMM YYYY HH:mm"
                  )
                  : "-"}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="edit_row businesstiming">
          <HookForm
            defaultForm={{}}
            init={(form) => setLoginForm(form)}
            onSubmit={(e) => onFormBusinessTimingSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="first_row">
                    <div className="title_button_row">
                      <div className="field_name eventdatetime">
                        Start date/time
                      </div>
                      <div className="custombtnfield">
                        <CustomButton
                          type="submit"
                          title={t("UPDATE")}
                          disabled={!formMethod?.formState.isValid}
                          loading={busy}
                        />
                        <CustomButton
                          onClick={() => setEventTimingEdit(false)}
                          type="button"
                          className="outline_btn"
                          title={t("CANCEL")}
                        />
                      </div>
                    </div>
                    <div className="padding_inner">
                      <div className="date-filter-main">
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Calender
                              selected={startDate}
                              onChange={(e) => {
                                setStartDate(e);
                                onChangeStartDate(e);
                                onChange(e);
                                setEndDate();
                                setStartTime();
                                setEndTime();
                              }}
                              minDate={new Date()}
                              dateFormat="d MMM yyyy"
                              customInput={<StartDate />}
                              isClearable={false}
                              value={startDate}
                            />
                          )}
                          control={formMethod.control}
                          name={businessTimingForm.startdate.name}
                          rules={businessTimingForm.startdate.validate}
                          defaultValue={startDate}
                        />
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <TimePicker
                              selected={startTime}
                              onChange={(e) => {
                                let data = moment(e).format("HH:mm");

                                setStartTime(data);
                                onChangeStartTime(e);
                                onChange(e);
                                setEndTime();
                              }}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              customInput={<StartTime />}
                              showTimeSelect={true}
                              showTimeSelectOnly={true}
                              timeIntervals={15}
                              minTime={
                                today === moment(startDate).format("DD/MM/YYYY")
                                  ? setHours(
                                    setMinutes(
                                      new Date(),
                                      new Date().getMinutes()
                                    ),
                                    new Date().getHours()
                                  )
                                  : setHours(setMinutes(new Date(), 0), 24)
                              }
                              maxTime={setHours(setMinutes(new Date(), 59), 23)}
                              isClearable={false}
                              value={startTime}
                            />
                          )}
                          control={formMethod.control}
                          name={businessTimingForm.starttime.name}
                          rules={businessTimingForm.starttime.validate}
                          defaultValue={startTime}
                        />
                      </div>
                      <div
                        className={
                          startDate === null || startTime === null
                            ? "date-filter-main disabled"
                            : "date-filter-main"
                        }
                      >
                        <div className="datetime_title">End date/time</div>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Calender
                              selected={endDate}
                              onChange={(e) => {
                                onChangeEndDate(e);
                                onChange(e);
                                setEndTime();
                              }}
                              // minDate={new Date(moment(new Date(startDate)).add(1, 'days').format())}
                              minDate={startDate}
                              dateFormat="d MMM yyyy"
                              customInput={<EndDate />}
                              isClearable={false}
                              value={endDate}
                            />
                          )}
                          control={formMethod.control}
                          name={businessTimingForm.enddate.name}
                          rules={businessTimingForm.enddate.validate}
                          defaultValue={endDate}
                        />
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <TimePicker
                              selected={endTime}
                              onChange={(e) => {
                                let data = moment(e).format("HH:mm");

                                setEndTime(data);
                                onChangeEndTime(e);
                                onChange(e);
                              }}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              customInput={<EndTime />}
                              showTimeSelect={true}
                              showTimeSelectOnly={true}
                              minTime={
                                moment(startDate).format("Do YYYY") ===
                                  moment(endDate).format("Do YYYY")
                                  ? setHours(
                                    setMinutes(
                                      startTime,
                                      moment(startTime).format(`mm`)
                                    ),
                                    moment(startTime).format("HH")
                                  )
                                  : setHours(setMinutes(new Date(), 0), 24)
                              }
                              maxTime={setHours(setMinutes(new Date(), 59), 23)}
                              timeIntervals={15}
                              isClearable={false}
                              value={endTime}
                            />
                          )}
                          control={formMethod.control}
                          name={businessTimingForm.endtime.name}
                          rules={businessTimingForm.endtime.validate}
                          defaultValue={endTime}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
