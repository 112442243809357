import { get } from "lodash";
import { combineReducers } from "redux";
import { createReducer } from "../../utils";
import * as types from './types';

const townReducer = createReducer([])({
    [types.GET_TOWN_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

export default combineReducers({
    townList: townReducer,
})