/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useWindowDimension from "../../../hooks/useWindowDimension";

import CustomLeftPart from "../../../components/customLeftPart/customLeftPart";
import CustomAddBusiness from "../../../components/customAddBusiness/customAddBusiness";
import CustomBusinessListing from "../../../components/customBusinessListing/customBusinessListing";
import "./dashboard.scss";
import { useTranslation } from "react-i18next";
import { getTownList } from "../../../state/ducks/townDetails/action";
import { Helmet } from 'react-helmet';

/**
 * A functional component that represents a dashboard page.
 * @param {object} props - The props passed to the component.
 * @returns The rendered dashboard page.
 */
function Dashboard() {
  const CLOUDINARY_URL =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER;

  const { t } = useTranslation();
  const dimensions = useWindowDimension();
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(true);
  let [leftPart, setShowLeftPart] = useState(false);
  const [headerHeight, SetHeaderHeight] = useState("");
  let [refreshCategory, setRefreshCategory] = useState(false);
  const [showBusinessDetails, setShowBusinessDetails] = useState(null);
  let [showBusinessDetailsModal, setShowBusinessDetailsModal] = useState(false);

  /**
   * useEffect hook that sets the height of the header element.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("header")[0].offsetHeight);
    dispatch(getTownList())
  }, []);

  /**
   * Renders a page with a left and right part. The left part contains a menu button and a custom left part component.
   * The right part contains either a custom add business component or a custom business listing component, depending on the state.
   * @returns The rendered page with the left and right parts.
   */
  return (<>
    <Helmet>
      <title>Angus Recovery Road Map - Supporting Individuals & Families</title>
    </Helmet>

    <div
      className={showBusinessDetailsModal ? "pageMain rightSidebarshow" : "pageMain"}
      style={{
        height: dimensions.height - headerHeight,
        marginTop: headerHeight,
      }}
    >
      <div className="dashboardpage">
        {leftPart && (
          <div
            className="overLayMenu"
            onClick={() => setShowLeftPart(false)}
          ></div>
        )}

        <button
          className="category_btn"
          onClick={() => {
            setShowLeftPart(true);
          }}
        >
          <span className="icon-menu"></span>{t("CATEGORY")}
        </button>
        <div className={leftPart ? "left_part leftPartshow" : "left_part "}>
          <CustomLeftPart
            refreshCategory={refreshCategory}
            setRefreshCategory={(value) => setRefreshCategory(value)}
            onClickClose={() => setShowLeftPart(false)}
            onViewClick={(item) => {
              setShowBusinessDetails(item);
              setIsModal(true);
            }}
            addUncategorised={false}
            setShowBusinessDetailsModal={(i) => setShowBusinessDetailsModal(i)}
          />
        </div>
        <div className="right_part">
          <div style={{ height: dimensions.height - headerHeight }}>
            {showBusinessDetails &&
              showBusinessDetails.businessCount === 0 &&
              isModal ? (
              <CustomAddBusiness
                item={showBusinessDetails}
                callback={() => {
                  // console.log('callback called CustomAddBusiness')
                  setRefreshCategory(true);
                }}
                onClickCancel={() => setIsModal(false)}
              />
            ) : (
              showBusinessDetails && (
                <CustomBusinessListing
                  mainItem={showBusinessDetails}
                  color={showBusinessDetails.color}
                  callback={() => {
                    setRefreshCategory(true);
                  }}
                  id={showBusinessDetails._id}
                  status={showBusinessDetails.status}
                  onRefresh={() => {
                    setIsModal(true);
                  }}
                  showBusinessDetailsModal={showBusinessDetailsModal}
                  setShowBusinessDetailsModal={(i) => {
                    setShowBusinessDetailsModal(i);
                  }
                  }
                />
              )
            )}
            {!showBusinessDetails && (
              <div
                className="no_categroy"
                style={{ height: dimensions.height - headerHeight }}
              >
                <div className="no_categroy_logo">
                  <img
                    src={CLOUDINARY_URL + "no-categroy.png"}
                    alt="no-categroy"
                  />
                </div>
                <div className="no_categroy_txt">
                  {t("THERE_IS_NO_CATEGORY_SELECTED_YET_PLEASE_SELECT_ONE_CATEGORY")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default Dashboard;
