import React from "react";
import './timePicker.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimePicker = (props) => {
    let { isClearable, showTimeSelect, showTimeSelectOnly, maxTime, minTime, timeIntervals, disabled, value, timeFormat, onClickOutside } = props
    return (
        <div className="calenderContainer">
            <DatePicker
                selected={props.selected}
                dateFormat={props.dateFormat}
                placeholderText={props.placeholderText}
                onChange={(e) => props.onChange(e)}
                readOnly={props.readOnly}
                customInput={props.customInput}
                isClearable={isClearable}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                maxTime={maxTime}
                minTime={minTime}
                disabled={disabled}
                value={value}
                timeFormat={timeFormat}
                onClickOutside={onClickOutside}
            />
        </div>
    )
}

TimePicker.defaultProps = {}

export default TimePicker;