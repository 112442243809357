import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
import JWTService from "./../../../services/jwtService";
import StorageService from "./../../../services/localStorageService"

const authReducer = createReducer(false)({
    [types.LOGOUT]: () => false,
    [types.AUTHENTICATED]: () => true,
    [types.AUTHORIZATION_FAILED]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('token');
        return false
    }
});

const initializeSessionReducer = createReducer(null)({
    [types.INITIALIZE]: (state, action) => {
        const user = action.payload.adminData;
        const tokens = action.payload.tokens;
        StorageService.setItem('isAuthenticated', true);
        JWTService.saveToken(tokens);
        return { user, tokens, isAuthenticated: true }
    },
    [types.DESTROY]: () => {
        StorageService.setItem('isAuthenticated', false);
        StorageService.removeItem('userDetails')
        JWTService.destroyToken();
        return { user: null, tokens: null, isAuthenticated: false };
    }
});

export default combineReducers({
    isAuthenticated: authReducer,
    session: initializeSessionReducer,
});
