import { Modal } from "react-bootstrap";
import React from "react";
import "./privacymodalbox.scss";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const PrivacyModalBox = (props) => {
  const {t} = useTranslation()
  return (
    <>
      {/**
       * Renders a modal component with a privacy policy.
       * @param {boolean} showModal - Determines whether the modal is shown or hidden.
       * @param {function} modalClose - Callback function to close the modal.
       * @param {string} policyData - The privacy policy data to be displayed in the modal body.
       * @returns A modal component displaying the privacy policy.
       */}
      <Modal
        centered
        className="privacyModal"
        show={props.showModal}
        onHide={() => {
          props.modalClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="privacy-head">
            {t("PRIVACY_POLICY")}<span>{t("LAST_UPDATED_JANUARY_10_2021")}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{parse(props?.policyData)}</Modal.Body>
      </Modal>
    </>
  );
};
export default PrivacyModalBox;
