import React from 'react'; // Import lodash debounce
import './searchBox.scss';

const SearchBox = ({ placeholder, onSearch, onClear, value, children }) => {

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    onSearch(searchValue)
  };

  return (
    <div className="searchboxMain">
      <input
        type="text"
        name="focus"
        className="searchInput"
        value={value}
        placeholder={placeholder}
        onChange={handleSearchChange}
      />
      {value.length > 0 && (
        <button className="close-icon icon-close" type="reset" onClick={() => onClear && onClear()}></button>
      )}
      {value.length === 0 && <button className="close-icon icon-search" type="button"></button>}
      {children}
    </div>
  );
};

export default SearchBox;
