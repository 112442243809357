import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../layout/header/header";
import Map from "../../components/map/map";
import AxiosInstance from "../../services/axios";
import Filter from "../../components/filter/filter";
import useGeoLocation from "../../hooks/useGeoLocation";
import Searchbar from "../../components/searchbar/searchbar";
import ViewDetails from "../../components/viewDetails/viewDetails";
import "./homeMapView.scss";
import Constant from "../../util/constant";
import { showToast } from "../../state/ducks/utils/actions";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import Loading from "../../components/loading/loading";
import ReactLoading from "react-loading";
import domToPdf from 'dom-to-pdf';
import { Helmet } from 'react-helmet';

const HomeMapView = (props) => {
  /**
   * Retrieves the translation function from the useTranslation hook and assigns it to the variable t.
   * Retrieves the dispatch function from the useDispatch hook and assigns it to the variable dispatch.
   * @returns None
   */
  const { t } = useTranslation();
  const dispatch = useDispatch()

  /**
   * Retrieves the user's geolocation using the browser's Geolocation API.
   * @returns An object containing the user's current geolocation coordinates.
   */
  const location = useGeoLocation();
  // console.log("location home", location);

  const [toggleView, setToggleView] = useState(true);
  const [toggleFilter, setToggleFilter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [viewPage, setViewPage] = useState({});
  const [searchText, setSearchText] = useState("");
  const [apiData, setApiData] = useState([])
  const [mapView, setMapView] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sliceData, setSliceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([]);
  const [service, setService] = useState(null);
  const [event, setEvent] = useState(null);
  const [checkToggle, setCheckToggle] = useState(false);
  const [town, setTown] = useState('All')
  const [isDownloading, setIsDownloading] = useState(false)

  /**
   * Retrieves the filter configuration from the local storage and assigns them to the respective variables.
   * @returns None
   */
  let filterWrap = JSON.parse(localStorage.getItem("homeFilter"));
  let filterToggleWrap =
    JSON.parse(localStorage.getItem("toggleFilter")) || false;
  let serviceFilter = localStorage.getItem("serviceFilter");
  let eventFilter = localStorage.getItem("eventFilter");
  let selectedTown = localStorage.getItem("selectedTown") || 'All';

  /**
   * useEffect hook that sets the checkToggle state to the value of filterToggleWrap
   * when the component mounts.
   * @returns None
   */
  useEffect(() => {
    setCheckToggle(filterToggleWrap);
    setService(serviceFilter === 'true' ? true : serviceFilter === 'false' ? false : true)
    setEvent(eventFilter === 'true' ? true : eventFilter === 'false' ? false : true)
    setTown(selectedTown)
  }, []);

  /**
   * Runs the `getFilterCategory` function whenever the `toggleView` state changes.
   * @returns None
   */
  useEffect(() => {
    getFilterCategory();
  }, []);

  /**
   * Executes the getMapView function whenever there is a change in the categories, service, event, or checkToggle variables.
   * @returns None
   */
  useEffect(() => {
    if (service !== null && event !== null && categories.length > 0) {
      setSearchText("");
      getMapView(categories, service, event, checkToggle, town);
    }
  }, [categories, service, event, checkToggle, town, location]);

  useEffect(() => {
    if (event === true) {
      const uncategorisedExits = categories.some((cat) => cat.categoryName === 'Uncategorised')
      if (!uncategorisedExits) {
        let obj = {
          categoryName: "Uncategorised",
          color: "#000000",
          status: Constant.STATUS.ENABLE,
          ischecked: true,
        };
        setCategories([obj, ...categories])

      }
    } else {
      let updatedData = categories?.filter((item) => {
        return !(item.categoryName === "Uncategorised");
      });
      setCategories(updatedData)
    }
  }, [event, categories.length])

  useEffect(() => {
    searchFilterFunction(searchText);
  }, [searchText])


  /**
   * Retrieves the filter category data asynchronously from the server.
   * @returns None
   * @throws {Error} if there is an error retrieving the data from the server.
   */
  const getFilterCategory = async () => {
    try {
      await AxiosInstance.get("user/getCategory").then((response) => {
        let temp = response?.payload;
        const uncategorisedExits = categories.some((cat) => cat.categoryName === 'Uncategorised')
        let newArr1 = [...temp]
        if (!uncategorisedExits) {
          let obj = {
            categoryName: "Uncategorised",
            color: "#000000",
            status: Constant.STATUS.ENABLE,
            ischecked: true,
          };
          newArr1 = [obj, ...temp]
        }

        let tempArray = newArr1?.map((item, index) => {
          let filterData = filterWrap?.some((value) => value._id === item._id);
          if (filterData) {
            item["status"] = Constant.STATUS.DISABLE;
          }
          return item;
        });
        setCategories(tempArray);
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Retrieves a map view based on the provided parameters and updates the state variables accordingly.
   * @param {any} category - The category of the map view.
   * @param {boolean} item - Indicates whether to include service details in the map view.
   * @param {boolean} eventDetail - Indicates whether to include event details in the map view.
   * @param {boolean} toggleDetail - Indicates whether to include toggle details in the map view.
   * @returns None
   */
  const getMapView = async (
    category,
    item,
    eventDetail,
    toggleDetail,
    town
  ) => {
    let temp = category
      ?.filter((item) => {
        if (item.status === Constant.STATUS.ENABLE) {
          return item;
        }
      })
      .map((item) => item._id);

    try {
      let params = {
        category: category ? temp : [],
        isOnlyBusiness: item,
        isOnlyEvents: eventDetail,
        isOpen: toggleDetail,
        isUncategorised: eventDetail === true ? true : false,
        town: town
      };
      if (!location.loaded !== true) {

        location?.coordinates?.lng &&
          (params["longitude"] = location?.coordinates?.lng);
        location?.coordinates?.lat &&
          (params["latitude"] = location?.coordinates?.lat);

        // location?.coordinates?.lng &&
        //   (params["longitude"] = -2.5915);
        // location?.coordinates?.lat &&
        //   (params["latitude"] = 56.5591);
      }
      setIsLoading(true)
      const response = await AxiosInstance.post("user/getServices", params)
      let businessList = response?.payload?.businessList;
      let eventList = response?.payload?.eventList;

      let merge = [...businessList, ...eventList];

      const uncategorisedStatus = categories.some(cat => cat.categoryName === 'Uncategorised' && cat.status === 'Disable')
      if (uncategorisedStatus) {
        merge = merge.filter(evt => evt.categoryId !== null)
      }

      let mergeCount = merge.length

      setTotalCount(mergeCount);
      let tempArray = [];
      const chunkSize = 50;
      for (let i = 0; i < merge.length; i += chunkSize) {
        const chunk = merge.slice(i, i + chunkSize);
        tempArray.push(chunk);
      }

      const mapData = merge.filter((item) => item.location ? true : false)

      setApiData(merge)
      setMapView(mapData);
      setFilteredDataSource(merge);
      setSliceData(tempArray);
      setIsLoading(false)
      let isUpdated = false;
      let bookmarkList = JSON.parse(localStorage.getItem("bookmark_data"));
      merge.map((item) => {
        bookmarkList?.map((bookmarkItem, index) => {
          if (bookmarkItem._id == item._id) {
            isUpdated = true;
            bookmarkList[index] = item;
          }
        });
      });
      if (isUpdated) {
        localStorage.setItem("bookmark_data", JSON.stringify(bookmarkList));
      }
    } catch (error) {
      setIsLoading(false)
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Filters the data in the `mapView` array based on the provided `text`.
   * If `text` is not empty, it filters the data by comparing the `name` property of each item
   * in the `mapView` array with the uppercase version of the `text`. It returns a new array
   * containing only the items that have a `name` property that contains the `text`.
   * If `text` is empty, it sets the filtered data source to the original `mapView` array.
   * @param {string} text - The text to filter the data by.
   * @returns None
   */
  const searchFilterFunction = (text) => {
    if (!_.isEmpty(text)) {
      const newData = apiData.filter(function (item) {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text?.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredDataSource(newData);
      setMapView(newData)
      setSearchText(text);
    } else {
      setSearchText(text);
      setFilteredDataSource(apiData);
      setMapView(apiData)
    }
  };

  /**
   * Downloads multiple PDF files based on the sliceData array.
   * @returns None
   */
  const downloadPDF = async () => {
    console.log('Start downloading PDFs');
    setIsDownloading(true)
    for (let i = 0; i < sliceData.length; i++) {
      const input = document.getElementById(`services${i}`);
      const extraHeight = document.getElementById(`extraHeight`);

      const originalDisplay = input.style.display;
      input.style.display = 'block';
      extraHeight.style.display = 'block';

      const options = {
        filename: `all_services_events_${i}.pdf`,
        compression: 'MEDIUM'
      };

      await domToPdf(input, options);
      console.log("download completed - ", i);
      input.style.display = originalDisplay;
      extraHeight.style.display = originalDisplay;
    }
    setIsDownloading(false)
  };

  let localstorageBooks =
    JSON.parse(localStorage.getItem("bookmark_data")) || [];

  /**
   * Renders a React component that displays a filter and a list or map view of data.
   * @returns {React.Fragment} - The rendered component.
   */
  return (
    <React.Fragment>
      <Helmet>
        <title>Angus Recovery Road Map - Supporting Individuals & Families</title>
      </Helmet>
      <div className={toggleFilter ? "filterWrap" : "filterWrap active"}>
        <Filter
          onclick={() => setToggleFilter(!toggleFilter)}
          iconChange={toggleFilter ? "icon-filter" : "icon-close"}
          categoriesData={categories}
          count={totalCount}
          onCheckedServices={(value) => {
            setService(value);
          }}
          onCheckedEvent={(value) => {
            setEvent(value);
          }}
          onCheckedToggle={(value) => {
            setCheckToggle(value);
          }}
          onUpdatedCategoryData={(value) => {
            setCategories([...value]);
          }}
          onUpdatedTown={(value) => {
            setTown(value);
          }}
          selectedTown={town}
          filterToggleWrap={checkToggle}
          filterServiceWrap={service}
          filterEventWrap={event}
        />
      </div>
      <div className={toggleFilter ? "mainWrap" : "mainWrap move"}>
        <Header />

        {/* {toggleView === false ? ( */}
        <Searchbar
          value={searchText}
          onSearch={(e) => {
            setSearchText(e.target.value);
          }}
          onClear={() => {
            setSearchText("");
          }}
        />
        {/* // ) : null} */}
        <div className="viewWrap">
          <Button
            className={toggleView ? "mapview-btn active" : "mapview-btn "}
            onClick={() => {
              setToggleView(true);
              setSearchText("");
            }}
          >
            {t("MAP_VIEW")}
          </Button>
          <Button
            className={!toggleView ? "listview-btn active" : "listview-btn "}
            onClick={() => {
              setToggleView(false);
              setSearchText("");
            }}
          >
            {t("LIST_VIEW")}
          </Button>
        </div>
        <div className={!toggleView ? "mapWrap hide" : "mapWrap"}>
          <Map mapData={mapView} town={town} />
        </div>

        <ViewDetails
          mapData={viewPage}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
        {isLoading ? <Loading show={isLoading} /> :
          <div className={toggleView ? "listVieWrap hide" : "listVieWrap"}>
            {filteredDataSource?.length === 0 ? (
              <div className="no_data">
                <i className="icon-info_circle_outline"></i>
                <p>{t("OOPS_NO_SERVICE_CENTRES_OR_EVENTS_FOUND")}</p>
              </div>
            ) : filteredDataSource?.map((item, index) => {
              item["isbookmarked"] = localstorageBooks?.some(
                (val) => val?._id === item?._id
              );
              let displayTime;
              let startDate;
              let endDate;
              if (item?.type === "event") {
                startDate = moment(`${moment(item?.eventStartDate)
                  .utc()
                  .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");

                if (item?.eventEndDate) {
                  endDate = moment(`${moment(item?.eventEndDate)
                    .utc()
                    .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                } else {
                  endDate = "--:--";
                }
              } else {
                let currentDay = moment().format("ddd");
                let data = item?.availableDayTime?.filter((businessItem) => {
                  if (businessItem?.day === currentDay) {
                    return businessItem;
                  } else if (businessItem?.day !== currentDay) {
                  }
                });
                displayTime = data[0]?.isDayActive
                  ? data[0].is24Hours
                    ? "24 Hours available"
                    : `${data[0].startTime} to ${data[0].endTime}`
                  : "Closed";
              }

              return (
                <>
                  <div
                    key={index}
                    onClick={() => {
                      setShowModal(true);
                      setViewPage(item);
                    }}
                    style={{ color: item.color }}
                    className={"eventBox HomeEventWrapper"}
                  >
                    {item?.type === "event" ? (
                      <span className="icon icon-activity-pin"></span>
                    ) : (
                      <span className="icon icon-service-pin"></span>
                    )}
                    <div className="cardContent">
                      <div className="eventTop ">
                        <h2>
                          {item?.name}
                          {/* <span className="distance">
                            {parseFloat(item?.distance).toFixed(2)} miles
                          </span> */}
                          {(item?.distance || item.distance == 0) && (
                            <span className="distance">
                              {parseFloat(item?.distance).toFixed(2)} miles
                            </span>
                          )}
                        </h2>
                        <span className="category" style={{ color: item.color }}>{item?.categoryName}</span>
                        {item?.towns?.length > 0 &&
                          <div className="TownUl">
                            <ul>
                              {item?.towns?.map((town, id) => <li key={id}>{town}</li>)}
                            </ul>
                          </div>
                        }
                      </div>
                      {item?.isActive !== "Open" && item?.isActive !== "open" ? (
                        <div className="timing">
                          <span>{t("TIMING")} - </span>{" "}
                          {item?.isActive === "Close" ? "Closed" : item?.isActive}
                        </div>
                      ) : (
                        <div className="timing">
                          <span>{item.isActive}</span>
                        </div>
                      )}
                      <div className="date">
                        {item?.type === "event"
                          ? `${startDate} - ${endDate}`
                          : displayTime}
                      </div>
                      <div className="btn-wrap">
                        {item.location && <a
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="btn btn-primary direction"
                          href={`https://www.google.com/maps/?q=${item.location.coordinates[1]},${item.location.coordinates[0]}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon icon-coolicon"></span>{t("DIRECTIONS")}
                        </a>
                        }
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            // setBookmarkBtn(true);
                            setToggleView(false);
                            const getbookMarkBookArr = JSON.parse(
                              localStorage.getItem("bookmark_data")
                            );
                            const isBookMarkAdded = getbookMarkBookArr?.some(
                              (itemData) => item?._id === itemData?._id
                            );
                            let localList = [...filteredDataSource];

                            if (isBookMarkAdded) {
                              if (
                                getbookMarkBookArr &&
                                getbookMarkBookArr.length > 0
                              ) {
                                const newData = getbookMarkBookArr.filter(
                                  (val) => item._id !== val._id
                                );
                                localList[index]["isbookmarked"] = false;
                                item["isbookmarked"] = false;
                                localStorage.setItem(
                                  "bookmark_data",
                                  JSON.stringify(newData)
                                );
                              }
                            } else {
                              let bookMarkArr = JSON.parse(
                                localStorage.getItem("bookmark_data")
                              );
                              if (bookMarkArr && bookMarkArr.length > 0) {
                                bookMarkArr.push(item);
                                localStorage.setItem(
                                  "bookmark_data",
                                  JSON.stringify(bookMarkArr)
                                );
                              } else {
                                localList[index]["isbookmarked"] = true;
                                item["isbookmarked"] = true;
                                localStorage.setItem(
                                  "bookmark_data",
                                  JSON.stringify([item])
                                );
                              }
                            }
                            setFilteredDataSource([...localList]);
                          }}
                          className={
                            item.isbookmarked === true ? "isbookmarked bookmark" : "bookmark"
                          }
                        >
                          <span className="icon icon-bookmark-outline"></span>
                          {t("BOOKMARK")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <Button
              className="download"
              onClick={() => {
                downloadPDF();
              }}
            >
              {isDownloading ? <ReactLoading type="balls" color={'#fff'} height={25} width={25} /> : <span className="icon icon-download"></span>}
            </Button>
          </div>
        }

        <ViewDetails />
        <div id="extraHeight"></div>
        {sliceData?.map((data, index) => {
          const firstEventIndex = data.findIndex(i => i.type === 'event')
          let servicesData = []
          let eventsData = []

          if (firstEventIndex !== -1) {
            servicesData = data.slice(0, firstEventIndex)
            eventsData = data.slice(firstEventIndex)
          } else {
            servicesData = data
          }

          return (
            <div key={index} id={`services${index}`} className="showData">
              {servicesData.length > 0 && (
                <>
                  <div className="titleText">
                    <div className="service">
                      <span>{t("SERVICE_CENTERS")}</span>
                    </div>
                  </div>
                  <div className="showTable">
                    {servicesData?.map((item, j) => (
                      <div key={j} id={`data-${index}-${j}`} className="servicesEventsDetails">
                        <div className="categoryList">
                          <div className="category">
                            {item?.categoryName}
                            {item?.onlineStatus && <span>{t("ONLINE")}</span>}
                          </div>
                          <div className="name">{item?.name}</div>
                          <div className="description">{item?.description}</div>
                          {item?.towns?.length > 0 && (
                            <>
                              <div className="title">{t("TOWNS")}</div>
                              <div className="TownList">
                                <ul>{item?.towns?.map((town, i) => <li key={i}>{town}</li>)}</ul>
                              </div>
                            </>
                          )}
                          <div>
                            {item?.type === "service" && (
                              <>
                                <div className="title">{t("EVENTS")}</div>
                                {item?.relatedEvents?.length > 0 ? (
                                  item?.relatedEvents?.map((event, index) => (
                                    <div className="eventData" key={index.toString()}>
                                      <span>{event?.name}</span>
                                    </div>
                                  ))
                                ) : (
                                  <div className="eventData">No Event Found</div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="categoryList categoryList1">
                          <div className="serviceTime">
                            {item?.type === "service" && <span>{t("SERVICE_TIMING")}</span>}
                          </div>
                          <div className="serviceData">
                            {item?.type === "service" &&
                              item?.availableDayTime?.map((dayTime, index) => {
                                const currentDay = moment().format("ddd");
                                const timing = dayTime.isDayActive
                                  ? dayTime.is24Hours
                                    ? "24 Hours available"
                                    : `${dayTime.startTime}-${dayTime.endTime}`
                                  : "Closed";

                                return (
                                  <div key={index} className="serviceTiming">
                                    <span className="serviceDay">{dayTime.day},</span>
                                    {timing}
                                    <span className="isActive serviceDay">
                                      {dayTime?.isActive === "Close" ? "Closed" : dayTime?.isActive}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="categoryList categoryList2">
                          <div className="location">
                            <span>{t("LOCATION")}: </span>
                            {item?.address ? item?.address : t("NOT_AVAILABLE")}
                          </div>
                          {item?.type === "service" && (
                            <>
                              <div className="location">
                                <span>{t("WEBSITE")}: </span>
                                {item?.websiteLink ? item?.websiteLink : t("NOT_AVAILABLE")}
                              </div>
                              <div className="location">
                                <span>{t("TWITTER")}: </span>
                                {item?.twitterLink ? item?.twitterLink : t("NOT_AVAILABLE")}
                              </div>
                              <div className="location">
                                <span>{t("PHONE_NUMBER")}: </span>
                                {item?.phoneNumber
                                  ? item?.phoneNumber
                                  : t("NOT_AVAILABLE")}
                              </div>
                              <div className="location">
                                <span>{t("FACEBOOK")}: </span>
                                {item?.facebookLink
                                  ? item?.facebookLink
                                  : t("NOT_AVAILABLE")}
                              </div>
                              <div className="location">
                                <span>{t("LINKEDIN")}: </span>
                                {item?.linkedLink
                                  ? item?.linkedLink
                                  : t("NOT_AVAILABLE")}
                              </div>
                              <div className="location">
                                <span>{t("EMAIL_ADDRESS")}: </span>
                                {item?.email
                                  ? item?.email
                                  : t("NOT_AVAILABLE")}
                              </div>
                              <div className="location">
                                <span>{t("INSTAGRAM")}: </span>
                                {item?.instagramLink
                                  ? item?.instagramLink
                                  : t("NOT_AVAILABLE")}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {eventsData.length > 0 && (
                <>
                  <div className="titleText">
                    <div className="service">
                      <span>{t("EVENTS")}</span>
                    </div>
                  </div>
                  <div className="showTable">
                    {eventsData?.map((item, j) => {
                      const startDate = moment(`${moment(item?.eventStartDate).utc().format("yyyy/MM/DD")} ${item?.eventStartTime}`).format(
                        "DD MMM YYYY HH:mm"
                      );
                      const endDate = item?.eventEndDate
                        ? moment(`${moment(item?.eventEndDate).utc().format("yyyy/MM/DD")} ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm")
                        : "--:--";

                      const total = servicesData?.length + j;

                      return (
                        <div key={j} id={`data-${index}-${total}`} className="servicesEventsDetails">
                          <div className="categoryList">
                            <div className="category">
                              {item?.categoryName}
                              {item?.onlineStatus && <span>{t("ONLINE")}</span>}
                            </div>
                            <div className="name">{item?.name}</div>
                            <div className="description">{item?.description}</div>
                            {item?.towns?.length > 0 && (
                              <>
                                <div className="title">{t("TOWNS")}</div>
                                <div className="TownList">
                                  <ul>{item?.towns?.map((town, i) => <li key={i}>{town}</li>)}</ul>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="categoryList categoryList1"></div>
                          <div className="categoryList categoryList2">
                            {item?.type === "event" && (
                              <div className="location">
                                <span>{t("EVENT_TIMING")}: </span>
                                {`${startDate} - ${endDate}`}
                              </div>
                            )}
                            <div className="location">
                              <span>{t("LOCATION")}: </span>
                              {item?.address ? item?.address : t("NOT_AVAILABLE")}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          );

        })}
      </div>
    </React.Fragment>
  );
};

export default HomeMapView;
