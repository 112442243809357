import axios from "axios";
import { get } from "lodash";
import jwtService from "./../services/jwtService";
import localStorageService from "../services/localStorageService";

/**
 * Create an instance of Axios with a base URL set to the value of the REACT_APP_BASE_URL environment variable.
 * @returns {AxiosInstance} - An instance of Axios with the base URL set.
 */
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

let isRefreshing = false;
let failedQueue = [];
/**
 * Processes the failed queue by either rejecting the promises with the given error or resolving them with the given token.
 * @param {Error} error - The error to reject the promises with. If null, the promises will be resolved with the token.
 * @param {any} [token=null] - The token to resolve the promises with. If not provided, the promises will be rejected with the error.
 * @returns None
 */

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error)
            prom.reject(error);
        else
            prom.resolve(token);
    });
    failedQueue = [];
};

/**
 * Interceptor function that adds the JWT token to the request headers if it exists.
 * @param {Object} config - The request configuration object.
 * @returns The modified request configuration object with the JWT token added to the headers.
 */
instance.interceptors.request.use(config => {
    if (jwtService.getToken())
        config.headers['Authorization'] = jwtService.getToken();
    return config;
});

/**
 * Interceptor function that handles responses from API requests.
 * @param {function} response - The success callback function that handles the response data.
 * @param {function} error - The error callback function that handles any errors that occur.
 * @returns A Promise that resolves with the response data or rejects with an error.
 */
instance.interceptors.response.use(
    response => response.data,
    error => {
        const originalRequest = error.config;
        const responseStatus = get(error, "response.status", "")

        // console.log("originalRequest", originalRequest);
        // If refresh token fails
        if (responseStatus === 401 && error.config.url.indexOf("refreshTokens") !== -1) {
            processQueue(error, null);
            isRefreshing = false;
            return Promise.reject(error);
        }

        if (responseStatus === 401 && error.config.url.indexOf("login") !== -1) {
            return Promise.reject(error);
        }

        // Check if original request 
        if (responseStatus === 401 && !originalRequest._retry) {
            // Push all the failed request due to expired token in queue
            if (isRefreshing) {
                return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(token => {
                        originalRequest.headers["Authorization"] = token;
                        return instance(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // Try to refresh token
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refreshTokens`, { refreshToken: jwtService.getRefreshToken() })
                    /* 
                        On success save token, set headers and start processing 
                        previously failed requests with new token
                    */
                    .then((response) => {
                        const tokens = response?.data?.payload?.tokens
                        jwtService.saveToken(tokens);
                        console.log("token for refresh ", tokens);
                        axios.defaults.headers.common["Authorization"] = tokens.access.token;
                        originalRequest.headers["Authorization"] = tokens.access.token;
                        processQueue(null, tokens.access.token);
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        console.log("refresh token expired!");
                        const homeURL = window.location.protocol + '//' + window.location.hostname + '/admin';
                        processQueue(err, null);
                        jwtService.destroyToken()
                        localStorageService.setItem('isAuthenticated', false)
                        window.location.href = homeURL;
                        reject(err);
                    })
                    .finally(() => isRefreshing = false);
            });
        }
        return Promise.reject(error);
    }
);

export default instance
