/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import useWindowDimension from "../../../hooks/useWindowDimension";
import CustomAddEvent from "../../../components/customAddEvent/customAddEvent";
import CustomLeftPart from "../../../components/customLeftPart/customLeftPart";
import CustomEventListing from "../../../components/customEventListing/customEventListing";
import Scrollbars from "react-custom-scrollbars-2";
import "./eventManagement.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getTownList } from "../../../state/ducks/townDetails/action";
import { getCategoryList } from "../../../state/ducks/categoryDetails/action";
import { Helmet } from 'react-helmet';

/**
 * Component for managing events.
 * @param {object} props - The props passed to the component.
 * @returns The EventManagement component.
 */
function EventManagement(props) {
  const CLOUDINARY_URL =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER;

  const { t } = useTranslation();
  const dimensions = useWindowDimension();
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(true);
  let [leftPart, setShowLeftPart] = useState(false);
  const [headerHeight, SetHeaderHeight] = useState("");
  let [refreshCategory, setRefreshCategory] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(null);
  let [showEventDetailsModal, setShowEventDetailsModal] = useState(false);


  /**
   * A React useEffect hook that sets the height of the header element.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("header")[0].offsetHeight);
    dispatch(getTownList())
    dispatch(getCategoryList())
  }, []);

  /**
   * Renders a page with a left and right part. The left part contains a menu button and a custom left part component.
   * The right part contains either a custom add event component or a custom event listing component, depending on the state.
   * If there are no business details to display, a "no category" message is shown.
   * @returns The rendered page component.
   */
  return (<>
    <Helmet>
      <title>Events In Angus | Angus Recovery Road Map</title>
    </Helmet>
    <div
      className="pageMain"
      style={{
        height: dimensions.height - headerHeight,
        marginTop: headerHeight,
      }}
    >
      <div className="event_management_page">
        {leftPart && (
          <div
            className="overLayMenu"
            onClick={() => setShowLeftPart(false)}
          ></div>
        )}

        <button
          className="category_btn"
          onClick={() => {
            setShowLeftPart(true);
          }}
        >
          <span className="icon-menu"></span>
          {t("CATEGORY")}
        </button>
        <div className={leftPart ? "left_part leftPartshow" : "left_part "}>
          <CustomLeftPart
            refreshCategory={refreshCategory}
            setRefreshCategory={(value) => setRefreshCategory(value)}
            onClickClose={() => {
              setShowLeftPart(false);
            }}
            onViewClick={(item) => {
              setShowEventDetails(item);
              setIsModal(true);
            }}
            addUncategorised={true}
            setShowEventDetailsModal={(i) => setShowEventDetailsModal(i)}
          />
        </div>

        <div className={showEventDetailsModal ? "right_part rightSidebarshow" : "right_part"}>
          <Scrollbars style={{ height: dimensions.height - headerHeight }}>
            {showEventDetails &&
              showEventDetails.eventCount === 0 &&
              isModal ? (
              <CustomAddEvent
                item={showEventDetails}
                callback={() => {
                  // console.log('callback called CustomAddEvent')
                  setRefreshCategory(true);
                }}
                onClickCancel={() => setIsModal(false)}
              />
            ) : (
              showEventDetails && (
                <CustomEventListing
                  mainItem={
                    (showEventDetails && showEventDetails) || "mainItem"
                  }
                  color={
                    (showEventDetails && showEventDetails.color) ||
                    "color"
                  }
                  title={
                    (showEventDetails && showEventDetails.title) ||
                    t("title")
                  }
                  id={showEventDetails && showEventDetails?._id}
                  callback={() => {
                    // console.log('callback called CustomEventListing')
                    setRefreshCategory(true);
                  }}
                  onRefresh={() => setIsModal(true)}
                  showEventDetailsModal={showEventDetailsModal}
                  setShowEventDetailsModal={(i) => {
                    setShowEventDetailsModal(i);
                  }}
                />
              )
            )}
            {!showEventDetails && (
              <div
                className="no_categroy"
                style={{ height: dimensions.height - headerHeight }}
              >
                <div className="no_categroy_logo">
                  <img
                    src={CLOUDINARY_URL + "no-categroy.png"}
                    alt="no-categroy"
                  />
                </div>
                <div className="no_categroy_txt">
                  {t("THERE_IS_NO_EVENT_ADDED_YET_PLEASE_ADD_ONE_TO_GET_STARTED")}
                </div>
              </div>
            )}
          </Scrollbars>
        </div>
      </div>
    </div>
  </>
  );
}

export default EventManagement;
