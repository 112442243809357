/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { get } from "lodash";
import axios from "axios";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import SelectSearch from "react-select-search";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import "../../../node_modules/react-select-search/style.css";

import Constant from "../../util/constant";
import { Controller } from "react-hook-form";
import { showToast } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";
import { addEvent } from "../../state/ducks/eventListDetails/operations";
import { getAllBusinessList } from "../../state/ducks/businessListDetails/operations";

import Label from "../../components/label/label";
import HookForm from "../../components/hookForm/hookForm";
import Calender from "../../components/calender/calender";
import CheckBox from "../../components/checkbox/checkBox";
import TextField from "../../components/textField/textField";
import TimePicker from "../../components/timePicker/timePicker";
import CustomButton from "../../components/customButton/customButton";
import CustomDropdown from "../../components/customDropdown/customDropdown";
import GooglePlaceDropDown from "../../components/googlePlaceDropDown/googlePlaceDropDown";

import "./customAddEvent.scss";
import CustomeSelectBox from "../customeSelectBox/customeSelectBox";
import CustomToggleButton from "../customToggleButton/customToggleButton";

const CustomAddEvent = (props) => {
  let { onClickCancel, item } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dimensions = useWindowDimension();
  const [busy, setBusy] = useState(false);
  const [categroy, setCategroy] = useState();
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [checked, setChecked] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [businessId, setBusinessId] = useState();
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [titleHeight, SetTitleHeight] = useState("");
  const [headerHeight, SetHeaderHeight] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [serviceNameList, setServiceNameList] = useState([]);
  const [buttonHeight, SetButtonHeight] = useState("");
  const [customValue, setCustomValue] = useState(null);
  const [portLocation, setPortLocation] = useState(null);
  const [characterCount, setcharacterCount] = useState(0);
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessLocation, setBusinessLocation] = useState(null);
  const [addbusinessHeight, SetAddBusinessHeight] = useState("");
  const [categoryEventCount, setCategoryEventCount] = useState();
  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const [allBusinessDetails, setAllBusinessDetails] = useState([]);
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState();

  const colorList = useSelector(
    (state) => state?.CategoryDetails?.getCategoryListForColor?.categoryList
  );
  const townList = useSelector(state => state?.townDetails?.townList?.payload)
  console.log("businessAddress", businessAddress);
  console.log("businessLocation", businessLocation);
  console.log("portLocation", portLocation);
  var todayDate = new Date();
  var dd = String(todayDate.getDate()).padStart(2, "0");
  var mm = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = todayDate.getFullYear();
  const today = dd + "/" + mm + "/" + yyyy;

  /**
   * Runs the getDropDownValue function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    getDropDownValue();
  }, [colorList]);

  useEffect(() => {
    getBusinessData();
  }, []);

  /**
   * Retrieves the dropdown value from the local storage and updates the state with the new value.
   * @returns None
   */
  const getDropDownValue = async () => {
    let data = localStorage.getItem("Uncategorised");
    let uncategorisedCategory = JSON.parse(data);

    let newArray = [];
    let uncategorised = {
      _id: uncategorisedCategory?._id,
      categoryName: uncategorisedCategory?.categoryName,
      color: uncategorisedCategory?.color,
    };
    if (typeof colorList !== "undefined" && colorList.length > 0) {
      newArray = [...colorList, uncategorised];
      let list = newArray?.map((item) => {
        let colorObj = {
          id: item?._id,
          value: item?.categoryName,
          color: item?.color,
        };
        return colorObj;
      });
      console.log("colorlist 1", list);
      setServiceNameList(list);
      setSelectedItem(list?.find((i) => i.id === item._id))
    }
  };

  /**
   * useEffect hook that updates the selected item and category event count
   * whenever the item or its event count changes.
   * @param {object} props - The props object containing the item and its properties.
   * @param {object} props.item - The item object.
   * @param {string} props.item.color - The color of the item.
   * @param {string} props.item._id - The ID of the item.
   * @param {string} props.item.categoryName - The name of the category the item belongs to.
   * @returns None
   */
  useEffect(() => {
    let data = {
      color: item?.color,
      id: item?._id,
      categoryName: item?.categoryName,
    };
    console.log("item", item);
    setSelectedItem(data);
    setCategoryEventCount(item?.eventCount);
  }, [item?.eventCount, item]);

  /**
   * Retrieves business data asynchronously and updates the state with the fetched data.
   * @returns None
   */
  const getBusinessData = async () => {
    try {
      setBusy(true);
      const res = await dispatch(getAllBusinessList())
      let data = res?.payload;

      const tempData = [];
      let businesssData = data?.map(async (item, index) => {
        let businessObj = {
          value: index,
          name: item?.name,
          color: item?.categoryColor,
          address: item?.address,
          location: {
            latitude: item?.location?.coordinates[1],
            longitude: item?.location?.coordinates[0],
          },
          categoryTitle: item?.categoryName,
          categoryID: item.categoryId,
          bId: item._id,
        };
        tempData.push(businessObj);

        return businessObj;
      });
      setAllBusinessDetails(tempData);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * A functional component that renders a button for selecting a start date.
   * @param {Object} props - The component props.
   * @param {string} props.value - The selected start date value.
   * @param {function} props.onClick - The event handler for when the button is clicked.
   * @returns {JSX.Element} - The rendered button component.
   */
  const StartDate = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-date-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">{t("E.G.15_SEP_2021")}</span>
        )}
      </button>
    );
  };

  /**
   * Renders a button component for selecting a start time.
   * @param {Object} props - The component props.
   * @param {string} props.value - The selected start time value.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered button component.
   */
  const StartTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">{t("09_00_AM")}</span>
        )}
      </button>
    );
  };

  /**
   * Renders an end date component.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the end date.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered end date component.
   */
  const EndDate = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-date-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">
            {t("E.G.17_SEP_2021")}
          </span>
        )}
      </button>
    );
  };

  /**
   * Renders a button component for selecting an end time.
   * @param {Object} props - The component props.
   * @param {string} props.value - The selected end time value.
   * @param {function} props.onClick - The click event handler for the button.
   * @returns {JSX.Element} - The rendered button component.
   */
  const EndTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">{t("17_00")}</span>
        )}
      </button>
    );
  };

  /**
   * Updates the start date state when the user selects a new date.
   * @param {Array<Date>} dates - The selected start date(s).
   * @returns None
   */
  const onChangeStartDate = (dates) => {
    setStartDate(dates);
  };

  /**
   * Updates the start time state with the provided value.
   * @param {times} times - The new start time value.
   * @returns None
   */
  const onChangeStartTime = (times) => {
    setStartTime(times);
  };

  /**
   * Updates the end date value based on the selected dates.
   * @param {Array<Date>} dates - The selected dates.
   * @returns None
   */
  const onChangeEndDate = (dates) => {
    setEndDate(dates);
  };

  /**
   * Updates the end time value with the provided times.
   * @param {any} times - The new end time value.
   * @returns None
   */
  const onChangeEndTime = (times) => {
    setEndTime(times);
  };


  /**
   * An object representing the form fields for adding an event.
   * Each field has a name and a validate object containing validation rules.
   * @property {object} eventName - The event name field.
   * @property {object} descripton - The event description field.
   * @property {object} searchServiceName - The search service name field.
   * @property {object} categroy - The category field.
   * @property {object} town - The town field.
   * @property {object} Livelink - The LinkedIn link field.
   * @property {object} online - The online field.
   * @property {object} address - The address field.
   * @property {object} portLocation - The port location field.
   */
  const addEventForm = {
    eventName: {
      name: "eventName",
      validate: {
        required: {
          value: true,
          message: t("EVENT_NAME_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: t("ONLY_ALPHABETS_NUMBER_ACCENTS"),
        },
      },
    },
    descripton: {
      name: "descripton",
      validate: {
        required: {
          value: true,
          message: t("DESCRIPTION_REQUIRED"),
        },
      },
    },
    searchServiceName: {
      name: "searchServiceName",
      validate: {
        required: {
          value: false,
        },
      },
    },
    categroy: {
      name: "categroy",
      validate: {
        required: {
          value: false,
          message: t("required"),
        },
      },
    },
    town: {
      name: "towns",
      validate: {
        required: {
          value: true,
          message: t("TOWN_REQUIRED"),
        },
      },
    },
    Livelink: {
      name: "Livelink",
      validate: {
        required: {
          value: true,
          message: t("LINKEDIN_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    online: {
      name: "online",
      validate: {
        required: {
          value: false,
        },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: !onlineStatus,
          message: t("ADDRESS_REQUIRED"),
        },
      },
    },
    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: !onlineStatus,
        },
      },
    },
    startdate: {
      name: "startdate",
      validate: {
        required: {
          value: true,
        },
      },
    },
    starttime: {
      name: "starttime",
      validate: {
        required: {
          value: true,
        },
      },
    },
    enddate: {
      name: "enddate",
      validate: {
        required: {
          value: false,
        },
      },
    },
    endtime: {
      name: "endtime",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };

  /**
   * useEffect hook that sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("header")[0].offsetHeight);
    SetTitleHeight(
      document.getElementsByClassName("add_no_business")[0].offsetHeight
    );
    SetAddBusinessHeight(
      document.getElementsByClassName("add_business_title")[0].offsetHeight
    );
    SetButtonHeight(
      document.getElementsByClassName("custombtnfield")[0].offsetHeight
    );
  }, []);


  /**
   * Handles the form submission event.
   * @param {Event} e - The form submission event.
   * @returns None
   */
  const onFormSubmit = async (e) => {
    let startD = e?.startdate;
    let formatedStartDate = moment(startD).format("YYYY-MM-DD");
    let endD = e?.enddate;
    let formateEndDate = moment(endD).format("YYYY-MM-DD");

    let startT = e?.starttime;
    let formatedStartTime = moment(startT).format("HH:mm");
    let endT = e?.endtime;
    let formatedEndTime = moment(endT).format("HH:mm");

    let params = {
      name: e?.eventName,
      eventStartDate: formatedStartDate,
      eventStartTime: formatedStartTime,
      description: e?.descripton,
      categoryId: isCategoryChanged ? e?.categroy?.id : selectedItem?.id,
      businessId: businessId,
      status: Constant.STATUS.ENABLE,
      towns: e.towns.map(town => town.value),
      onlineStatus: e.online,
    };
    if (e?.enddate !== "" || e?.endtime !== "") {
      params = {
        ...params,
        eventEndDate: formateEndDate,
        eventEndTime: formatedEndTime,
      };
    }

    if (e.online) params.liveLink = e.Livelink
    if (e.address) params.address = e.address
    if (e?.portLocation?.location?.long && e?.portLocation?.location?.lat) {
      params.longitude = e.portLocation.location.long
      params.latitude = e.portLocation.location.lat
    }

    // console.log("add event", params);
    try {
      setBusy(true);
      const res = await dispatch(addEvent(params))
      props.callback && props.callback();
      onClickCancel();
      props.onReload && props.onReload();
      if (!res.payload.categoryId || res.payload.categoryId === null) {
        let data = localStorage.getItem("Uncategorised");
        let uncategorisedCategory = JSON.parse(data);

        console.log("uncategorised onFormSubmit", uncategorisedCategory);

        uncategorisedCategory["eventCount"] =
          uncategorisedCategory?.eventCount + 1;
        window.localStorage.setItem(
          "Uncategorised",
          JSON.stringify(uncategorisedCategory)
        );
      }
      dispatch(
        showToast({
          message: res?.message,
          type: "success",
        })
      );
      setBusy(false);
      // clearData();
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Renders a form for adding events with various input fields and buttons.
   * @returns JSX element representing the form for adding events.
   */
  return (
    <div className="CustomAddEvents">
      <div className="add_Business">
        <div className="add_business_container">
          <div className="add_no_business">
            {/* {categoryEventCount > 0 ? (
              <span>{t("PLEASE_FILL_THE_FOLLOWING_FORM_TO_ADD_ONE")}</span>
            ) : (
              <span>
                {t(
                  "NO_EVENTS_ADD_YET_PLEASE_FILL_THE_FOLLOWING_FORM_TO_ADD_ONE"
                )}
              </span>
            )} */}
          </div>

          <div className="add_business_card">
            <div className="add_business_title">{t("ADD_EVENTS")}</div>
            <HookForm
              defaultForm={{}}
              clearForm={true}
              onSubmit={(e) => {
                onFormSubmit(e);
              }}
            >
              {(formMethod) => {
                return (
                  <>
                    <Scrollbars
                      style={{
                        height:
                          dimensions.height -
                          headerHeight -
                          titleHeight -
                          addbusinessHeight -
                          100,
                      }}
                    >
                      <div className="padding_inner">
                        <Label title={t("Event_information")} />
                        <TextField
                          formMethod={formMethod}
                          rules={addEventForm.eventName.validate}
                          name={addEventForm.eventName.name}
                          errors={formMethod?.formState.errors}
                          autoFocus={true}
                          type="text"
                          autoComplete="on"
                          placeholder={t("Event_name")}
                          value={formMethod?.addEventForm?.eventName}
                          maxLength={100}
                        />
                        <div className="discription_txt">
                          <TextField
                            formMethod={formMethod}
                            rules={addEventForm.descripton.validate}
                            name={addEventForm.descripton.name}
                            errors={formMethod?.formState.errors}
                            onChange={(e) =>
                              setcharacterCount(e.target.value.length)
                            }
                            autoFocus={true}
                            autoComplete="on"
                            placeholder={t("Description")}
                            textarea="textarea"
                            maxLength={550}
                          />
                          <div className="textarea_count">
                            {characterCount}/550
                          </div>
                        </div>
                        <div className="searchMainDropdown">
                          <Controller
                            rules={addEventForm.searchServiceName.validate}
                            name={addEventForm.searchServiceName.name}
                            control={formMethod?.control}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <SelectSearch
                                  search={true}
                                  options={allBusinessDetails}
                                  defaultValue={customValue ? customValue : {}}
                                  value={customValue ? customValue : {}}
                                  placeholder={t(
                                    "SEARCH_SERVICE_NAME_TO_LINK_EVENT"
                                  )}
                                  onChange={async (evt, item) => {
                                    // console.log("item.bId", item)
                                    setBusinessId(item.bId);
                                    item.address && setBusinessAddress(item?.address);
                                    let longitude = item?.location?.longitude;
                                    let latitude = item?.location?.latitude;

                                    try {
                                      if (longitude && latitude) {
                                        let locationAddress =
                                          await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},
                                                                 ${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`);

                                        setBusinessLocation({
                                          businessLocation: {
                                            latitude: latitude,
                                            longitude: longitude,
                                            address:
                                              locationAddress?.data?.results[0]
                                                ?.formatted_address,
                                          },
                                        });
                                      }
                                    } catch (err) { }
                                    setCustomValue(evt === null ? '0' : evt);

                                    if (item.address || (longitude && latitude)) setCategoryDisabled(true);
                                    else setCategoryDisabled(false)

                                    formMethod.setValue(
                                      addEventForm.searchServiceName.name,
                                      item,
                                      { shouldValidate: true }
                                    );
                                    let d = allBusinessDetails?.find(
                                      (value) => {
                                        return value.name === item.name;
                                      }
                                    );
                                    const data = {
                                      color: d.color,
                                      id: d.categoryID,
                                      categoryName: d.categoryTitle,
                                    };
                                    formMethod.setValue(
                                      addEventForm.categroy.name,
                                      data,
                                      { shouldValidate: true }
                                    );
                                    setCategroy(data);
                                    setCategoryId(data.id);
                                    setSelectedItem(data);
                                    onChange(evt, item);
                                  }}
                                  disabled={false}
                                />
                                {customValue && (
                                  <button
                                    type="reset"
                                    className="icon-close"
                                    onClick={() => {
                                      setCustomValue("");
                                      setBusinessId();
                                      setCategoryDisabled(false);
                                      setChecked(false)
                                      setBusinessAddress("")
                                      setBusinessLocation(null)
                                      setCategroy()
                                      formMethod.setValue(
                                        addEventForm.address.name,
                                        "",
                                        { shouldValidate: true }
                                      );
                                    }}
                                  ></button>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="input_row">
                          <div className="colorDropdownWrapper">
                            <span
                              className="cricle_select_view"
                              style={{
                                backgroundColor: categroy
                                  ? categroy?.color
                                  : selectedItem?.color,
                              }}
                            ></span>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <CustomDropdown
                                  dropDownItems={serviceNameList}
                                  placeholder={t("SELECT_CATEGORY")}
                                  disabled={categoryDisabled}
                                  selectedValue={selectedItem}
                                  defaultValue={selectedItem}
                                  ColorSelected={true}
                                  onSelect={(evt) => {
                                    setIsCategoryChanged(true);
                                    onChange(evt);
                                    console.log("---", evt)
                                    const data = {
                                      color: evt?.color,
                                      id: evt?.id,
                                      categoryName: evt?.value,
                                    };
                                    setSelectedItem(data);
                                    setCategoryId(evt.id);
                                  }}
                                ></CustomDropdown>
                              )}
                              control={formMethod.control}
                              name={addEventForm.categroy.name}
                              defaultValue={selectedItem}
                              rules={addEventForm.categroy.validate}
                            />
                            <span className="arrow-dropdown icon-sorting"></span>
                          </div>
                        </div>
                        <div className="input_row">
                          <div className="townWrapper">
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <CustomeSelectBox
                                  placeholder={
                                    <>
                                      {t("SELECT_TOWN")}
                                      <span style={{ color: 'red' }}>*</span>
                                    </>
                                  }
                                  defaultValue={[]}
                                  options={townList || []}
                                  isMulti={true}
                                  className={
                                    value?.length > 1 &&
                                    "multipleSelectBox"
                                  }
                                  onChange={(evt) => {
                                    onChange(evt);
                                  }}
                                />
                              )}
                              errors={formMethod?.formState.errors}
                              control={formMethod.control}
                              name={addEventForm.town.name}
                              rules={addEventForm.town.validate}
                            />
                            <span className="arrow-dropdown icon-sorting"></span>
                          </div>
                        </div>
                        <div className="input_row">
                          <div className="onlineWrapper">
                            <Controller
                              defaultValue={false}
                              control={formMethod?.control}
                              name={addEventForm.online.name}
                              rules={addEventForm.online.validate}
                              render={({ field: { onChange, value } }) => (
                                <div className="OnlineSwitchWrapper">
                                  <p>{t("ONLINE")}</p>
                                  <CustomToggleButton
                                    id=""
                                    checked={value}
                                    onCheckChange={(e) => { onChange(e); setOnlineStatus(e); }}
                                  />
                                </div>
                              )}
                            />
                            {formMethod.getValues("online") &&
                              <TextField
                                formMethod={formMethod}
                                rules={addEventForm.Livelink.validate}
                                name={addEventForm.Livelink.name}
                                errors={formMethod?.formState.errors}
                                type="text"
                                autoComplete="on"
                                placeholder={t("Live_link")}
                                maxLength={250}
                              />
                            }
                          </div>
                        </div>
                        <div className="check_row">
                          <Label
                            className="title_top"
                            title={t("EVENT_ADDRESS_AND_LOCATION")}
                          />
                          {categoryDisabled && (
                            <CheckBox
                              labelTitle={t("SAME_AS_SERVICE_ADDRESS")}
                              id="address_checkbox"
                              checked={checked}
                              onCheckedChange={async (checkedStatus) => {
                                setChecked(checkedStatus);
                                (checkedStatus && businessLocation?.businessLocation) || portLocation?.portLocation
                                  ? formMethod.setValue(
                                    addEventForm.portLocation.name,
                                    {
                                      address:
                                        businessLocation?.businessLocation?.address || portLocation?.portLocation?.address,
                                      location: {
                                        lat: businessLocation?.businessLocation?.latitude || portLocation?.portLocation?.latitude,
                                        long: businessLocation?.businessLocation?.longitude || portLocation?.portLocation?.longitude,
                                      },
                                    },
                                    { shouldValidate: true }
                                  )
                                  : formMethod.setValue(
                                    addEventForm.portLocation.name,
                                    "",
                                    { shouldValidate: true }
                                  );
                                (checkedStatus && businessAddress)
                                  ? formMethod.setValue(
                                    addEventForm.address.name,
                                    businessAddress || portLocation?.portLocation?.address,
                                    { shouldValidate: true }
                                  )
                                  : formMethod.setValue(
                                    addEventForm.address.name,
                                    "",
                                    { shouldValidate: true }
                                  );
                              }}
                            />
                          )}
                        </div>
                        <div
                          className={
                            checked && businessAddress ? "address_part disable" : "address_part"
                          }
                        >
                          <TextField
                            formMethod={formMethod}
                            rules={addEventForm.address.validate}
                            name={addEventForm.address.name}
                            errors={onlineStatus ? {} : formMethod?.formState.errors}
                            defaultValue={checked ? businessAddress : ""}
                            value={checked ? businessAddress : ""}
                            disabled={checked && businessAddress}
                            type="text"
                            autoComplete="on"
                            placeholder={t("LOCATION")}
                            maxLength={150}
                            isRequired={onlineStatus ? false : true}
                          />
                        </div>
                        <div
                          className={
                            checked && businessLocation?.businessLocation
                              ? "location_part disable" : "location_part"
                          }
                        >
                          <div>
                            <Controller
                              value={portLocation?.portLocation?.address}
                              control={formMethod?.control}
                              name={addEventForm.portLocation.name}
                              rules={addEventForm.portLocation.validate}
                              render={({ field: { onChange } }) => (
                                <GooglePlaceDropDown
                                  isRequired={onlineStatus ? false : true}
                                  defaultValue={{
                                    label: checked && businessLocation?.businessLocation?.address
                                      ? businessLocation?.businessLocation?.address
                                      : portLocation?.portLocation?.address || '',
                                    value: checked && businessLocation?.businessLocation?.address
                                      ? businessLocation?.businessLocation?.address
                                      : portLocation?.portLocation?.address || '',
                                  }}
                                  getLocation={place => {
                                    console.log("place", place);
                                    place === null ? setPortLocation(null) : setPortLocation({
                                      portLocation: {
                                        latitude: get(place, 'location.lat', 1),
                                        longitude: get(place, 'location.long', 1),
                                        address: get(place, 'address', "")
                                      }
                                    })
                                    // place === null && !checked ? setBusinessLocation(null) : setBusinessLocation({
                                    //   businessLocation: {
                                    //     latitude: get(place, 'location.lat', 1),
                                    //     longitude: get(place, 'location.long', 1),
                                    //     address: get(place, 'address', "")
                                    //   }
                                    // })
                                    onChange(place);
                                  }
                                  }

                                />
                              )} />
                          </div>
                          <div className="map_view">
                            <iframe
                              title={t("maps")}
                              className="map"
                              src={
                                checked
                                  ? `https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                  }
                                      &q=${get(
                                    businessLocation,
                                    "businessLocation.latitude",
                                    1
                                  )},
                                      ${get(
                                    businessLocation,
                                    "businessLocation.longitude",
                                    1
                                  )}`
                                  : `https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                  }
                                    &q=${get(
                                    portLocation,
                                    "portLocation.latitude",
                                    1
                                  )},
                                    ${get(
                                    portLocation,
                                    "portLocation.longitude",
                                    1
                                  )}`
                              }
                              width="100%"
                              height="330px"
                              frameBorder="0"
                              allowFullScreen={false}
                              aria-hidden={false}
                              tabIndex={0}
                            />
                          </div>
                        </div>
                        <Label className="title_top" title={t("Event_timing")} />
                        <div className="businesstiming">
                          <div className="date-filter-main">
                            <div className="datetime_start_title">
                              {t("START_DATE_TIME")}
                            </div>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <Calender
                                  selected={startDate}
                                  onChange={(e) => {
                                    onChangeStartDate(e);
                                    onChange(e);
                                    setEndDate();
                                    setStartTime();
                                    setEndTime();
                                  }}
                                  minDate={new Date()}
                                  dateFormat="d MMM yyyy"
                                  customInput={<StartDate />}
                                  isClearable={false}
                                  value={startDate}
                                />
                              )}
                              control={formMethod.control}
                              name={addEventForm.startdate.name}
                              rules={addEventForm.startdate.validate}
                              defaultValue={""}
                            />
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <TimePicker
                                  selected={startTime}
                                  onChange={(e) => {
                                    let data = moment(e).format("HH:mm");
                                    setStartTime(data);
                                    onChangeStartTime(e);
                                    onChange(e);
                                    setEndTime();
                                  }}
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  customInput={<StartTime />}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  timeIntervals={15}
                                  minTime={
                                    today ===
                                      moment(startDate).format("DD/MM/YYYY")
                                      ? setHours(
                                        setMinutes(
                                          new Date(),
                                          new Date().getMinutes()
                                        ),
                                        new Date().getHours()
                                      )
                                      : setHours(setMinutes(new Date(), 0), 24)
                                  }
                                  maxTime={setHours(
                                    setMinutes(new Date(), 59),
                                    23
                                  )}
                                  isClearable={false}
                                />
                              )}
                              control={formMethod.control}
                              name={addEventForm.starttime.name}
                              rules={addEventForm.starttime.validate}
                              defaultValue={""}
                            />
                          </div>
                          <div
                            className={
                              startDate === null || startTime === null
                                ? "date-filter-main disabled"
                                : "date-filter-main"
                            }
                          >
                            <div className="datetime_title">{t("END_DATE_TIME")}</div>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <Calender
                                  selected={endDate}
                                  onChange={(e) => {
                                    onChangeEndDate(e);
                                    onChange(e);
                                    setEndTime();
                                  }}
                                  minDate={startDate}
                                  dateFormat="d MMM yyyy"
                                  customInput={<EndDate />}
                                  isClearable={false}
                                  value={endDate}
                                />
                              )}
                              control={formMethod.control}
                              name={addEventForm.enddate.name}
                              rules={addEventForm.enddate.validate}
                              defaultValue={""}
                            />
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <TimePicker
                                  selected={endTime}
                                  onChange={(e) => {
                                    let data = moment(e).format("HH:mm");

                                    setEndTime(data);
                                    onChangeEndTime(e);
                                    onChange(e);
                                  }}
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  customInput={<EndTime />}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  minTime={
                                    moment(startDate).format("Do YYYY") ===
                                      moment(endDate).format("Do YYYY")
                                      ? setHours(
                                        setMinutes(
                                          startTime,
                                          moment(startTime).format(`mm`)
                                        ),
                                        moment(startTime).format("HH")
                                      )
                                      : setHours(setMinutes(new Date(), 0), 24)
                                  }
                                  maxTime={setHours(
                                    setMinutes(new Date(), 59),
                                    23
                                  )}
                                  timeIntervals={15}
                                  isClearable={false}
                                  value={endTime}
                                />
                              )}
                              control={formMethod.control}
                              name={addEventForm.endtime.name}
                              rules={addEventForm.endtime.validate}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    </Scrollbars>
                    <div className="custombtnfield">
                      <CustomButton
                        type="submit"
                        title={t("ADD")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                      <CustomButton
                        type="button"
                        onClick={onClickCancel}
                        className="outline_btn"
                        title={t("CANCEL")}
                      />
                    </div>
                  </>
                );
              }}
            </HookForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAddEvent;
