import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";
import useWindowDimension from "../../../hooks/useWindowDimension";
import "./changePassword.scss";

/**
 * A functional component that renders a change password page.
 * @param {object} props - The props passed to the component.
 * @returns The rendered change password page.
 */
function ChangePassword(props) {
  const { t } = useTranslation();
  const dimensions = useWindowDimension();
  const [headerHeight, SetHeaderHeight] = useState("");

  /**
   * A React useEffect hook that sets the height of the header based on the height of the
   * "profiledropdown" element.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(
      document.getElementsByClassName("profiledropdown")[0].offsetHeight
    );
  }, []);

  /**
   * Renders a page with a change password form.
   * @returns {JSX.Element} - The rendered page component.
   */
  return (
    <div
      className="pageMain"
      style={{
        height: dimensions.height - headerHeight,
        marginTop: headerHeight,
      }}
    >
      <div className="page">
        <h1 className="pageTitle">{t("CHANGEPASSWORD_BT")}</h1>
        <div className="adminProfile">
          <Scrollbars
            style={{ height: dimensions.height - headerHeight }}
          ></Scrollbars>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
