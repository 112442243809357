import axios from "axios";
import { get } from "lodash";
import jwtService from "./jwtService";
import localStorageService from "./localStorageService";

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});
let isRefreshing = false;
let failedQueue = [];

/**
 * Processes the failed queue by either rejecting the promises with the given error or resolving them with the given token.
 * @param {Error} error - The error to reject the promises with. If null, the promises will be resolved with the token.
 * @param {any} [token=null] - The token to resolve the promises with. If not provided, the promises will be rejected with the error.
 * @returns None
 */
const processQueue = (error, token = null) => {
    /**
     * Processes the failed queue by either rejecting the promises with the given error or resolving them with the given token.
     * @param {Error} error - The error to reject the promises with. If null, the promises will be resolved with the token.
     * @param {any} [token=null] - The token to resolve the promises with. If not provided, the promises will be rejected with the error.
     * @returns None
     */
    failedQueue.forEach(prom => {
        if (error)
            prom.reject(error);
        else
            prom.resolve(token);
    });
    failedQueue = [];
};

/**
 * Axios interceptor function that adds the JWT token to the request headers.
 * @param {AxiosRequestConfig} config - The Axios request configuration object.
 * @returns The modified Axios request configuration object with the JWT token added to the headers.
 */
AxiosInstance.interceptors.request.use(config => {
    if (jwtService.getToken())
        config.headers['Authorization'] = jwtService.getToken();
    return config;
});

/**
 * Interceptor function for Axios responses. Handles refreshing access tokens and retrying failed requests.
 * @param {Object} response - The response object from the server.
 * @param {Object} error - The error object if the request failed.
 * @returns The response data if the request was successful, or a rejected Promise if the request failed.
 */
AxiosInstance.interceptors.response.use(
    response => response.data,
    error => {
        const originalRequest = error.config;
        const responseStatus = get(error, "response.status", "")
        // If refresh token fails
        if (responseStatus === 401 && error.config.url.indexOf("refreshTokens") !== -1) {
            processQueue(error, null);
            isRefreshing = false;
            return Promise.reject(error);
        }

        if (responseStatus === 401 && error.config.url.indexOf("login") !== -1) {
            return Promise.reject(error);
        }

        // Check if original request 
        if (responseStatus === 401 && !originalRequest._retry) {
            // Push all the failed request due to expired token in queue
            if (isRefreshing) {
                return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(token => {
                        originalRequest.headers["Authorization"] = token;
                        return AxiosInstance(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // Try to refresh token
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refreshTokens`, { refreshToken: jwtService.getRefreshToken() })
                    /* 
                        On success save token, set headers and start processing 
                        previously failed requests with new token
                    */
                    .then((response) => {
                        const tokens = response?.data?.payload?.tokens
                        jwtService.saveToken(tokens);

                        axios.defaults.headers.common["Authorization"] = tokens.access.token;
                        originalRequest.headers["Authorization"] = tokens.access.token;
                        processQueue(null, tokens.access.token);
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        console.log("refresh token expired!");
                        const homeURL = window.location.protocol + '//' + window.location.hostname + '/admin';
                        processQueue(err, null);
                        jwtService.destroyToken()
                        localStorageService.setItem('isAuthenticated', false)
                        window.location.href = homeURL;
                        reject(err);
                    })
                    .finally(() => isRefreshing = false);
            });
        }

        return Promise.reject(error);
    }
);

export default AxiosInstance