import React, { useEffect, useState } from "react";
import { GoogleMap, KmlLayer, Marker, useJsApiLoader } from "@react-google-maps/api";
import ViewDetails from "../viewDetails/viewDetails";
import GoogleMapStyle from "./googleMapStyle.json";
import useWindowDimension from "../../hooks/useWindowDimension";
import Constant from "../../util/constant";

/**
 * A functional component that renders a Google Map with markers based on the provided map data.
 * @param {Object} props - The component props.
 * @param {Array} props.mapData - The array of map data containing information about the markers.
 * @returns The rendered Google Map component.
 */
const Map = (props) => {
  let tempMap = props?.mapData;
  const dimension = useWindowDimension();
  const [showModal, setShowModal] = useState(false);
  const [viewPage, setViewPage] = useState({})
  const [center, setCenter] = useState({})
  /**
   * Updates the center of the map based on the selected town in the props.
   * @param {Object} props - The props object containing the selected town.
   * @returns None
   */
  useEffect(() => {
    switch (props?.town) {
      case 'Monifieth':
        setCenter(Constant.TOWN_COORDINATES.Monifieth)
        break;
      case 'Kirriemuir':
        setCenter(Constant.TOWN_COORDINATES.Kirriemuir)
        break;
      case 'Montrose':
        setCenter(Constant.TOWN_COORDINATES.Montrose)
        break;
      case 'Brechin':
        setCenter(Constant.TOWN_COORDINATES.Brechin)
        break;
      case 'Arbroath':
        setCenter(Constant.TOWN_COORDINATES.Arbroath)
        break;
      case 'Forfar':
        setCenter(Constant.TOWN_COORDINATES.Forfar)
        break;
      case 'Carnoustie':
        setCenter(Constant.TOWN_COORDINATES.Carnoustie)
        break;
      default:
        setCenter(Constant.TOWN_COORDINATES.Angus)
        break;
    }
  }, [props?.town])

  const containerStyle = {
    width: "100%",
    height: dimension.height - 60 + "px",
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Constant.GOOGLEMAPAPI.APIKEY
  })

  /**
   * Renders a Google Map component with markers based on the provided data.
   * @param {boolean} isLoaded - Indicates whether the Google Map API has been loaded successfully.
   * @param {object[]} tempMap - An array of objects representing the markers to be displayed on the map.
   * @param {object} center - The coordinates of the center of the map.
   * @param {boolean} showModal - Indicates whether the details modal should be shown.
   * @param {function} setViewPage - A function to set the currently viewed page.
   * @param {function} setShowModal - A function to set the visibility of the details modal.
   * @param {object} viewPage - The currently viewed page.
   * @returns The rendered
   */

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{ styles: GoogleMapStyle, disableDefaultUI: true }}
        center={center}
        zoom={props?.town === 'All' ? 12 : 15}
      >
        {props?.town !== 'All' &&
          <KmlLayer
            url={`${process.env.REACT_APP_CLOUDNARY_URL}${process.env.REACT_APP_CLOUDNARY_NAME}${process.env.REACT_APP_CLOUDNARY_KMLFILES}${props?.town}.kml`}
            options={{ preserveViewport: true }}
          />
        }
        {tempMap?.map((item, index) => {
          return (
            item.type === 'service' ?
              <Marker key={index} position={{ lat: item?.location?.coordinates[1], lng: item?.location?.coordinates[0] }}
                icon={{
                  path: 'M19.8608 0.595947C19.5738 0.595947 19.2869 0.595947 19 0.595947C8.52629 0.595947 0.0612564 8.97997 0.0612564 19.3534C0.0612564 28.448 6.66113 36.1215 15.4131 37.8267L17.8522 42.6581C18.2827 43.6528 19.7174 43.6528 20.1478 42.6581L22.5869 37.8267C31.3389 36.1214 37.9388 28.59 37.9388 19.3534C37.9388 9.26416 29.9041 1.02215 19.8609 0.595947H19.8608ZM22.013 31.2901C21.2957 31.4322 20.4347 31.5743 19.5739 31.5743C19.4305 31.5743 19.2869 31.5743 19.1435 31.5743C12.2566 31.5743 6.66113 26.0323 6.66113 19.2114C6.66113 12.3906 12.2567 6.84855 19.1435 6.84855C19.2869 6.84855 19.4305 6.84855 19.5739 6.84855H19.7174C26.3173 7.13278 31.4824 12.5327 31.4824 19.2114C31.4824 25.1797 27.3216 30.0112 22.013 31.2901H22.013ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901Z',
                  fillColor: item.color,
                  strokeColor: item.color,
                  fillOpacity: 1,
                  anchor: new window.google.maps.Point(17, 46),
                  scaledSize: new window.google.maps.Size(37, 37)
                }}
                // className={item.theme}
                onClick={() => {
                  setShowModal(true)
                  setViewPage(item)
                }}
              />
              :
              <Marker
                key={index.toString()}
                position={{ lat: item?.location?.coordinates[1], lng: item?.location?.coordinates[0] }}
                icon={{
                  path: 'M15.665 15.4209L14.7595 16.3737V28.0684C14.7595 28.6914 14.9475 29.295 15.2922 29.8033C15.4699 30.0654 15.86 30.0654 16.0378 29.8033C16.3824 29.295 16.5704 28.6914 16.5704 28.0684V16.3737L15.665 15.4209Z M14.7595 12.7969V16.3739C15.0574 16.4033 15.3594 16.3739 15.665 16.3739C15.9705 16.3739 16.2725 16.4033 16.5704 16.3739V12.7969H14.7595Z M15.665 0V14.6076C19.6988 14.6076 22.9689 11.3376 22.9689 7.30382C22.9689 3.27006 19.6988 0 15.665 0Z M21.1579 7.30382C21.1579 3.27006 18.6986 0 15.6649 0C11.6311 0 8.36108 3.27006 8.36108 7.30382C8.36108 11.3376 11.6311 14.6076 15.6649 14.6076C18.6986 14.6076 21.1579 11.3376 21.1579 7.30382Z',
                  fillColor: item.color,
                  strokeColor: item.color,
                  fillOpacity: 1,
                  anchor: new window.google.maps.Point(17, 46),
                  scaledSize: new window.google.maps.Size(37, 37)
                }}
                className={item.theme}
                onClick={() => {
                  setShowModal(true)
                  setViewPage(item)
                }}
              />
          );
        })}
      </GoogleMap>

      <ViewDetails
        mapData={viewPage}
        showModal={showModal}
        closeModal={() => {
          setShowModal(false)

        }}
      />
      <a href="/" className="reCenter">
        <span className="icon icon-Target"></span>
      </a>
    </div>
  ) : <></>;
};

export default Map;
