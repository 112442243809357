import React from 'react';
import CustomToggleButton from '../customToggleButton/customToggleButton';
import './listEvent.scss';
import { useTranslation } from 'react-i18next';


/**
 * A component that renders a list item for an event.
 * @param {Object} props - The props for the component.
 * @param {string} props.title - The title of the event.
 * @param {string} props.location - The location of the event.
 * @param {boolean} props.checked - Indicates whether the event is checked.
 * @param {function} props.onCheckChange - The function to call when the checked state changes.
 * @param {string} props.color - The color of the event.
 * @param {function} props.onClickDelete - The function to call when the delete button is clicked.
 * @param {string} props.className - The class name for the component.
 * @param {
 */
const ListEvent = (props) => {
  const { t } = useTranslation()

  let { title, location, checked, onCheckChange, color, onClickDelete,
    className, onListItemClick, selected, datetime, btnShow, expiredShow, showHeader, expiredCount, onlineStatus } = props

  return (
    <div className={"ListEventMain" + className} >
      {showHeader && <div backgroundColor='red' className="past_title">
        <div className="pastEventTitle">
          {t("Past_Event")}
        </div>
        <div className="totalEventTitle">
          <div className="count_title" >{expiredCount > 1 ? t("Total_Expired_Events") : t("Total_Expired_Event")}</div>
          <div className="count_number">{expiredCount}</div>
        </div>


        <span className="line_border"></span>
        <span className="category_name" style={{ color: color }}>{title}</span></div>}
      <button className={selected ? "full_btn list_selected_bg" : "full_btn"} style={{ borderColor: selected ? color : "#fff" }} type="button" onClick={onListItemClick}>
        <span className="active_bg" style={{ backgroundColor: color }}></span>
        <div className="title_left">
          <div className="title_row">
            <div className="title_txt">{title}</div>
            <div className="location"><span className="icon-pin"></span>{onlineStatus && !location ? 'Online' : location}</div>
          </div>
        </div>
        <div className="middle_part">
          <div className="datetime_txt"><span className="icon-calender"></span>{datetime}</div>
        </div>
        <div className="action_right" onClick={(e) => { e.stopPropagation() }}>
          {/* <button onClick={onClickEdit} className="icon-pencil"></button> */}
          {btnShow && <> <button onClick={onClickDelete} className="icon-delete"></button>
            <CustomToggleButton id="" checked={checked} onCheckChange={onCheckChange} /></>}
          {expiredShow && <button onClick={onClickDelete} className="expired_btn">{t("Expired")}</button>}
        </div>
      </button>
    </div>
  );
}

export default ListEvent;
