import { useEffect, useState } from 'react'

/**
 * A custom React hook that returns the current dimensions of the window.
 * @returns {Object} - An object containing the height and width of the window.
 */
export default function useWindowDimension() {
    let [dimensions, setDimension] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
            setDimension({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
        return () =>
            window.removeEventListener('resize', handleResize)
    });

    return dimensions
}

