/* eslint-disable import/no-anonymous-default-export */
const safeJSONParser = json => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return json;
  }
};

/**
 * Retrieves the value associated with the specified key from the browser's local storage.
 * @param {string} key - The key of the item to retrieve.
 * @returns The value associated with the specified key, or null if the key does not exist or the value cannot be parsed as JSON.
 */
export const getItem = key => {
  return safeJSONParser(window.localStorage.getItem(key));
};

/**
 * Sets a value in the browser's local storage.
 * @param {string} key - The key to store the value under.
 * @param {any} value - The value to store.
 * @returns None
 */
export const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Removes an item from the browser's local storage.
 * @param {string} key - The key of the item to remove.
 * @returns None
 */
export const removeItem = key => {
  window.localStorage.removeItem(key);
};

/**
 * Clears all data stored in the local storage of the browser window.
 * @returns None
 */
export const clear = () => {
  window.localStorage.clear();
};

export default { getItem, setItem, removeItem };
