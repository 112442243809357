import React, { useState } from 'react';
import Select, { components } from 'react-select';
import "./customeSelectBox.scss";

const CustomeSelectBox = (props) => {
  const {
    options,
    isMulti,
    onChange,
    formatOptionLabel,
    isDisabled,
    defaultValue,
    value,
    className,
    classNamePrefix,
    isClearable,
    placeholder
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label for="checkbox">
          <span></span>
        </label>
        {children}
      </components.Option>
    );
  };

  return (
    <div className="App">
      <Select
        defaultValue={defaultValue}
        isMulti={isMulti}
        isClearable={isClearable}
        closeMenuOnSelect={!isMulti} // Close menu on select when not in multi-select mode
        hideSelectedOptions={false}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onChange={(selectedOption) => {
          onChange(selectedOption);
        }}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        options={options}
        components={{ Option: CustomOption }}
        isDisabled={isDisabled}
        menuIsOpen={menuIsOpen}
        value={value}
        className={className + " react-select-container"}
        classNamePrefix={classNamePrefix + " react-select"}
      />
    </div>
  );
}

export default CustomeSelectBox;
