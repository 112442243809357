import React from 'react';
import Dropzone from 'react-dropzone';
import './fileUpload.scss';

/**
 * A component for uploading files.
 * @param {Object} props - The props for the FileUpload component.
 * @param {string} props.parentClassname - The classname for the parent div.
 * @param {boolean} props.multiple - Whether multiple files can be uploaded.
 * @param {function} props.onDrop - The function to be called when files are dropped.
 * @param {string} props.error - The error message to display.
 * @param {function} props.setRef - The function to set the reference to the Dropzone component.
 * @returns {JSX.Element} - The FileUpload component.
 */
const FileUpload = (props) => {
    let { parentClassname, multiple, onDrop, error, setRef } = props;
    return (
        <>
            <div className={parentClassname}>
                <div className={`dropzoneCustom ${error ? 'error' : ''}`}>
                    <Dropzone onDrop={acceptedFiles => { onDrop && onDrop(acceptedFiles) }} ref={setRef}>
                        {({ getRootProps, getInputProps }) => {
                            return <div className="txtDropzone" {...getRootProps()}>
                                <input {...getInputProps()} multiple={multiple} accept="image/png, image/jpg, image/jpeg" />
                                {props.children}
                            </div>
                        }}
                    </Dropzone>
                </div>
            </div>
            {error ? <div className="errorTxtFile"><span className="joyclub-Info"></span><p>{error}</p></div> : null}
        </>
    )
}

FileUpload.defaultProps = {
    hasLabel: true,
    parentClassname: 'fileUploadMain',
    btnVsible: false

}

export default FileUpload;