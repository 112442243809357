import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ToastComponent from "./components/toastComponent/toastComponent";
import AdminLayout from "./layout/adminLayout/adminLayout";
import { AdminLayoutRoute, Authroutes, routes } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app.scss";
import { getItem } from "./services/localStorageService";
import { useSelector } from "react-redux";

/**
 * The main component of the application.
 * @param {Object} props - The props passed to the component.
 * @returns The JSX code representing the application.
 */
function App() {
  const { isAuthenticated: login } = useSelector(state => state.auth.session)
  const isAuthenticated = getItem("isAuthenticated") || login;

  return (
    /**
     * Renders a React component that contains multiple routes for navigation.
     * @returns {JSX.Element} - The rendered React component.
     */
    <div className="App">
      <React.Fragment>
        <ToastComponent />
        <Routes>
          <>
            {routes.map((item, index) => {
              return (
                <Route
                  key={index}
                  path={item.path}
                  element={item.component}
                  exact
                />
              );
            })}
            {/* admin Route */}
            {Authroutes.map((route, idx) => (
              <>
                <Route
                  key={idx}
                  path={route.path}
                  element={route.element}
                  exact
                />
              </>
            ))}
            {
              (AdminLayoutRoute.map((route, idx) => {
                return (
                  <>
                    {isAuthenticated ?
                      <Route element={<AdminLayout />}>
                        <Route
                          key={idx}
                          path={route.path}
                          element={route.element}
                          exact
                        />
                      </Route> :
                      <Route key={idx} path={route.path} element={<Navigate to={'/admin/login'} replace />} />
                    }
                  </>
                );
              }))
            }
          </>
        </Routes>
      </React.Fragment>
    </div>
  );
}

export default App;
