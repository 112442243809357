import * as types from './types';

export const getCategoryList = (data, page = 1, limit = 10) => (
    {
        type: types.GET_CATEGORY_LIST,
        meta: {
            async: true,
            blocking: false,
            path: `category/getCategoryList?sortBy=status:desc&search=${data}&page=${page}&limit=${limit}`,
            method: 'GET',
        }
    })

export const getCategoryListForColor = () => (
    {
        type: types.GET_CATEGORY_LIST_FOR_COLOR,
        meta: {
            async: true,
            blocking: false,
            path: `category/getCategoryColorList`,
            method: 'GET',
        }
    })

export const statusUpdate = (id, payload) =>
({
    type: types.STATUS_UPDATE_CATEGORY_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: `category/statusUpdate?id=${id}`,
        method: 'PUT',
        body: payload
    }
})




export const deleteCategory = (id, payload) =>
(
    {
        type: types.DELETE_CATEGORY_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `category/delete?id=${id}`,
            method: 'DELETE',
            body: payload,
        }
    }
)

export const updateCategory = (id, payload) => (
    {
        type: types.UPDATE_CATEGORY_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `category/update?id=${id}`,
            method: 'PUT',
            body: payload
        }
    })

export const addCategory = (payload) => ({
    type: types.ADD_CATEGORY_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: `category/add`,
        method: 'POST',
        body: payload
    }
})

// Define action creators
export const setCategoryFilter = (payload) => ({
    type: types.SET_SELECTED_CATEGORY,
    payload: payload,
});