/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import SearchBox from "../../components/searchBox/searchBox";
import CustomButton from "../../components/customButton/customButton";
import ConfirmModal from "../../components/confirmModal/confirmModal";
import ListBusiness from "../../components/listBusiness/listBusiness";

import { showToast, hidePopup } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";
import { Scrollbars } from 'react-custom-scrollbars-2';

import "./customBusinessListing.scss";
import BusinessDetails from "../../components/businessDetails/businessDetails";
import ModalCustom from "../../components/modalCustom/modalCustom";
import {
  getBusinessList,
  statusUpdate,
  deleteBusiness,
} from "../../state/ducks/businessListDetails/actions";
import CustomAddBusiness from "../../components/customAddBusiness/customAddBusiness";
import { getCategoryList } from "../../state/ducks/categoryDetails/action";
import CustomSideBar from "../../components/customSideBar/customSideBar";
import { useTranslation } from "react-i18next";
import Constant from "../../util/constant";
import Loading from "../loading/loading";

const CustomBusinessListing = (props) => {
  let {
    color,
    id,
    title,
    onRefresh,
    mainItem,
    showBusinessDetailsModal,
    setShowBusinessDetailsModal,
  } = props;

  const dimensions = useWindowDimension();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const limit = 10

  const [headerHeight, SetHeaderHeight] = useState("");
  const [pageTitleHeight, SetPageTitleHeight] = useState("");
  const [searchHeight, SetSearchHeight] = useState("");
  const [sidebarHeaderHeight, SetSidebarHeaderHeight] = useState("");
  const [sidebarActionHeight, SetSidebarActionHeight] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showBusinessDeleteModal, setShowBusinessDeleteModal] = useState(false);
  const [businessSearchData, setBusinessSearchData] = useState([]);
  const [businessCount, setBusinessCount] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [serviceEventsCount, setServiceEventsCount] = useState(0);
  const [selectedListItem, setSelectedListItem] = useState("");
  const [businessID, setBusinessId] = useState();
  const [businessStatus, setBusinessStatus] = useState();
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [busy, setBusy] = useState(false);
  const [showcategroyModal, setShowCategroyModal] = useState({
    show: false,
    type: "",
  });
  let [selectedIndex, setSelectedIndex] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [scrollApiCalling, setScrollApiCalling] = useState(true);

  const scrollBar = useRef();

  const CLOUDINARY_URL =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER;

  /**
   * UseEffect hook that sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("header")[0].offsetHeight);
    SetPageTitleHeight(
      document.getElementsByClassName("business_title")[0].offsetHeight
    );
    SetSearchHeight(
      document.getElementsByClassName("search_row")[0].offsetHeight
    );

    SetSidebarHeaderHeight(
      document.getElementsByClassName("headerBar")[0].offsetHeight
    );
    SetSidebarActionHeight(
      document.getElementsByClassName("action_header")[0].offsetHeight
    );
  }, []);

  /**
   * Executes a side effect when the value of `id` changes.
   * Sets the value of `page` to 1.
   * @returns None
   */
  useEffect(() => {
    setPage(1)
  }, [id])

  useEffect(() => {
    if (reload) {
      setPage(1)
      setSearchValue('')
      if (page === 1 && searchValue === '') getBusinessListData(searchValue, page);
      if (selectedIndex > 9) setSelectedIndex(0)
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    getBusinessListData(searchValue, page);
  }, [id, page, searchValue, mainItem.status]);

  /**
   * Runs the `onRefresh` function whenever the `businessCount` value changes.
   * @returns None
   */
  useEffect(() => {
    onRefresh();
  }, [businessCount]);

  /**
   * Retrieves business list data from the server based on the provided search criteria and page number.
   * @param {Object} searchData - The search criteria for filtering the business list.
   * @param {number} pageNumber - The page number to retrieve.
   * @returns None
   * @throws Error if there is an issue retrieving the data from the server.
   */
  const getBusinessListData = async (searchData, pageNumber) => {
    // console.log("getBusinessListData called", pageNumber, searchData);
    setScrollApiCalling(false);
    if (pageNumber === 1) setBusy(true)
    try {
      const res = await dispatch(getBusinessList(id, pageNumber, limit, searchData))
      // console.log("business res ==>", res);
      if (res.payload) {
        setBusinessCount(res?.payload?.businessCount);

        if (pageNumber == 1) {
          setBusinessSearchData(res.payload.businessList);
          // Scroll to the top if page is 1
          if (scrollBar.current) scrollBar.current.scrollToTop()
          setBusy(false)
        } else {
          setBusinessSearchData((prev) => [...prev, ...res.payload.businessList]);
        }

        if (res?.payload?.businessList?.length > 0) setScrollApiCalling(true);
        if (res?.payload?.businessCount === 0) onRefresh();
      } else {
        setScrollApiCalling(true);
        setPage(1)
        setReload(true)
      }
    } catch (error) {
      console.log("getBusinessListData ==> ", error);
      setBusy(false)
      setReload(true)
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Converts a phrase to title case.
   * @param {string} phrase - The phrase to convert to title case.
   * @returns {string} The phrase converted to title case.
   */
  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  /**
   * Updates the status of a business asynchronously.
   * @param {string} id - The ID of the business.
   * @param {boolean} status - The new status of the business. True for enabled, false for disabled.
   * @returns None
   */
  const businessStausChange = async (id, status) => {
    let params = {
      status: status ? Constant.STATUS.ENABLE : Constant.STATUS.DISABLE,
    };
    try {
      const res = await dispatch(statusUpdate(id, params))
      setShowDisableModal(!showDisableModal);
      setReload(true);

      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      console.log("error => ", error);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Deletes business data by dispatching a deleteBusiness action and updating the side menu.
   * @returns None
   * @throws {Error} If an error occurs during the deletion process.
   */
  const deleteBusinessData = async () => {
    try {
      const res = await dispatch(deleteBusiness(businessID))
      setReload(true);
      props.callback && props.callback();
      setShowBusinessDeleteModal(!showBusinessDeleteModal);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      console.log("error => ", error);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  const showModelDescription = (type, serviceEventsCount, serviceName) => {
    switch (type) {
      case 'disable':
        if (serviceEventsCount == 0) {
          return <p>{t("Are_you_sure_you_want_to_disable")} "{serviceName}"?</p>;
        } else {
          return (
            <p>{serviceEventsCount === 1 ? t("There_is") : t("There_are")} <span className="ColorRed">{serviceEventsCount} {serviceEventsCount > 1 ? t("events") : t("event")}</span> {t("associated_with")} "{serviceName}". {t("Are_you_sure_you_want_to_disable")}?</p>
          );
        }
      case 'delete':
        if (serviceEventsCount == 0) {
          return <p>{t("Are_you_sure_you_want_to_delete")} "{serviceName}"?</p>;
        } else {
          return (
            <p>{serviceEventsCount === 1 ? t("There_is") : t("There_are")} <span className="ColorRed">{serviceEventsCount} {serviceEventsCount > 1 ? t("events") : t("event")}</span> {t("associated_with")} "{serviceName}". {t("Are_you_sure_you_want_to_delete")}?</p>
          );
        }
      default:
        break;
    }
  }

  return (
    <div className="CustomBusinessListing">
      <div className="business_container">
        <div className="business_title">
          {t("Service_Listing")}<span className="line_border">|</span>
          <span className="category_name" style={{ color: color }}>
            {title}
          </span>
        </div>
        <div className="search_row">
          <SearchBox
            placeholder={t("Service Name, Location")}
            value={searchValue}
            onSearch={(value) => {
              setSearchValue(value);
              setPage(1);
            }}
            onClear={() => {
              setSearchValue("");
              setPage(1);
            }}
          />
          <div className="right_btn_business">
            <div className="total_business_row">
              <div className="count_title">{businessCount > 1 ? t("TOTAL_SERVICES") : t("TOTAL_SERVICE")}</div>
              <div className="count_number">{businessCount}</div>
            </div>
            <CustomButton
              onClick={() => setShowCategroyModal({ show: true, type: "Add" })}
              className="outline_btn"
              title={`+ ${t("ADD_SERVICE")}`}
            />
          </div>
        </div>
        <div className="data_list">
          {busy ?
            <div className="loader">
              <Loading show={busy} type="spin" color="#073180" className="adminPanel" />
            </div> :
            <Scrollbars
              style={{
                height:
                  dimensions.height -
                  headerHeight -
                  pageTitleHeight -
                  searchHeight,
              }}
              onScroll={(e) => {
                const { scrollTop, scrollHeight, clientHeight } = scrollBar.current.getValues();
                if (scrollApiCalling && scrollTop + clientHeight >= scrollHeight - 10) {
                  setPage((prevPage) => prevPage + 1);
                }
              }}
              ref={scrollBar}
            >
              {businessSearchData?.length === 0 ? (
                <div className="no_business_row">
                  <img
                    className="no_categroy_logo"
                    src={CLOUDINARY_URL + "no-categroy.png"}
                    alt="no-categroy.png"
                  />
                  <div className="no_categroy_txt">
                    {t("The_entered_service_is_not_found_Try_something_else")}
                  </div>
                </div>
              ) : (
                businessSearchData?.map((item, index) => {
                  return (
                    <ListBusiness
                      key={index.toString()}
                      className={item.status === Constant.STATUS.ENABLE ? "" : " disable"}
                      modal_dis={item.modal_dis}
                      onClickDelete={() => {
                        setShowDeleteModal(!showDeleteModal);
                        setBusinessId(item._id);
                        setServiceName(item.name);
                        setServiceEventsCount(item.relatedEventsCount)
                      }}
                      onListItemClick={() => {
                        dispatch(hidePopup());
                        setSelectedIndex(index);
                        // setShowBusinessDetailsModal(true);
                        item.status === Constant.STATUS.ENABLE && setShowBusinessDetailsModal(true)
                        setSelectedListItem(item);
                        setBusinessId(item._id);
                        setBusinessStatus(item.status);
                      }}
                      onClickEdit={() => {
                        setShowCategroyModal({ show: true, type: "Edit" });
                      }}
                      SortName={item.name.charAt(0).toUpperCase()}
                      title={toTitleCase(item.name)}
                      selected={index == selectedIndex}
                      color={item.color}
                      location={item.address}
                      onlineStatus={item.onlineStatus}
                      checked={item.status === Constant.STATUS.ENABLE}
                      onCheckChange={(checkedStatus) => {
                        setBusinessId(item._id);
                        setBusinessStatus(checkedStatus);
                        setServiceName(item.name);
                        setServiceEventsCount(item.relatedEventsCount)
                        // setBusinessCount(item.businessCount)
                        setShowDisableModal(!showDisableModal);
                        if (checkedStatus) {
                          businessStausChange(item._id, checkedStatus);
                        }
                      }}
                    />
                  );
                })
              )}
            </Scrollbars>
          }
        </div>
      </div>
      <ConfirmModal
        modalTitle={t("DELETE_TITLE")}
        btnHide={false}
        leftBtnTitle={t("YES_DELETE")}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onProcced={() => {
          setShowDeleteModal(false);
          deleteBusinessData();
          setShowBusinessDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        className={"confirm-delete"}
      >
        {showModelDescription('delete', serviceEventsCount, serviceName)}
      </ConfirmModal>

      <ConfirmModal
        modalTitle={t(
          `${businessStatus === Constant.STATUS.DISABLE ? "ENABLE_TITLE" : "DISABLE_TITLE"}`
        )}
        btnHide={false}
        leftBtnTitle={t(
          `${businessStatus === Constant.STATUS.DISABLE ? "YES_ENABLE" : "YES_DESABLE"}`
        )}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showDisableModal && !businessStatus}
        onHide={() => {
          setShowDisableModal(false);
        }}
        onProcced={() => {
          businessStausChange(businessID, businessStatus);
          setSelectedListItem({ ...selectedListItem, status: Constant.STATUS.DISABLE });
          setShowBusinessDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow')
        }}
        className={"confirm-disable"}
      >
        {showModelDescription('disable', serviceEventsCount, serviceName)}
      </ConfirmModal>

      <CustomSideBar
        title={t("Service_Details")}
        closeIcon={true}
        showSidebar={showBusinessDetailsModal}
        onHide={() => {
          setShowBusinessDetailsModal(false);
          // document.body.classList.remove('rightSidebarshow') 
        }}
        onClickDelete={() => {
          setShowDeleteModal(true);
          setBusinessStatus(selectedListItem?.status);
          setServiceName(selectedListItem?.name);
          setServiceEventsCount(selectedListItem?.relatedEventsCount)

        }}
        checked={selectedListItem?.status === Constant.STATUS.ENABLE}
        actionHeader={true}
        onCheckChange={(checkedStatus) => {
          setServiceName(selectedListItem?.name);
          setServiceEventsCount(selectedListItem?.relatedEventsCount)
          if (checkedStatus === true) {
            setBusinessStatus(checkedStatus);
            setSelectedListItem({ ...selectedListItem, status: Constant.STATUS.DISABLE });
            businessStausChange(selectedListItem?._id, checkedStatus);
          } else if (checkedStatus === false) {
            setBusinessStatus(checkedStatus);
            setShowDisableModal(true);
          }
        }}
        className="business_details"
      >
        <Scrollbars
          style={{
            height:
              dimensions.height - sidebarHeaderHeight - sidebarActionHeight,
          }}
        >
          <BusinessDetails
            color={color}
            businessID={businessID}
            onModalClose={() => setShowBusinessDetailsModal(false)}
            onRefreshBusinessList={() => {
              // console.log("onRefreshBusinessList  called")
              setPage(1)
              if (page === 1) getBusinessListData(searchValue, 1);
              if (selectedIndex > 9) {
                setShowBusinessDetailsModal(false)
                setSelectedIndex(0)
              }
            }}
            onRefreshCustomLeftPart={() => {
              props.callback && props.callback()
              setShowBusinessDetailsModal(false)
              setSelectedIndex(0)
            }}
          />
        </Scrollbars>
      </CustomSideBar>
      <ModalCustom
        showModal={showcategroyModal.show}
        onHide={() => setShowCategroyModal({ show: false, type: "" })}
        className={"add_business_modal"}
      >
        <CustomAddBusiness
          onClickCancel={() => setShowCategroyModal({ show: false, type: "" })}
          count={businessCount}
          item={mainItem}
          onReload={() => {
            // console.log("reload");
            props.callback()
            setReload(true)
          }
          }
        />
      </ModalCustom>
    </div>
  );
};

export default CustomBusinessListing;
