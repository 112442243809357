/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { get } from "lodash";
import setHours from "date-fns/setHours";
import "react-phone-input-2/lib/style.css";
import HookForm from "../hookForm/hookForm";
import setMinutes from "date-fns/setMinutes";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Constant from "../../util/constant";
import CheckBox from "../checkbox/checkBox";
import TextField from "../textField/textField";
import TimePicker from "../timePicker/timePicker";
import CustomButton from "../customButton/customButton";
import {
  updateBusiness,
  getServiceDetails,
} from "../../state/ducks/businessListDetails/actions";
import CustomDropdown from "../customDropdown/customDropdown";
import { showToast } from "../../state/ducks/utils/operations";
import CustomToggleButton from "../customToggleButton/customToggleButton";
import TextFieldNoRequired from "../textFieldNoRequired/textFieldNoRequired";
import GooglePlaceDropDown from "../googlePlaceDropDown/googlePlaceDropDown";
import "./businessDetails.scss";
import { Link } from "react-router-dom";
import CustomeSelectBox from "../customeSelectBox/customeSelectBox";

const BusinessDetails = (props) => {
  let { color, businessData } = props;

  const [form, setLoginForm] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [dayMe, setDayMe] = useState({});
  const [busy, setBusy] = useState(false);
  const [endTime, setEndTime] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [startTime, setStartTime] = useState([]);
  const [dayListing, setDayListing] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [portLocation, setPortLocation] = useState(null);
  const [characterCount, setcharacterCount] = useState(0);
  const [eventDetailsData, setEventDetailsData] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [categoryID, setCategoryId] = useState(businessData?._id);
  const [businessInfoEdit, setBusinessInfoEdit] = useState(false);
  const [businessTimingEdit, setBusinessTimingEdit] = useState(false);
  const [contactInformationEdit, setContactInformationEdit] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');

  const townList = useSelector(state => state?.townDetails?.townList?.payload)

  const colorList = useSelector(
    (state) => state?.CategoryDetails?.getCategoryListForColor?.categoryList
  );

  /**
   * useEffect hook that runs when the value of props.businessID changes.
   * It calls the getBusinessData function with the new businessID value.
   * It also sets the state variables businessInfoEdit, businessTimingEdit,
   * contactInformationEdit, and locationEdit to false.
   * @param {string} props.businessID - The ID of the business.
   * @returns None
   */
  useEffect(() => {
    props.businessID && getBusinessData(props.businessID);
    setBusinessInfoEdit(false);
    setBusinessTimingEdit(false);
    setContactInformationEdit(false);
  }, [props.businessID]);

  /**
   * Retrieves business data for a given ID asynchronously.
   * @param {number} id - The ID of the business to retrieve data for.
   * @returns None
   */
  const getBusinessData = async (id) => {
    try {
      setBusy(true);
      const res = await dispatch(getServiceDetails(id))
      setEventDetailsData(res?.payload?.business[0]?.RelatedEvents);
      let displayTime = res?.payload?.business[0]?.availableDayTime;

      let newDisplayTime = [];
      let isDisableItems = [];

      displayTime.map((item, index) => {
        item["startTime"] = moment(
          `${moment().format("yyyy/MM/DD")} ${item.startTime}`
        ).format("HH:mm");
        item["endTime"] = moment(
          `${moment().format("yyyy/MM/DD")} ${item.endTime}`
        ).format("HH:mm");
        var objKey = `wholeday${index}`;

        var newObj = {};
        newObj[objKey] = item.isDisable;
        isDisableItems.push(newObj);
        newDisplayTime.push(item);
      });
      setDayListing(newDisplayTime);
      setOnlineStatus(res?.payload?.business[0]?.onlineStatus)
      setDayMe(isDisableItems);

      if (res?.payload?.business[0]?.location) {
        let longitude = res?.payload?.business[0]?.location?.coordinates[0];
        setLongitude(longitude);

        let latitude = res?.payload?.business[0]?.location?.coordinates[1];
        setLatitude(latitude);
        try {
          let locationAddress = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
          );
          // console.log("locationAddress", locationAddress);
          // setCurrentAddress(locationAddress?.data?.results[0]?.formatted_address);
          setCurrentAddress({
            label: locationAddress?.data?.results[0]?.formatted_address,
            value: { ...locationAddress?.data?.results[0] }
          });
        } catch (error) {
        }
      } else {
        setLongitude()
        setLatitude()
        setCurrentAddress()
      }

      setBusy(false);
      setSelectedItemData(res?.payload?.business[0]);
      if (res?.payload?.business[0]?.description?.length > 550) {
        setcharacterCount(550);
      } else {
        setcharacterCount(res?.payload?.business[0]?.description?.length);
      }

      let data = {
        color: res?.payload?.categoryColor,
        id: res?.payload?.business[0].categoryId,
        categoryName: res?.payload?.categoryName,
      };
      setSelectedItem(data);
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Maps over a colorList array and creates a new array of color objects with the specified properties.
   * @param {Array} colorList - The array of color items to map over.
   * @returns {Array} - A new array of color objects with the properties id, value, and color.
   */
  let name = colorList?.map((item) => {
    let colorObj = {
      id: item._id,
      value: item.categoryName,
      color: item.color,
    };
    return colorObj;
  });

  /**
   * An object representing a business information form with various fields and their validation rules.
   * @property {object} businessName - The field for the business name.
   * @property {string} businessName.name - The name of the field.
   * @property {object} businessName.validate - The validation rules for the field.
   * @property {object} businessName.validate.required - The required validation rule for the field.
   * @property {boolean} businessName.validate.required.value - The value indicating if the field is required.
   * @property {string} businessName.validate.required.message - The error message to display if the field is required.
   * @property {object} businessName.validate.pattern - The pattern validation rule for the field.
   * @
   */
  const businessInformationForm = {
    businessName: {
      name: "businessName",
      validate: {
        required: {
          value: true,
          message: t("BUSINESS_NAME_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: t("ONLY_ALPHABETS_NUMBER_ACCENTS"),
        },
      },
    },
    categroy: {
      name: "categroy",
      validate: {
        required: {
          value: false,
          message: t("required"),
        },
      },
    },
    town: {
      name: "towns",
      validate: {
        required: {
          value: true,
          message: t("TOWN_REQUIRED"),
        },
      },
    },
    Livelink: {
      name: "Livelink",
      validate: {
        required: {
          value: true,
          message: t("BUSINESS_LIVE_LINK_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    online: {
      name: "online",
      validate: {
        required: {
          value: false,
        },
      },
    },
    descripton: {
      name: "descripton",
      validate: {
        required: {
          value: true,
          message: t("DESCRIPTION_REQUIRED"),
        },
      },
    },
  };

  /**
   * An object representing a location form with address and portLocation fields.
   * Each field has a name and validation rules.
   * @property {object} address - The address field.
   * @property {string} address.name - The name of the address field.
   * @property {object} address.validate - The validation rules for the address field.
   * @property {boolean} address.validate.required.value - Indicates if the address field is required.
   * @property {string} address.validate.required.message - The error message to display if the address field is required.
   * @property {object} portLocation - The portLocation field.
   * @property {string} portLocation.name - The name of the portLocation field.
   * @property {object}
   */
  const locationForm = {
    address: {
      name: "address",
      validate: {
        required: {
          value: !onlineStatus,
          message: t("ADDRESS_REQUIRED"),
        },
      },
    },
    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: false,
          message: t("ADDRESS_REQUIRED"),
        },
      },
    },
  };

  /**
   * An object representing a business timing form.
   * @property {Object} starttime - The start time of the business timing.
   * @property {string} starttime.name - The name of the start time field.
   * @property {Object} starttime.validate - The validation rules for the start time field.
   * @property {boolean} starttime.validate.required - Indicates if the start time field is required.
   * @property {Object} endtime - The end time of the business timing.
   * @property {string} endtime.name - The name of the end time field.
   * @property {Object} endtime.validate - The validation rules for the end time field.
   * @property {boolean} endtime.validate.required - Indicates if the end time
   */
  const businessTimingForm = {
    starttime: {
      name: "starttime",
      validate: {
        required: {
          value: false,
        },
      },
    },
    endtime: {
      name: "endtime",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };

  /**
   * An object representing a contact information form with various fields and their validation rules.
   * @property {object} email - The email field with its validation rules.
   * @property {string} email.name - The name of the email field.
   * @property {object} email.validate - The validation rules for the email field.
   * @property {object} email.validate.required - The required validation rule for the email field.
   * @property {boolean} email.validate.required.value - Whether the email field is required or not.
   * @property {string} email.validate.required.message - The error message to display if the email field is required.
   * @property {object} email.validate.pattern - The pattern validation rule for the email field.
   * @property
   */
  const contactInformationForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: false,
          message: t("EMAIL_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("EMAIL_INVALID"),
        },
      },
    },
    cunrtyCode: {
      name: "cunrtyCode",
      validate: {},
    },
    phoneNumber: {
      name: "phoneNumber",
      validate: {
        required: {
          value: false,
          message: t("PHONE_NUMBER_REQUIRE"),
        },
        maxLength: { value: 11, message: t("PHONE_NUMBER_INVALID") },
        minLength: { value: 10, message: t("PHONE_NUMBER_INVALID") },
      },
    },
    website: {
      name: "website",
      validate: {
        required: {
          value: false,
          message: t("WEBSITE_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    facebookLink: {
      name: "facebookLink",
      validate: {
        required: {
          value: false,
          message: t("FACEBOOK_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    twitterLink: {
      name: "twitterLink",
      validate: {
        required: {
          value: false,
          message: t("TWITTER_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    instagramLink: {
      name: "instagramLink",
      validate: {
        required: {
          value: false,
          message: t("INSTAGRAM_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
    linkedinLink: {
      name: "linkedinLink",
      validate: {
        required: {
          value: false,
          message: t("LINKEDIN_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: t("URL_INVALID"),
        },
      },
    },
  };

  /**
   * Handles the submission of the business information edit form.
   * @param {Event} e - The event object representing the form submission.
   * @returns None
   */
  const onFormBusinessInfoEditSubmit = async (e) => {
    let params = {
      name: e.businessName,
      categoryId: e.categroy?.id,
      description: e.descripton,
      onlineStatus: e.online,
      towns: e.towns?.map(item => item.value)
    };

    if (e.online) params.liveLink = e.Livelink
    if (e.address) params.address = e.address
    if (e?.portLocation?.location?.long || longitude) {
      params.longitude = e.portLocation ? e?.portLocation?.location?.long : longitude
    }
    if (e?.portLocation?.location?.lat || latitude) {
      params.latitude = e.portLocation ? e?.portLocation?.location?.lat : latitude
    }
    // console.log("edit params ==>", params);
    let businessID = props?.businessID;
    try {
      setBusy(true);
      const res = await dispatch(updateBusiness(businessID, params))
      getBusinessData(businessID);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
      setBusy(false);
      setBusinessInfoEdit(false);
      props.onRefreshBusinessList();
      if (e.categroy?.id !== selectedItemData.categoryId) props.onRefreshCustomLeftPart();

    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Handles the submission of the business timing form.
   * @param {Event} e - The event object.
   * @returns None
   */
  const onFormBusinessTimingSubmit = async (e) => {
    let data = [];
    dayListing.map((item, index) => {
      if (item.endTime === "--:--") {
        item["endTime"] = "23:59";
      }
      item["startTime"] = item.isDisable
        ? "00:00"
        : moment(`${moment().format("yyyy/MM/DD")}  ${item.startTime}`).format(
          "HH:mm"
        );
      item["endTime"] = item.isDisable
        ? "23:59"
        : moment(`${moment().format("yyyy/MM/DD")} ${item.endTime}`).format(
          "HH:mm"
        );
      data.push(item);
    });

    let params = {
      availableDayTime: dayListing,
    };
    let businessID = props?.businessID;

    try {
      setBusy(true);
      await dispatch(updateBusiness(businessID, params)).then((res) => {
        let displayTime = res?.payload?.availableDayTime;
        setDayListing(displayTime);
        props.onRefreshBusinessList();

        setBusinessTimingEdit(false);
        dispatch(
          showToast({
            message: res.message,
            type: "success",
          })
        );
        setBusy(false);
      });
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Handles the form submission event and updates the business information.
   * @param {Event} e - The form submission event.
   * @returns None
   */
  const onFormSubmit = async (e) => {
    let url = "https://";
    let facebook = "";
    let instagram = "";
    let twitter = "";
    let linked = "";
    let websiteData = "";

    if (e.facebookLink !== "undefined" && e.facebookLink !== "") {
      facebook =
        e?.facebookLink?.startsWith("http" || "https") === false
          ? url.concat(e?.facebookLink)
          : e?.facebookLink;
    }
    if (e.instagramLink !== "undefined" && e.instagramLink !== "") {
      instagram =
        e?.instagramLink?.startsWith("http" || "https") === false
          ? url.concat(e?.instagramLink)
          : e?.instagramLink;
    }
    if (e.twitterLink !== "undefined" && e.twitterLink !== "") {
      twitter =
        e?.twitterLink?.startsWith("http" || "https") === false
          ? url.concat(e?.twitterLink)
          : e?.twitterLink;
    }
    if (e.linkedinLink !== "undefined" && e.linkedinLink !== "") {
      linked =
        e?.linkedinLink?.startsWith("http" || "https") === false
          ? url.concat(e?.linkedinLink)
          : e?.linkedinLink;
    }
    if (e.website !== "undefined" && e.website !== "") {
      websiteData =
        e?.website?.startsWith("http" || "https") === false
          ? url.concat(e?.website)
          : e?.website;
    }

    let params = {
      email: e.email,
      phoneNumber: e.phoneNumber,
      websiteLink: websiteData,
      facebookLink: facebook,
      instagramLink: instagram,
      twitterLink: twitter,
      linkedLink: linked,
    };
    let businessID = props?.businessID;
    try {
      setBusy(true);
      const res = await dispatch(updateBusiness(selectedItemData._id, params))
      getBusinessData(businessID);
      setContactInformationEdit(false);
      setBusy(false);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (err) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(err, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Renders a custom start time button component.
   * @param {string} value - The value of the start time.
   * @param {function} onClick - The function to be called when the button is clicked.
   * @returns {JSX.Element} - The rendered custom start time button component.
   */
  const StartTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">-- : --</span>
        )}
      </button>
    );
  };

  /**
   * Renders a button component for displaying an end time value.
   * @param {Object} props - The component props.
   * @param {string} props.value - The value of the end time.
   * @param {function} props.onClick - The function to be called when the button is clicked.
   * @returns A button component with the end time value or a placeholder if no value is provided.
   */
  const EndTime = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-time-input" onClick={onClick}>
        {value ? (
          value
        ) : (
          <span className="custom-date-placeholder">-- : --</span>
        )}
      </button>
    );
  };

  /**
   * Updates the start time for a specific index in the times array.
   * @param {Array} times - The array of start times.
   * @param {number} index - The index of the start time to update.
   * @returns None
   */
  const onChangeStartTime = (times, index) => {
    let temp_time = startTime;
    temp_time[index] = times;
    setStartTime(temp_time);
  };

  /**
   * Updates the end time value at the specified index in the `endTime` array.
   * @param {any[]} times - The new end time value to set.
   * @param {number} index - The index of the `endTime` array to update.
   * @returns None
   */
  const onChangeEndTime = (times, index) => {
    let temp_time = endTime;
    temp_time[index] = times;
    setEndTime(temp_time);
  };

  /**
   * Displays the time data based on the given data object.
   * @param {object} data - The time data object.
   * @returns {JSX.Element} - The JSX element representing the time data.
   */
  const displayTimeData = (data) => {
    if (data.isDayActive) {
      if (data.is24Hours) {
        return <p>{`24 hours available`}</p>;
      } else {
        return (
          <p>
            {data.startTime} - {data.endTime}{" "}
          </p>
        );
      }
    } else {
      return <p>{`closed`}</p>;
    }
  };

  /**
   * Truncates the given data if its length exceeds 50 characters.
   * @param {string} data - The data to truncate.
   * @returns {string} - The truncated data.
   */
  const truncateData = (data) => {
    let data1 = "max@mailinatormailinatormailinatormailinatormailin...";
    return data.length > 50 ? data.substring(0, 50) + "..." : data;
  };

  return (
    <div className="BusinessDetails">
      {!businessInfoEdit ? (
        <div className="first_row">
          <div className="sort_name">
            <span
              className="full_bg"
              style={{
                backgroundColor: color,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: 30,
              }}
            >
              {selectedItemData?.name?.charAt(0).toUpperCase()}
            </span>
          </div>
          <div className="contain_row">
            <div className="left_contain">
              <span className="TagBothWrapper">
                <div className="TagWrapper">
                  <div className="list_name">{selectedItemData?.name}</div>
                  {selectedItemData?.onlineStatus && <span className="tagWrapper sucess-bg">{t("ONLINE")}</span>}
                </div>
                <div className="list_discription">
                  {selectedItemData?.description}
                </div>
              </span>
              {selectedItemData?.onlineStatus && <div className="liveViewWrapper">
                <span className="liveservericon">
                  <span class="icon-server"></span>
                </span>
                <Link to={selectedItemData?.liveLink} target="blank" className="liveservertext">
                  {t("Live_View")}{" "}
                </Link>
              </div>}
              <div className="townWrapper">
                <div className="list_name">{t("Town")}</div>
                <ul>
                  {selectedItemData?.towns?.length > 0
                    ? selectedItemData?.towns?.map((item, i) => <li key={i}>{item}</li>)
                    : <li>No Towns Selected</li>}
                </ul>
              </div>
            </div>
          </div>
          {(selectedItemData.address || selectedItemData.location || !selectedItemData.onlineStatus) && <div className="title_button_row">
            <div className="field_name">{t("LOCATION")}</div>
          </div>}
          {(selectedItemData.address || !selectedItemData.onlineStatus) &&
            <div className="address_text address_input">
              {/* <span className="icon-pin-outline"></span> */}
              {selectedItemData?.address}
            </div>
          }
          {(selectedItemData.location || !selectedItemData.onlineStatus) &&
            <div className="lacation-wrapper">
              <div className="address_text address_input lacation-name-style">
                {/* <span className="icon-pin-outline"></span> */}
                {/* {selectedItemData?.address} */}
                {currentAddress?.label}
              </div>
              <div className="map_view">
                <iframe
                  title={t("map")}
                  className="map"
                  src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                    }
                &q=${get(
                      portLocation,
                      "location.latitude",
                      latitude ? latitude : 1
                    )},
                ${get(
                      portLocation,
                      "location.longitude",
                      longitude ? longitude : 1
                    )}`}
                  width="100%"
                  height="330px"
                  frameBorder="0"
                  allowFullScreen={false}
                  aria-hidden={false}
                  tabIndex="0"
                />
              </div>
            </div>
          }
          <div className="EditServices">
            <button
              onClick={() => setBusinessInfoEdit(true)}
              className="icon-pencil"
            ></button>
          </div>
        </div>
      ) : (
        <div className="edit_row">
          <HookForm
            defaultForm={{}}
            init={(form) => setLoginForm(form)}
            onSubmit={(e) => onFormBusinessInfoEditSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="first_row">
                    <div className="title_button_row">
                      <div className="field_name">
                        {t("SERVICE_INFORMATION")}
                      </div>
                      <div className="custombtnfield">
                        <CustomButton
                          type="submit"
                          title={t("UPDATE")}
                          disabled={!formMethod?.formState.isValid || (!onlineStatus && !currentAddress)}
                          loading={busy}
                        />
                        <CustomButton
                          onClick={() => setBusinessInfoEdit(false)}
                          type="button"
                          className="outline_btn"
                          title={t("CANCEL")}
                        />
                      </div>
                    </div>
                    <div className="padding_inner">
                      <TextField
                        formMethod={formMethod}
                        rules={businessInformationForm.businessName.validate}
                        name={businessInformationForm.businessName.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        defaultValue={selectedItemData?.name}
                        autoComplete="on"
                        placeholder={t("Service_name")}
                        textFieldClassName="fullTextBox"
                        maxLength={100}
                      />
                      <div className="input_row">
                        <div className="colorDropdownWrapper">
                          <span
                            className="cricle_select_view"
                            style={{ backgroundColor: selectedItem?.color }}
                          ></span>
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <CustomDropdown
                                dropDownItems={name}
                                placeholder={t("SELECT_CATEGORY")}
                                selectedValue={selectedItem}
                                defaultValue={selectedItem}
                                onSelect={(evt) => {
                                  onChange(evt);
                                  const data = {
                                    color: evt.color,
                                    id: evt.id,
                                    categoryName: evt.value,
                                  };
                                  setSelectedItem(data);
                                  setCategoryId(evt.id);
                                }}
                              >
                                <span
                                  className="cricle_view"
                                  style={{ backgroundColor: color }}
                                ></span>
                              </CustomDropdown>
                            )}
                            control={formMethod.control}
                            defaultValue={selectedItem}
                            name={businessInformationForm.categroy.name}
                            rules={businessInformationForm.categroy.validate}
                          />
                          <span className="arrow-dropdown icon-sorting"></span>
                        </div>
                      </div>
                      <div className="input_row">
                        <div className="townWrapper">
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <>
                                <CustomeSelectBox
                                  placeholder={
                                    <>
                                      {t("SELECT_TOWN")}
                                      <span style={{ color: 'red' }}>*</span>
                                    </>
                                  }
                                  defaultValue={selectedItemData.towns?.map(item => ({ label: item, value: item }))}
                                  options={townList || []}
                                  isMulti={true}
                                  className={
                                    value?.length > 1 &&
                                    "multipleSelectBox"
                                  }
                                  onChange={(evt) => {
                                    onChange(evt);
                                  }}
                                />
                              </>
                            )}
                            defaultValue={selectedItemData.towns?.map(item => ({ label: item, value: item }))}
                            control={formMethod.control}
                            name={businessInformationForm.town.name}
                            rules={businessInformationForm.town.validate}
                            errors={formMethod?.formState.errors}
                          />
                          <span className="arrow-dropdown icon-sorting"></span>
                        </div>
                      </div>
                      <div className="input_row">
                        <div className="onlineWrapper">
                          <Controller
                            defaultValue={selectedItemData.onlineStatus}
                            // defaultValue=""
                            control={formMethod?.control}
                            name={businessInformationForm.online.name}
                            rules={businessInformationForm.online.validate}
                            render={({ field: { onChange, value } }) => (
                              <div className="OnlineSwitchWrapper">
                                <p>{t("ONLINE")}</p>
                                <CustomToggleButton
                                  id=""
                                  checked={value}
                                  onCheckChange={(e) => { onChange(e); setOnlineStatus(e); }}
                                />
                              </div>
                            )}
                          />
                          {formMethod.getValues("online") &&
                            <TextField
                              formMethod={formMethod}
                              rules={businessInformationForm.Livelink.validate}
                              name={businessInformationForm.Livelink.name}
                              defaultValue={selectedItemData?.liveLink}
                              errors={formMethod?.formState.errors}
                              // autoFocus={true}
                              type="text"
                              textFieldClassName="fullTextBox"
                              autoComplete="on"
                              placeholder={t("Live_link")}
                              maxLength={250}
                            />
                          }
                        </div>
                      </div>
                      <div className="discription_txt">
                        <TextField
                          formMethod={formMethod}
                          rules={businessInformationForm.descripton.validate}
                          name={businessInformationForm.descripton.name}
                          errors={formMethod?.formState.errors}
                          onChange={(e) =>
                            setcharacterCount(e.target.value.length)
                          }
                          autoComplete="on"
                          defaultValue={
                            selectedItemData?.description.length > 550
                              ? selectedItemData.description.substring(0, 550) +
                              "..."
                              : selectedItemData?.description
                          }
                          placeholder={t("Description")}
                          textarea="textarea"
                          maxLength={550}
                        />
                        <div className="textarea_count">
                          {characterCount}/550
                        </div>
                      </div>
                      <div className="padding_inner">
                        <TextField
                          formMethod={formMethod}
                          rules={locationForm.address.validate}
                          name={locationForm.address.name}
                          errors={onlineStatus ? {} : formMethod?.formState.errors}
                          defaultValue={selectedItemData?.address}
                          type="text"
                          autoComplete="on"
                          textFieldClassName="fullTextBox"
                          placeholder={t("LOCATION")}
                          maxLength={150}
                          isRequired={onlineStatus ? false : true}
                        />
                        <div className="location_part">
                          <div>
                            <Controller
                              control={formMethod?.control}
                              name={locationForm.portLocation.name}
                              rules={locationForm.portLocation.validate}
                              errors={onlineStatus ? {} : formMethod?.formState.errors}
                              render={({ field: { onChange, value } }) => (
                                <GooglePlaceDropDown
                                  isRequired={onlineStatus ? false : true}
                                  defaultValue={currentAddress}
                                  getLocation={(place) => {
                                    if (place === null) {
                                      setPortLocation(null)
                                      setLatitude()
                                      setLongitude()
                                      setCurrentAddress()
                                    } else {
                                      setPortLocation({
                                        location: {
                                          latitude: place?.location?.lat || 1,
                                          longitude: place?.location?.long || 1,
                                          address: place.address || "",
                                        }
                                      })
                                      setCurrentAddress({
                                        label: place.address,
                                        value: { ...place }
                                      });
                                    }
                                    onChange(place);
                                  }
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="map_view">
                            <iframe
                              title={t("map")}
                              className="map"
                              src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                }
                              &q=${get(
                                  portLocation,
                                  "location.latitude",
                                  latitude ? latitude : 1
                                )},${get(
                                  portLocation,
                                  "location.longitude",
                                  longitude ? longitude : 1
                                )}`}
                              width="100%"
                              height="330px"
                              frameBorder="0"
                              allowFullScreen={false}
                              aria-hidden={false}
                              tabIndex="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}
      {!businessTimingEdit ? (
        <div className="first_row">
          <div className="title_button_row">
            <div className="field_name">{t("SERVICE_TIMING")}</div>
            <button
              onClick={() => setBusinessTimingEdit(true)}
              className="icon-pencil"
            ></button>
          </div>

          {dayListing.map((item, index) => {
            return (
              <div className="day_time_row" key={index}>
                <div className="day_text">
                  <span className="icon-calender"></span>
                  {item.day}
                </div>
                <div className="time_text">
                  <span className="icon-watch"></span>
                  {displayTimeData(item)}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="edit_row businesstiming">
          <HookForm
            defaultForm={{}}
            init={(form) => setLoginForm(form)}
            onSubmit={(e) => onFormBusinessTimingSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="first_row">
                    <div className="title_button_row">
                      <div className="field_name">{t("SERVICE_TIMING")}</div>
                      <div className="custombtnfield">
                        <CustomButton
                          type="submit"
                          title={t("UPDATE")}
                          loading={busy}
                        />
                        <CustomButton
                          onClick={() => setBusinessTimingEdit(false)}
                          type="button"
                          className="outline_btn"
                          title={t("CANCEL")}
                        />
                      </div>
                    </div>
                    <div className="padding_inner">
                      <div className="timing_heading">
                        <ul>
                          <li>{t("AVAILABLE_DAYS")}</li>
                          <li>{t("START_TIME")}</li>
                          <li>{t("END_TIME")}</li>
                          <li>{t("24_HOURS")}</li>
                        </ul>
                      </div>
                      <div className="timing_list">
                        {dayListing?.map((item, index) => {
                          return (
                            <ul key={index}>
                              <li className="list_col">
                                <div className="inputbox">
                                  <div className="day">{item.day}</div>
                                  <CustomToggleButton
                                    id={"day" + index}
                                    // checked={dayToggle["day" + index] === false ? false : true}
                                    checked={item?.isDayActive}
                                    onCheckChange={(checked) => {
                                      dayListing[index].isDayActive = checked;
                                      setDayListing([...dayListing]);
                                    }}
                                  />
                                </div>
                              </li>
                              <li className="list_col">
                                <div
                                  className={
                                    item.isDisable
                                      ? "inputbox disable"
                                      : "inputbox"
                                  }
                                >
                                  <Controller
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <TimePicker
                                        selected={startTime?.[index]}
                                        onChange={(e) => {
                                          let data = moment(e).format("HH:mm");

                                          dayListing[index].startTime = data;
                                          setDayListing([...dayListing]);
                                          onChangeStartTime(e, index);
                                          onChange(e);
                                          dayListing[index].endTime = "--:--";
                                        }}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        customInput={<StartTime />}
                                        showTimeSelect={true}
                                        showTimeSelectOnly={true}
                                        timeIntervals={15}
                                        isClearable={false}
                                        disabled={item.isDisable}
                                        minTime
                                        maxTime={setHours(
                                          setMinutes(new Date(), 30),
                                          20
                                        )}
                                        value={item?.startTime}
                                      />
                                    )}
                                    control={formMethod.control}
                                    name={businessTimingForm.starttime.name}
                                    rules={
                                      businessTimingForm.starttime.validate
                                    }
                                    defaultValue={""}
                                  />
                                </div>
                              </li>
                              <li className="list_col">
                                <div
                                  className={
                                    item.isDisable
                                      ? "inputbox disable"
                                      : "inputbox"
                                  }
                                >
                                  <Controller
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <TimePicker
                                        selected={endTime?.[index]}
                                        onChange={(e) => {
                                          let data = moment(e).format("HH:mm");

                                          dayListing[index].endTime = data;
                                          setDayListing([...dayListing]);
                                          onChangeEndTime(e, index);
                                          onChange(e);
                                        }}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        customInput={<EndTime />}
                                        showTimeSelect={true}
                                        showTimeSelectOnly={true}
                                        timeIntervals={15}
                                        isClearable={false}
                                        minTime={
                                          new Date(
                                            moment(
                                              new Date(
                                                formMethod.watch("starttime")
                                              )
                                            )
                                              .add(15, "minutes")
                                              .format()
                                          )
                                        }
                                        maxTime={setHours(
                                          setMinutes(new Date(), 59),
                                          23
                                        )}
                                        disabled={item.isDisable}
                                        value={item?.endTime}
                                      />
                                    )}
                                    control={formMethod.control}
                                    name={businessTimingForm.endtime.name}
                                    rules={businessTimingForm.endtime.validate}
                                    defaultValue={""}
                                  />
                                </div>
                              </li>
                              <li className="list_col">
                                <div className="inputbox">
                                  <CheckBox
                                    id={"wholeday" + index}
                                    checked={dayListing[index].is24Hours}
                                    onCheckedChange={(checked) => {
                                      dayListing[index]["is24Hours"] = checked;
                                      dayListing[index]["isDisable"] = checked;
                                      setDayListing([...dayListing]);
                                    }}
                                  />
                                </div>
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}
      <div className="first_row">
        <div className="title_button_row">
          <div className="field_name">Events</div>
        </div>
        {eventDetailsData?.map((item, index) => {
          let StartDate = moment(
            `${moment(item?.eventStartDate).utc().format("yyyy/MM/DD")}  ${item?.eventStartTime
            }`
          ).format("DD MMM YYYY hh:mm a");
          let endDate = item?.eventEndDate
            ? moment(
              `${moment(item?.eventEndDate).utc().format("yyyy/MM/DD")}  ${item?.eventEndTime
              }`
            ).format("DD MMM YYYY hh:mm a")
            : "--:--";
          return (
            <div key={index} className="Event-wrapper">
              <div className="sub_title">{item.name}</div>
              <div className="address_text">
                <span className="icon-pin-outline"></span>
                {item.address}
              </div>
              <div className="address_text">
                <span className="icon-calender"></span>
                {`${StartDate} - ${endDate}`}
              </div>
            </div>
          );
        })}
      </div>

      {!contactInformationEdit ? (
        <div className="first_row">
          <div className="title_button_row">
            <div className="field_name">{t("CONTACT_INFORMATION")}</div>
            <button
              onClick={() => setContactInformationEdit(true)}
              className="icon-pencil"
            ></button>
          </div>

          {selectedItemData?.email && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-email"></span>
                {truncateData(selectedItemData?.email)}
              </div>
            </div>
          )}
          {selectedItemData?.phoneNumber && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-phone"></span>
                {selectedItemData?.phoneNumber}
              </div>
            </div>
          )}
          {selectedItemData?.websiteLink && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-link"></span>
                <Link to={selectedItemData?.websiteLink} target="blank">
                  {truncateData(selectedItemData?.websiteLink)}
                </Link>
              </div>
            </div>
          )}
          {selectedItemData?.facebookLink && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-facebook"></span>
                {truncateData(selectedItemData?.facebookLink)}
              </div>
            </div>
          )}
          {selectedItemData?.twitterLink && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-twitter"></span>
                {truncateData(selectedItemData?.twitterLink)}
              </div>
            </div>
          )}
          {selectedItemData?.instagramLink && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-instagram"></span>
                {truncateData(selectedItemData?.instagramLink)}
              </div>
            </div>
          )}
          {selectedItemData?.linkedLink && (
            <div className="information_row">
              <div className="linkTxt">
                <span className="icon-linkedin"></span>
                {truncateData(selectedItemData?.linkedLink)}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="edit_row contact_information">
          <HookForm
            defaultForm={{}}
            init={(form) => setLoginForm(form)}
            onSubmit={(e) => onFormSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="first_row">
                    <div className="title_button_row">
                      <div className="field_name">
                        {t("CONTACT_INFORMATION")}
                      </div>
                      <div className="custombtnfield">
                        <CustomButton
                          type="submit"
                          title={t("UPDATE")}
                          disabled={!formMethod?.formState.isValid}
                          loading={busy}
                        />
                        <CustomButton
                          onClick={() => setContactInformationEdit(false)}
                          type="button"
                          className="outline_btn"
                          title={t("CANCEL")}
                        />
                      </div>
                    </div>
                    <div className="padding_inner">
                      <TextFieldNoRequired
                        formMethod={formMethod}
                        rules={contactInformationForm.email.validate}
                        name={contactInformationForm.email.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        autoComplete="on"
                        placeholder={t("EMAIL")}
                        defaultValue={selectedItemData?.email}
                        iconLeftShow={true}
                        textFieldClassName="fullTextBox"
                        iconClassLeft="icon-email"
                      />

                      <div className="countryPhoneGroup">
                        <Controller
                          defaultValue={"44"}
                          control={formMethod?.control}
                          name={contactInformationForm.cunrtyCode.name}
                          rules={contactInformationForm.cunrtyCode.validate}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <span className="icon-phone"></span>
                              <PhoneInput
                                country={"gb"}
                                disableSearchIcon="false"
                                placeholder={t("+44")}
                                enableSearch="true"
                                value={formMethod.watch(
                                  contactInformationForm.cunrtyCode.name
                                )}
                                onChange={onChange}
                              />
                            </>
                          )}
                        />
                        <TextFieldNoRequired
                          redStar={true}
                          noTextfield={true}
                          onChange={(e) => this.onPhoneChange(formMethod, e)}
                          maskType="99999999999"
                          formMethod={formMethod}
                          rules={contactInformationForm.phoneNumber.validate}
                          name={contactInformationForm.phoneNumber.name}
                          errors={formMethod?.formState.errors}
                          placeholder={t("PHONE_NUMBER")}
                          defaultValue={selectedItemData?.phoneNumber}
                        />
                      </div>

                      <TextFieldNoRequired
                        formMethod={formMethod}
                        rules={contactInformationForm.website.validate}
                        name={contactInformationForm.website.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        autoComplete="on"
                        placeholder={t("WEBSITE_URL")}
                        textFieldClassName="fullTextBox"
                        defaultValue={selectedItemData?.websiteLink}
                        iconLeftShow={true}
                        iconClassLeft="icon-link"
                      />

                      <TextFieldNoRequired
                        formMethod={formMethod}
                        rules={contactInformationForm.facebookLink.validate}
                        name={contactInformationForm.facebookLink.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        autoComplete="on"
                        placeholder={t("FACEBOOK_URL")}
                        textFieldClassName="fullTextBox"
                        defaultValue={selectedItemData?.facebookLink}
                        iconLeftShow={true}
                        iconClassLeft="icon-facebook"
                      />
                      <TextFieldNoRequired
                        formMethod={formMethod}
                        rules={contactInformationForm.twitterLink.validate}
                        name={contactInformationForm.twitterLink.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        autoComplete="on"
                        placeholder={t("TWITTER_URL")}
                        textFieldClassName="fullTextBox"
                        defaultValue={selectedItemData?.twitterLink}
                        iconLeftShow={true}
                        iconClassLeft="icon-twitter"
                      />
                      <TextFieldNoRequired
                        formMethod={formMethod}
                        rules={contactInformationForm.instagramLink.validate}
                        name={contactInformationForm.instagramLink.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        autoComplete="on"
                        placeholder={t("INSTAGRAM_URL")}
                        textFieldClassName="fullTextBox"
                        defaultValue={selectedItemData?.instagramLink}
                        iconLeftShow={true}
                        iconClassLeft="icon-instagram"
                      />
                      <TextFieldNoRequired
                        formMethod={formMethod}
                        rules={contactInformationForm.linkedinLink.validate}
                        name={contactInformationForm.linkedinLink.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        autoComplete="on"
                        placeholder={t("LINKEDIN_URL")}
                        textFieldClassName="fullTextBox"
                        defaultValue={selectedItemData?.linkedLink}
                        iconLeftShow={true}
                        iconClassLeft="icon-linkedin"
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}
    </div>
  );
};

export default BusinessDetails;
