import React, { useEffect, useState } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import "./viewDetails.scss";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link } from "react-router-dom";

const ViewDetails = (props) => {
  const { t } = useTranslation();
  let showList = props?.mapData;
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [serviceDetail, setServiceDetail] = useState({});

  /**
   * useEffect hook that updates the service detail state whenever the showList state changes.
   * @param {function} setServiceDetail - The function to update the service detail state.
   * @param {boolean} showList - The state that triggers the effect when it changes.
   * @returns None
   */
  useEffect(() => {
    setServiceDetail(showList);
  }, [showList]);

  let endDate = "";
  let displayDate = "";

  /**
   * Formats the start date and time of an event using Moment.js library.
   * @param {string} serviceDetail.eventStartDate - The start date of the event.
   * @param {string} serviceDetail.eventStartTime - The start time of the event.
   * @returns {string} The formatted start date and time in the format "DD MMM YYYY HH:mm".
   */
  let StartDate = moment(`${moment(serviceDetail?.eventStartDate)
    .utc()
    .format("yyyy/MM/DD")}
    ${serviceDetail?.eventStartTime}`).format("DD MMM YYYY HH:mm");

  /**
   * Determines the display date and end date based on the service detail and show list.
   * @param {Object} serviceDetail - The service detail object.
   * @param {Object} showList - The show list object.
   * @returns {string} The formatted display date.
   */
  if (serviceDetail?.eventStartTime) {
    if (showList?.eventEndDate) {
      endDate = moment(`${moment(showList?.eventEndDate)
        .utc()
        .format("yyyy/MM/DD")}
            ${showList?.eventEndTime}`).format("DD MMM YYYY HH:mm");

      displayDate =
        moment(StartDate).format("DD MMM") +
        " " +
        "to" +
        " " +
        moment(endDate).format("DD MMM") +
        " " +
        moment(endDate).format("YYYY") +
        "," +
        " " +
        moment(StartDate).format("HH:mm") +
        "-" +
        " " +
        moment(endDate).format("HH:mm");
    } else {
      endDate = "--:--";
      displayDate =
        moment(StartDate).format("DD MMM") +
        "," +
        moment(StartDate).format("HH:mm");
    }
  } else {
    displayDate = serviceDetail?.eventEndTime;
  }

  const downloadPDF = () => {
    console.log('Start downloading PDFs');
    const input = document.getElementById(`showViewDetails`);
    html2canvas(input, { useCORS: true, pagesplit: true }).then(
      async (canvas) => {
        // canvas.getContext('2d', { willReadFrequently: true });
        var pdf = new jsPDF("p", "pt", "a4");
        var imgData = canvas.toDataURL("image/png");
        var pageWidth = pdf.internal.pageSize.width;
        var imgHeight = (canvas.height * pageWidth) / canvas.width;
        var position = 0;
        pdf.addImage(imgData, "PNG", 0, position, pageWidth, imgHeight);

        await pdf.save(`details.pdf`);
        console.log(`PDF downloaded`);
      }
    );
  };

  /**
   * Renders a modal with detailed information about a service or event.
   * @param {boolean} props.showModal - Determines whether the modal is shown or hidden.
   * @param {function} props.closeModal - Callback function to close the modal.
   * @returns JSX elements representing the modal.
   */
  return (
    <>
      <Offcanvas
        placement="end"
        show={props.showModal}
        onHide={() => {
          props.closeModal();
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="activityDetails" id="activityDetails">
            <div className="detialBox ">
              <div className="eventTop bottom-border orange">
                <div className="topLine">
                  <div className="sidebartopWrapper">
                    {serviceDetail?.type === "event" ? (
                      <span
                        className="icon icon-activity-pin"
                        style={{ color: serviceDetail?.color }}
                      ></span>
                    ) : (
                      <span
                        className="icon icon-service-pin"
                        style={{ color: serviceDetail?.color }}
                      ></span>
                    )}
                    <span
                      className="category"
                      style={{ color: serviceDetail?.color }}
                    >
                      {serviceDetail?.categoryName}
                    </span>
                  </div>
                  {(serviceDetail?.distance || serviceDetail?.distance == 0) && (
                    <span className="distance">
                      {parseFloat(serviceDetail?.distance).toFixed(2)} miles
                    </span>
                  )}
                  {serviceDetail?.onlineStatus && <span className="onlineStatus">{t("ONLINE")}</span>}
                </div>
                <h2>{serviceDetail?.name}</h2>

                <div className="address">
                  <span className="icon icon-marker"></span>
                  <div className="addressTownWrapper">
                    {serviceDetail?.address}
                    {serviceDetail?.towns?.length > 0 &&
                      <div className="TownList">
                        <ul>
                          {serviceDetail?.towns?.map((town, index) => <li key={index}>{town}</li>)}
                        </ul>
                      </div>
                    }
                  </div>
                </div>
                <div className="btn-wrap">
                  {serviceDetail?.location &&
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="btn btn-primary direction"
                      href={`https://www.google.com/maps/?q=${serviceDetail?.location?.coordinates[1]},${serviceDetail?.location?.coordinates[0]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="icon icon-coolicon"></span>
                      {/* {t("Directions")} */}
                    </a>
                  }
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      // setBookmarkBtn(true);
                      const getbookMarkBookArr =
                        JSON.parse(localStorage.getItem("bookmark_data")) || [];
                      const isBookMarkAdded = getbookMarkBookArr?.some(
                        (itemData) => serviceDetail?._id === itemData._id
                      );
                      let localList = [...filteredDataSource];

                      if (isBookMarkAdded) {
                        if (
                          getbookMarkBookArr &&
                          getbookMarkBookArr.length > 0
                        ) {
                          const newData = getbookMarkBookArr.filter(
                            (val) => serviceDetail._id !== val._id
                          );
                          setServiceDetail({
                            ...serviceDetail,
                            isbookmarked: false,
                          });
                          localStorage.setItem(
                            "bookmark_data",
                            JSON.stringify(newData)
                          );
                          props.closeModal();
                        }
                      } else {
                        let temp = {
                          ...serviceDetail,
                          isbookmarked: !serviceDetail.isbookmarked,
                        };
                        getbookMarkBookArr.push(temp);
                        setServiceDetail(temp);
                        localStorage.setItem(
                          "bookmark_data",
                          JSON.stringify(getbookMarkBookArr)
                        );
                      }
                      setFilteredDataSource([...localList]);
                    }}
                    className={
                      serviceDetail?.isbookmarked === true ? "isbookmarked bookmark" : "bookmark"
                    }
                  >
                    <span className="icon icon-bookmark-outline"></span>
                    {t("BOOKMARK")}
                  </Button>
                  {serviceDetail?.onlineStatus &&
                    (<Button
                      onClick={() => {
                        window.open(serviceDetail?.liveLink, '_blank')
                      }}
                      className="buttonJoinNow bookmark"
                    >
                      <span className="icon icon-server"></span>
                      {t("Join_Now")}
                    </Button>)
                  }
                  <Button
                    onClick={() => {
                      downloadPDF()
                    }}
                    className="bookmark"
                  >
                    <span className="icon icon-download directions"></span>
                  </Button>
                </div>
                <div className="description">{serviceDetail?.description}</div>
                <div className="pins">
                  {serviceDetail?.type === "service" &&
                    serviceDetail?.relatedEvents.map((item, index) => {
                      return (
                        <div key={index}>
                          <span className="icon icon-activity-pin color-icon nameMap">
                            <p className="text_name">{item?.name}</p>
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="time bottom-border">
                {serviceDetail?.type === "service" ? (
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {serviceDetail?.availableDayTime?.map((item, index) => {
                          let currentDay = moment().format("ddd");
                          if (item.day === currentDay) {
                            const timing = item.isDayActive
                              ? item.is24Hours
                                ? "24 Hours available"
                                : item.startTime + "-" + item.endTime
                              : "Closed";
                            return (
                              <>
                                <span
                                  key={index}
                                  className="icon icon-calendar"
                                ></span>
                                <div>
                                  <span>{item.day}</span>
                                  {timing}
                                  <span>
                                    {showList?.isActive === "Close"
                                      ? "Closed"
                                      : showList?.isActive}
                                  </span>
                                </div>
                              </>
                            );
                          }
                        })}
                      </Accordion.Header>
                      <Accordion.Body>
                        {serviceDetail?.availableDayTime?.map((item, index) => {
                          let currentDay = moment().format("ddd");
                          if (item.day !== currentDay) {
                            const timing = item.isDayActive
                              ? item.is24Hours
                                ? "24 Hours available"
                                : item.startTime + "-" + item.endTime
                              : "Closed";
                            return (
                              <>
                                <div className="timeWrap" key={index}>
                                  <span>{item.day}</span>
                                  <span>{timing}</span>
                                </div>
                              </>
                            );
                          }
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  <>
                    <span className="icon icon-calendar"></span>
                    <div>
                      <span>{displayDate}</span>
                      <span className="isActive">
                        {serviceDetail?.isActive}
                      </span>
                    </div>
                  </>
                )}
              </div>
              {serviceDetail?.phoneNumber ? (
                <div className="phone bottom-border">
                  <span className="icon icon-call"></span>
                  {serviceDetail?.phoneNumber}
                </div>
              ) : (
                ""
              )}
              {serviceDetail?.email ? (
                <div className="email bottom-border">
                  <span className="icon icon-mail"></span>
                  {serviceDetail?.email}
                </div>
              ) : null}
              {serviceDetail?.websiteLink ? (
                <div className="website bottom-border">
                  <span className="icon icon-link"></span>
                  <Link to={serviceDetail?.websiteLink} target="blank">
                    {serviceDetail?.websiteLink}
                  </Link>
                </div>
              ) : null}
              <div className="social bottom-border">
                {serviceDetail?.facebookLink ? (
                  <a href={serviceDetail?.facebookLink}>
                    <span className="icon icon-facebook"></span>
                  </a>
                ) : null}
                {serviceDetail?.twitterLink ? (
                  <a href={serviceDetail?.twitterLink}>
                    <span className="icon icon-twitter"></span>
                  </a>
                ) : null}
                {serviceDetail?.instagramLink ? (
                  <a href={serviceDetail?.instagramLink}>
                    <span className="icon icon-instagram"></span>
                  </a>
                ) : null}
                {serviceDetail?.linkedLink ? (
                  <a href={serviceDetail?.linkedLink}>
                    <span className="icon icon-linkedin"></span>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div id="showViewDetails" className="showViewData">
          <table className="titleText">
            <thead>
              <tr>
                <td colSpan="2" className="service">
                  {serviceDetail?.type === "event" ? (
                    <span>{t("EVENTS")}</span>
                  ) : (
                    <span>{t("SERVICE_CENTRES")}</span>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ height: 16 }} height={"16"}></td>
              </tr>
            </thead>
          </table>
          <table className="showTable">
            <tr>
              <td>
                <table className="secondTable">
                  <tbody>
                    <tr>
                      <td className="categoryList">
                        <div className="category">
                          {serviceDetail?.categoryName}
                          {serviceDetail?.onlineStatus && <span>{t("ONLINE")}</span>}
                        </div>
                        <div className="name">{serviceDetail?.name}</div>
                        <div className="description">
                          {serviceDetail?.description}
                        </div>
                        {serviceDetail?.towns?.length > 0 &&
                          (
                            <>
                              <div className="title">{t("TOWNS")} </div>
                              <div className="TownList">
                                <ul>
                                  {serviceDetail?.towns?.map((town, i) => <li key={i}>{town}</li>)}
                                </ul>
                              </div>
                            </>
                          )
                        }
                        {/* {item?.onlineStatus &&
                                    <div className="title">
                                      <p>{t("Join_Now")}</p>
                                      {item?.liveLink && (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.open(item?.liveLink, '_blank');
                                          }}
                                          href={`${item?.liveLink}`}
                                          style={{ color: 'blue', cursor: 'pointer' }}
                                        >
                                          {item.liveLink}
                                        </a>
                                      )}
                                    </div>
                                  } */}

                      </td>
                      <td className="categoryList categoryList1">
                        <div className="serviceTime">
                          {serviceDetail?.type === "event" ? (
                            <span>{t("EVENT_TIMING")}</span>
                          ) : (
                            <span>{t("SERVICE_TIMING")}</span>
                          )}
                        </div>
                        <div
                          className={
                            serviceDetail?.type === "service"
                              ? "serviceData"
                              : "eventDataStyle"
                          }
                        >
                          {serviceDetail?.type === "service" ? (
                            serviceDetail?.availableDayTime?.map(
                              (item, index) => {
                                let currentDay = moment().format("ddd");
                                if (item.day === currentDay) {
                                  const timing = item.isDayActive
                                    ? item.is24Hours
                                      ? t("24_Hours_available")
                                      : item.startTime + "-" + item.endTime
                                    : "Closed";
                                  return (
                                    <>
                                      <div
                                        className="serviceTiming"
                                        key={index}
                                      >
                                        <span className="serviceDay">
                                          {item.day}
                                        </span>
                                        {timing}
                                        <span className="isActive serviceDay">
                                          {showList?.isActive === "Close"
                                            ? "Closed"
                                            : item?.isActive}
                                        </span>
                                      </div>
                                    </>
                                  );
                                } else {
                                  const timing = item.isDayActive
                                    ? item.is24Hours
                                      ? t("24_Hours_available")
                                      : item.startTime + "-" + item.endTime
                                    : "Closed";
                                  return (
                                    <>
                                      <div className="serviceTiming">
                                        <span className="serviceDay">
                                          {item.day}
                                        </span>
                                        {timing}
                                      </div>
                                    </>
                                  );
                                }
                              }
                            )
                          ) : (
                            <>
                              <span className="icon icon-calendar"></span>
                              <div>
                                <span>{displayDate}</span>
                                <span className="isActive">
                                  {serviceDetail?.isActive}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                      <td className="categoryList categoryList2">
                        <div>
                          {serviceDetail?.type === "service" && (
                            <>
                              <div className="title">{t("EVENTS")}</div>
                              {serviceDetail?.relatedEvents?.length > 0 ? serviceDetail?.relatedEvents?.map(
                                (item, index) => {
                                  return (
                                    <div className="eventData" key={index.toString()}>
                                      <span>{item?.name}</span>
                                    </div>
                                  );
                                }
                              ) : <div className="eventData">No Event Found</div>}
                            </>
                          )
                          }
                        </div>
                      </td>
                    </tr>
                    <tr>
                      {serviceDetail?.type === "service" ? (
                        <>
                          <td className="categoryList3">
                            <div className="location">
                              <span>{t("LOCATION")}:</span>
                              {serviceDetail?.address
                                ? serviceDetail?.address
                                : t("NOT_AVAILABLE")}
                            </div>
                            <div className="location">
                              <span>{t("WEBSITE")}:</span>
                              {serviceDetail?.websiteLink
                                ? serviceDetail?.websiteLink
                                : t("NOT_AVAILABLE")}
                            </div>
                            <div className="location">
                              <span>{t("TWITTER")}:</span>
                              {serviceDetail?.twitterLink
                                ? serviceDetail?.twitterLink
                                : t("NOT_AVAILABLE")}
                            </div>
                          </td>
                          <td className="categoryList3 locationTime">
                            <div className="location">
                              <span>{t("PHONE_NUMBER")}:</span>
                              {serviceDetail?.phoneNumber
                                ? serviceDetail?.phoneNumber
                                : t("NOT_AVAILABLE")}
                            </div>
                            <div className="location">
                              <span>{t("FACEBOOK")}:</span>
                              {serviceDetail?.facebookLink
                                ? serviceDetail?.facebookLink
                                : t("NOT_AVAILABLE")}
                            </div>
                            <div className="location">
                              <span>{t("LINKEDIN")}:</span>
                              {serviceDetail?.linkedLink
                                ? serviceDetail?.linkedLink
                                : t("NOT_AVAILABLE")}
                            </div>
                          </td>
                          <td className="categoryList3">
                            <div className="location">
                              <span>{t("EMAIL_ADDRESS")}:</span>
                              {serviceDetail?.email
                                ? serviceDetail?.email
                                : t("NOT_AVAILABLE")}
                            </div>
                            <div className="location">
                              <span>{t("INSTAGRAM")}:</span>
                              {serviceDetail?.instagramLink
                                ? serviceDetail?.instagramLink
                                : t("NOT_AVAILABLE")}
                            </div>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

          </table>
        </div>
      </Offcanvas>
    </>
  );
};

export default ViewDetails;
