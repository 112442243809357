/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import Constant from "../../../util/constant";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../state/ducks/utils/operations";
import useWindowDimension from "../../../hooks/useWindowDimension";

import Label from "../../../components/label/label";
import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import { resetPassword } from "../../../state/ducks/auth/actions";
import CustomButton from "../../../components/customButton/customButton";

import "./resetPassword.scss";
import { Helmet } from 'react-helmet';

/**
 * A functional component that renders a password reset form.
 * @param {object} props - The component props.
 * @returns The rendered password reset form.
 */
function ResetPassword(props) {
  const dimensions = useWindowDimension();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form, setResetForm] = useState();
  const [busy, setBusy] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const cloudnaryImage = process.env.REACT_APP_CLOUDNARY_URL + process.env.REACT_APP_CLOUDNARY_NAME + process.env.REACT_APP_CLOUDNARY_SUBFLODER


  /**
   * An array of objects representing different error fields for a multi-step form validation.
   * Each object contains a specific validation rule and its corresponding error message.
   * @type {Array<Object>}
   * @property {string} length - The error message for the minimum length validation rule.
   * @property {string} hasSpecialChar - The error message for the special character validation rule.
   * @property {string} hasNumbers - The error message for the numerical character validation rule.
   * @property {string} hasUpperLowercase - The error message for the uppercase and lowercase character validation rule.
   */
  const multiErrorFields = [
    { length: t("MINIMUM_8") },
    { hasSpecialChar: t("SPECIAL") },
    { hasNumbers: t("NUMERICAL") },
    { hasUpperLowercase: t("UPPERCASELOWERCASE") },
  ];

  /**
   * An object representing a form with password and confirmPassword fields.
   * Each field has a name and a validate object containing validation rules.
   * @property {object} password - The password field.
   * @property {string} password.name - The name of the password field.
   * @property {object} password.validate - The validation rules for the password field.
   * @property {object} password.validate.required - The required validation rule for the password field.
   * @property {boolean} password.validate.required.value - Whether the password field is required.
   * @property {string} password.validate.required.message - The error message to display if the password field is required.
   * @property {object} password.validate.validate - Additional validation rules for the password field.
   */
  const resetForm = {
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: t("NEW_PASSWORD_REQUIRED"),
        },
        validate: {
          length: (value) =>
            (value && value.length >= 8 && value && value.length <= 64) || "",
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) !== null,
          hasUpperLowercase: (value) =>
            value && value.match(Constant.REGEX.UPPERCASELOWERCASE) !== null,
        },
      },
    },
    confirmPassword: {
      name: "confirmPassword",
      validate: {
        required: {
          value: true,
          message: t("CONFIRM_PASSWORD_REQUIRED"),
        },
      },
    },
  };

  /**
   * Handles the form submission for resetting a password.
   * @param {Event} e - The form submission event.
   * @param {string} token - The token used for password reset.
   * @returns None
   */
  const onFormSubmit = async (e, token) => {
    try {
      setBusy(true);
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      const params = {
        password: e.password,
        confirmPassword: e.confirmPassword
      };

      let res = await dispatch(
        resetPassword(token.replaceAll(" ", "+"), params)
      );

      setTimeout(() => {
        setBusy(false);
        dispatch(
          showToast({
            message: res.message,
            type: "success",
          })
        );

        navigate("/admin");
      }, 2000);
    } catch (err) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(err, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Renders a form for resetting a password.
   * @returns JSX element representing the reset password form.
   */
  return (<>
    <Helmet>
      <title>Reset Password | Admin Angus Recovery Road Map</title>
    </Helmet>
    <div className="ResetPassword">
      <div className="formMain" style={{ height: dimensions.height }}>
        <div className="formCenter">
          <div className="logo_bg">
            <img src={cloudnaryImage + 'banner_angus.png'} alt="" />
            <div className="logoauth">
              <img src={cloudnaryImage + 'Logo.png'} alt="Angus Logo" className="LogoAuth" />
            </div>
          </div>
          <Label title={t("RESET_PASSWORD_TITLE")} />
          <HookForm
            defaultForm={{}}
            init={(form) => setResetForm(form)}
            onSubmit={(e) => onFormSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <TextField
                    formMethod={formMethod}
                    rules={resetForm.password.validate}
                    multiErrorFields={multiErrorFields}
                    name={resetForm.password.name}
                    errors={formMethod?.formState.errors}
                    showHidePasswordNew={() => this.showHidePasswordNew()}
                    placeholder={t("NEW_PASSWORD")}
                    type={showPass ? "text" : "password"}
                    iconClass={showPass ? "icon-eye-show" : "icon-eye-hide"}
                    onChange={() =>
                      formMethod.watch("confirmPassword") &&
                      formMethod.trigger("confirmPassword")
                    }
                    onIconClick={() => setShowPass(!showPass)}
                    iconRightShow={true}
                  />

                  <TextField
                    formMethod={formMethod}
                    rules={{
                      required: {
                        value: true,
                        message: t("CONFIRM_PASSWORD_REQUIRED"),
                      },
                      validate: {
                        matchPassword: (value) =>
                          (value && value === formMethod.watch("password")) ||
                          t("BOTHNOTMATCH"),
                      },
                    }}
                    name={resetForm.confirmPassword.name}
                    errors={formMethod?.formState.errors}
                    showHidePasswordConf={() => this.showHidePasswordConf()}
                    type={showConfirmPass ? "text" : "password"}
                    placeholder={t("CONFIRM_PASSWORD")}
                    iconClass={
                      showConfirmPass ? "icon-eye-show" : "icon-eye-hide"
                    }
                    onChange={() =>
                      formMethod.watch("password") &&
                      formMethod.trigger("password")
                    }
                    onIconClick={() => setshowConfirmPass(!showConfirmPass)}
                    iconRightShow={true}
                  />

                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={t("RESET")}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      </div>
    </div>
  </>);
}

export default ResetPassword;
