import React from "react";
import "./notFound.scss";
import { useTranslation } from "react-i18next";

/**
 * A functional component that renders a "Not Found" screen.
 * @param {object} props - The props passed to the component.
 * @returns The JSX elements representing the "Not Found" screen.
 */
function NotFound(props) {
  
  const { t } = useTranslation();
  
  /**
   * Renders a "Not Found" screen component.
   * @returns {JSX.Element} - The rendered "Not Found" screen component.
   */
  return (
    <div className="notFoundScreen">
      <div className="cardBox">
        <div className="title">{t("404")}</div>
        <div className="sub-title">{t("WHOOPS")}</div>
        <div className="txt">{t("NOTFOUND")}</div>
      </div>
    </div>
  );
}

export default NotFound;
