/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { get } from "lodash";
import HookForm from "../hookForm/hookForm";

import Label from "../label/label";
import SearchBox from "../searchBox/searchBox";
import TextField from "../textField/textField";
import ModalCustom from "../modalCustom/modalCustom";
import ConfirmModal from "../confirmModal/confirmModal";
import CustomButton from "../customButton/customButton";
import CustomNavigations from "../customNavigations/customNavigations";
import CustomSelectPicker from "../customSelectPicker/customSelectPicker";
import { Scrollbars } from "react-custom-scrollbars-2";

import Constant from "../../util/constant";
import { showToast } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";
import {
  getCategoryList,
  statusUpdate,
  deleteCategory,
  updateCategory,
  addCategory,
  getCategoryListForColor,
  setCategoryFilter,
} from "../../state/ducks/categoryDetails/action";
import { useDispatch, useSelector } from "react-redux";
import "./customLeftPart.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Loading from "../loading/loading";

const CustomLeftPart = (props) => {
  const location = useLocation();

  const CLOUDINARY_URL =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER;

  const cloudnaryImage =
    process.env.REACT_APP_CLOUDNARY_URL +
    process.env.REACT_APP_CLOUDNARY_NAME +
    process.env.REACT_APP_CLOUDNARY_SUBFLODER

  let {
    onViewClick,
    onClickClose,
    addUncategorised,
    refreshCategory,
    setRefreshCategory,
  } = props;


  const dimensions = useWindowDimension();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const limit = 10

  const selectedCategoryId = useSelector(
    state => state?.CategoryDetails?.appliedCategoryFilters?.selectedCategoryId
  );
  const searchValue = useSelector(state => state?.CategoryDetails?.appliedCategoryFilters?.searchVal);
  const page = useSelector(state => state?.CategoryDetails?.appliedCategoryFilters?.page);
  const totalCategoryCount = useSelector(
    (state) => state?.CategoryDetails?.categoryList?.payload?.totalCount
  );

  const scrollBar = useRef();

  const [headerHeight, SetHeaderHeight] = useState("");
  const [rowHeight, SetRowHeight] = useState("");
  const [addBtnHeight, SetAddBtnHeight] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showCategroyModal, setShowCategroyModal] = useState({
    show: false,
    type: "",
  });
  const [categoryId, setCategoryId] = useState();
  const [category, setCategory] = useState({});
  const [businessCount, setBusinessCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [categoryStatus, setCategoryStatus] = useState();
  const [busy, setBusy] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchCategoryData, setSearchCategoryData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [scrollApiCalling, setScrollApiCalling] = useState(true);

  const categoryForm = {
    categoryName: {
      name: "categoryName",
      validate: {
        required: {
          value: true,
          message: t("CATEGORY_NAME_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.ASCII,
          message: t("FIELD_CAN_ONLY"),
        },
      },
    },
    selectPicker: {
      name: "selectPicker",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  const getCategoryData = async () => {
    for (let i = 1; i <= page; i++) {
      await getCategoryDetailsData(searchValue, i);
    }
  }

  useEffect(() => {
    dispatch(getCategoryListForColor());
    if (page > 1) getCategoryData()
  }, []);

  useEffect(() => {
    // console.log("selectedCategoryId", selectedCategoryId);
    if (selectedCategoryId) {
      const selectedIndex = searchCategoryData?.findIndex((i) => i._id === selectedCategoryId)
      // console.log("selectedIndex", selectedIndex);
      if (selectedIndex === -1) {
        onViewClick(searchCategoryData?.[0]);
        setSelectedIndex(0);
      } else {
        const selectedCategory = searchCategoryData[selectedIndex];
        if (selectedCategory) onViewClick(selectedCategory);
        setSelectedIndex(selectedIndex);
      }
    } else {
      onViewClick(searchCategoryData?.[0]);
      setSelectedIndex(0);
    }
  }, [selectedCategoryId, JSON.stringify(searchCategoryData)]);

  useEffect(() => {
    if (reload) {
      // console.log("reload the component");
      dispatch(setCategoryFilter({ page: 1 }))
      dispatch(getCategoryListForColor());
      if (page === 1) getCategoryDetailsData(searchValue, page);
      setReload(false);
    }
  }, [reload]);

  // refresh category when any businesses or events added/deleted/category changed
  useEffect(() => {
    // console.log("Category list refresh");
    if (refreshCategory) {
      getCategoryData()
      setRefreshCategory(false)
    }
  }, [refreshCategory]);

  useEffect(() => {
    getCategoryDetailsData(searchValue, page);
  }, [page]);

  const getCategoryDetailsData = async (search, pageNumber) => {
    console.log("getCategoryDetailsData called", search, pageNumber);
    setScrollApiCalling(false)
    if (pageNumber === 1) setBusy(true)
    try {
      const res = await dispatch(getCategoryList(search, pageNumber, limit))
      // console.log("getCategoryDetailsData ==>", res);

      if (res.payload) {
        const categoryList = res?.payload?.categoryList || []
        if (pageNumber == 1) {
          let data = localStorage.getItem("Uncategorised");
          let uncategorisedCategory = data ? JSON.parse(data) : {};
          if (addUncategorised && search == '' && uncategorisedCategory) {
            categoryList.unshift(uncategorisedCategory)
          }
          setSearchCategoryData(categoryList);
          const index = categoryList?.findIndex((i) => i._id === selectedCategoryId) || 0
          onViewClick(categoryList[index])
          if (!selectedCategoryId) {
            dispatch(setCategoryFilter({ selectedCategoryId: categoryList[0]._id }))
          }
          setBusy(false)
        } else {
          setSearchCategoryData((prevData) => [...prevData, ...categoryList]);
        }
        if (categoryList.length > 0) setScrollApiCalling(true);
      } else {
        setScrollApiCalling(true)
        setReload(true)
      }
    } catch (error) {
      console.log("getCategoryDetailsData error ==>", error);
      setBusy(false)
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  const categoryStatusUpdate = async () => {
    let params = {
      status: categoryStatus !== Constant.STATUS.DISABLE ? Constant.STATUS.DISABLE : Constant.STATUS.ENABLE,
    };
    try {
      const res = await dispatch(statusUpdate(categoryId, params))

      setShowDisableModal(!showDisableModal);
      setReload(true);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );

    } catch (error) {
      setShowDisableModal(!showDisableModal);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  const deleteCategoryData = async (data) => {
    try {
      const res = await dispatch(deleteCategory(data))
      setShowDeleteModal(false);
      setReload(true);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      setShowDeleteModal(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  const categoryUpdateData = async (data, id) => {
    let params = {
      categoryName: data.categoryName,
      color: data.selectPicker,
    };
    try {
      const res = await dispatch(updateCategory(id, params))
      setShowCategroyModal({ show: false, type: '' });

      for (let i = 1; i <= page; i++) {
        await getCategoryDetailsData(searchValue, i);
      }

      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
      dispatch(getCategoryListForColor());
    } catch (error) {
      setShowCategroyModal({ show: false, type: '' });
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  const addCategoryToList = async (data) => {
    let params = {
      categoryName: data.categoryName,
      status: Constant.STATUS.ENABLE,
      color: data.selectPicker,
    };

    try {
      const res = await dispatch(addCategory(params))
      setReload(true);

      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      console.log("add category ==> ", error);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    };

  };

  useEffect(() => {
    SetHeaderHeight(document.getElementsByClassName("logo")[0].offsetHeight);
    SetRowHeight(document.getElementsByClassName("row_part")[0].offsetHeight);
    SetAddBtnHeight(
      document.getElementsByClassName("add_category_btn")[0].offsetHeight
    );
  }, []);

  const toastData = (business, event, name) => {
    if (business == 0) {
      return <p>{t("Are_you_sure_you_want_to_delete")} "{name}"?</p>;
    } else if (business == 1 && event == 0) {
      return (
        <p>{t("There_is")} <span className="ColorRed">{business} {t("service")}</span> {t("associated_with")} "{name}". {t("Are_you_sure_yout_want_to_disable")}</p>
      );
    } else if (business == 1 && event == 1) {
      return (
        <p>{t("There_is")} <span className="ColorRed">{business} {t("service")}</span> {t("And")} <span className="ColorRed">{event} {t("event")}</span> {t("associated_with")} "{name}". {t("Are_you_sure_you_want_to_delete_category")}?</p>
      );
    } else if (business > 1 && event == 0) {
      return (
        <p>{t("There_are")} <span className="ColorRed">{business} {t("services")}</span> {t("associated_with")} "{name}". {t("Are_you_sure_you_want_to_delete_category")}?</p>
      );
    } else if (business >= 1 && event >= 1) {
      return (
        <p>{t("There_are")} <span className="ColorRed">{business} {business > 1 ? t("services") : t("service")}</span> {t("And")} <span className="ColorRed">{event} {event > 1 ? t("events") : t("event")}</span>  {t("associated_with")} "{name}". {t("Are_you_sure_you_want_to_delete_category")}?</p>
      );
    }
  };

  const disableToastData = (business, event, name) => {
    if (business == 0) {
      return <p>{t("Are_you_sure_you_want_to_disable")} "{name}"?</p>;
    } else if (business == 1 && event == 0) {
      return (
        <p>{t("There_is")} <span className="ColorRed">{business} {t("service")}</span> {t("associated_with")} "{name}". {t("Are_you_sure_yout_want_to_disable")}</p>
      );
    } else if (business == 1 && event == 1) {
      return (
        <p>{t("There_is")} <span className="ColorRed">{business} {t("service")}</span> {t("And")} <span className="ColorRed">{event} {t("event")}</span> {t("associated_with")} "{name}". {t("Are_you_sure_you_want_to_disable")}?</p>
      );
    } else if (business > 1 && event == 0) {
      return (
        <p>{t("There_are")} <span className="ColorRed">{business} {t("services")}</span> {t("associated_with")} "{name}". {t("Are_you_sure_you_want_to_disable")}?</p>
      );
    } else if (business >= 1 && event >= 1) {
      return (
        <p>{t("There_are")} <span className="ColorRed">{business} {business > 1 ? t("services") : t("service")}</span> {t("And")} <span className="ColorRed">{event} {event > 1 ? t("events") : t("event")}</span>  {t("associated_with")} "{name}". {t("Are_you_sure_you_want_to_disable")}?</p>
      );
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollBar.current.getValues();
    if (scrollApiCalling == true) {
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        dispatch(setCategoryFilter({ page: page + 1 }))
      }
    }
  };

  return (
    <div className="CustomLeftPart">
      <div className="logo">
        <div className="sidebarauthbg">
          <div className="bgSidebar">
            <img src={cloudnaryImage + "banner_angus.png"} />
          </div>
          <div className="Sidebarlogo">
            <img src={cloudnaryImage + "Logo.png"} />
          </div>
        </div>
        <button onClick={onClickClose} className="icon-close"></button>
      </div>
      <div className="row_part">
        <div className="categories_title">
          {t("Total_Categories")}
          <span className="count_number">
            {addUncategorised ? totalCategoryCount + 1 : totalCategoryCount}
          </span>
        </div>
        <SearchBox
          placeholder={t("Search_Category")}
          value={searchValue}
          onSearch={(value) => {
            dispatch(setCategoryFilter({ searchVal: value, page: 1 }))
            getCategoryDetailsData(value, 1);
          }}
          onClear={() => {
            dispatch(setCategoryFilter({ searchVal: '', page: 1 }))
            getCategoryDetailsData("", 1);
          }}
        />
      </div>
      <div className="data_list">
        {busy ?
          <div><Loading show={busy} type="spin" color="#073180" className="adminPanel" /></div> :
          <Scrollbars
            ref={scrollBar}
            style={{
              height: dimensions.height - headerHeight - rowHeight - addBtnHeight,
            }}
            onScroll={handleScroll}
          >
            {searchCategoryData?.length > 0 ? (
              searchCategoryData?.map((item, index) => {
                return (
                  <CustomNavigations
                    key={`${item}-${index}`}
                    className={item.status === Constant.STATUS.ENABLE ? "" : " disable"}
                    modal_dis={item.modal_dis}
                    showEdit={item.categoryName != "Uncategorised"}
                    onClickView={async () => {
                      // document.body.classList.remove('rightSidebarshow');
                      props.setShowBusinessDetailsModal && props.setShowBusinessDetailsModal(false);
                      props.setShowEventDetailsModal && props.setShowEventDetailsModal(false);
                      dispatch(setCategoryFilter({ selectedCategoryId: item._id }));
                    }}
                    onClickDelete={() => {
                      setShowDeleteModal(!showDeleteModal);
                      setCategoryId(item._id);
                      setBusinessCount(item.businessCount);
                      setServiceName(item.categoryName);
                      setEventCount(item.eventCount);
                    }}
                    onClickEdit={() => {
                      setCategory(item);
                      setCategoryId(item._id);
                      setShowCategroyModal({ show: true, type: "Edit" });
                    }}
                    title={item.categoryName}
                    color={item.color}
                    count={location.pathname === '/admin/event' ? item.eventCount : item.businessCount}
                    selected={index == selectedIndex}
                    id={item._id}
                    checked={item.status === Constant.STATUS.ENABLE}
                    onCheckChange={() => {
                      setCategoryStatus(item.status);
                      setCategoryId(item._id);
                      setBusinessCount(item.businessCount);
                      setShowDisableModal(!showDisableModal);
                      setServiceName(item.categoryName);
                      setEventCount(item.eventCount);
                    }}
                  />
                );
              })
            ) : (
              <div className="no_categroy_row">
                <img
                  className="no_categroy_logo"
                  src={CLOUDINARY_URL + "no-categroy.png"}
                  alt="no-categroy"
                />
                <div className="no_categroy_txt">
                  {t("The_entered_category_is_not_found_Try_searching_again")}
                </div>
              </div>
            )}
          </Scrollbars>
        }
      </div>
      <div className="add_category_btn">
        <CustomButton
          onClick={() => setShowCategroyModal({ show: true, type: "Add" })}
          disabled={totalCategoryCount === 30}
          className="outline_btn"
          title={"+ " + t("Add_Category")}
        />
      </div>
      <ConfirmModal
        modalTitle={t("DELETE_TITLE")}
        btnHide={false}
        leftBtnTitle={t("YES_DELETE")}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className={"confirm-delete"}
        onProcced={() => {
          deleteCategoryData(categoryId);
        }}
      >
        <p>{toastData(businessCount, eventCount, serviceName)}</p>
      </ConfirmModal>
      <ConfirmModal
        modalTitle={
          t(
            `${categoryStatus === Constant.STATUS.DISABLE ? "ENABLE_TITLE" : "DISABLE_TITLE"}`
          )
        }
        btnHide={false}
        leftBtnTitle={t(
          `${categoryStatus === Constant.STATUS.DISABLE ? "YES_ENABLE" : "YES_DESABLE"}`
        )}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showDisableModal}
        onHide={() => setShowDisableModal(false)}
        onProcced={() => {
          categoryStatusUpdate();
        }}
        className={"confirm-disable"}
      >
        {categoryStatus === Constant.STATUS.ENABLE ? (
          <>
            <p>{disableToastData(businessCount, eventCount, serviceName)}</p>
          </>
        ) : (
          <p> {t("Are_you_sure_you_want_to_Enable")} {`"${serviceName}"?`}</p>
        )}
      </ConfirmModal>
      <ModalCustom
        showModal={showCategroyModal.show}
        onHide={() => setShowCategroyModal({ show: false, type: "" })}
        className={"category_modal"}
      >
        <HookForm
          defaultForm={{}}
          onSubmit={() => {
            setShowCategroyModal({ show: false, type: "" });
          }}
        >
          {(formMethod) => {
            return (
              <>
                <Modal.Header>
                  <Modal.Title>
                    {t(showCategroyModal.type === "Add"
                      ? "Add_Category"
                      : "Edit_Category")}
                  </Modal.Title>
                  <button
                    onClick={() =>
                      setShowCategroyModal({ show: false, type: "" })
                    }
                    className="icon-close"
                  ></button>
                </Modal.Header>
                <Modal.Body>
                  <Label title={t("CATEGORY")} />
                  <TextField
                    formMethod={formMethod}
                    rules={categoryForm.categoryName.validate}
                    name={categoryForm.categoryName.name}
                    errors={formMethod?.formState.errors}
                    defaultValue={
                      showCategroyModal?.type !== "Add"
                        ? category?.categoryName
                        : ""
                    }
                    // autoFocus={true}
                    maxLength={35}
                    type="text"
                    autoComplete="on"
                    placeholder={t("CATEGORY_NAME")}
                  />
                  <div>
                    <Controller
                      control={formMethod?.control}
                      rules={categoryForm.selectPicker.validate}
                      name={categoryForm.selectPicker.name}
                      defaultValue={
                        showCategroyModal?.type !== "Add" && category?.color
                      }
                      render={({ field: { onChange, value } }) => (
                        <CustomSelectPicker
                          onChange={onChange}
                          // seachDataLIST={searchCategoryData}
                          defaultValue={
                            showCategroyModal?.type !== "Add" && category?.color
                          }
                        />
                      )}
                    />
                  </div>
                  <Modal.Footer>
                    <CustomButton
                      type="submit"
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                      title={
                        showCategroyModal.type === "Add" ? "Add" : "Update"
                      }
                      onClick={() => {
                        if (showCategroyModal.type === "Add") {
                          addCategoryToList(formMethod.getValues());
                        } else {
                          categoryUpdateData(
                            formMethod.getValues(),
                            categoryId
                          );
                        }
                      }}
                    />
                    <CustomButton
                      onClick={() => {
                        setShowCategroyModal({ show: false, type: "" });
                      }}
                      className="outline_btn"
                      title={t("CANCEL")}
                    />
                  </Modal.Footer>
                </Modal.Body>
              </>
            );
          }}
        </HookForm>
      </ModalCustom>
    </div>
  );
};

CustomLeftPart.defaultProps = {
  className: "",
  loading: false,
};

export default CustomLeftPart;
