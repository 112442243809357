/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import HookForm from "../hookForm/hookForm";

import FileUpload from "../fileUpload/fileUpload";
import TextField from "../textField/textField";

import Constant from "../../util/constant";
import { showToast } from "../../state/ducks/utils/operations";

import "./profile.scss";
import CustomButton from "../customButton/customButton";
import TextFieldNoRequired from "../textFieldNoRequired/textFieldNoRequired";
import ConfirmModal from "../confirmModal/confirmModal";
import {
  updateProfileDetails,
  getProfileDetails,
  changePassword,
} from "../../state/ducks/profile/operations";
import { getItem, removeItem, setItem } from "../../services/localStorageService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { saveToken } from "../../services/jwtService";
import { destroySession } from "../../state/ducks/auth/actions";

const Profile = (props) => {
  let [image, setImage] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth.session)

  const [busy, setBusy] = React.useState(false);
  let [showImageName, setShowImageName] = useState();
  const [changeform, setResetForm] = useState();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userForm, setUserForm] = useState(0);
  const userDetails = getItem("userDetails") || user;
  const [searchOldPassword, setSearchOldPassword] = useState("");
  /**
   * Event handler for when an image is changed or selected.
   * @param {File[]} acceptedFiles - An array of accepted image files.
   * @returns None
   */
  const onImageChange = (acceptedFiles) => {
    setShowImageName(acceptedFiles[0]);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };

  /**
   * Runs the `getProfileDetailsData` function once when the component is mounted.
   * @returns None
   */
  useEffect(() => {
    getProfileDetailsData();
  }, []);

  useEffect(() => {
    if (!props.changePasswordModel && changeform) {
      changeform?.setValue(changePasswordForm.newpassword.name, '')
      changeform?.setValue(changePasswordForm.oldpassword.name, '')
      changeform?.setValue(changePasswordForm.password.name, '')
      userForm?.clearErrors(changePasswordForm.newpassword.name);
      userForm?.clearErrors(changePasswordForm.oldpassword.name);
      userForm?.clearErrors(changePasswordForm.password.name);
    }
  }, [props.changePasswordModel])
  /**
   * useEffect hook that updates the userForm values and clears errors when the userDetails change.
   * @param {object} userForm - The form object for the user.
   * @param {object} userDetails - The details of the user.
   * @param {object} profileForm - The form object for the profile.
   * @returns None
   */
  useEffect(() => {
    // console.log(userForm);
    if (userForm) {
      if (userDetails?.name !== userForm?.getValues('profileName')) {
        // console.log(userDetails?.name, userForm?.getValues('profileName'))
        userForm?.setValue(profileForm.profileName.name, userDetails?.name);
        userForm?.clearErrors(profileForm.profileName.name);
      }
    }
  }, [userDetails]);


  /**
   * Retrieves the profile details data asynchronously.
   * @returns None
   * @throws {Error} If an error occurs while fetching the profile details.
   */
  const getProfileDetailsData = async () => {
    try {
      const res = await dispatch(getProfileDetails())
      setItem("userDetails", res?.payload);
    } catch (error) {
      console.log("getProfileDetailsData error => ", error);
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Handles the submit event when updating a profile.
   * @param {Event} e - The submit event object.
   * @returns None
   */
  const onUpdateSubmit = async (e) => {
    const params = new FormData();
    params.append("profileImage", showImageName);
    params.append("name", e.profileName);
    try {
      // console.log("profile params: ", showImageName, e.profileName);
      setBusy(true);
      let res = await dispatch(updateProfileDetails(params));
      // console.log("update profile =>", res);
      setItem("userDetails", res?.payload);
      setItem("NAME", res?.payload?.name);
      props.onProfileUpdate();
      setBusy(false);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Event handler for the keydown event.
   * @param {Event} e - The keydown event object.
   * @returns None
   */
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
    }
  };

  /**
   * Handles the form submission event and performs the necessary actions.
   * @param {Object} data - The form data submitted.
   * @returns None
   */
  const onFormSubmit = async (data) => {
    setBusy(true);
    if (data?.oldpassword) {
      try {
        const res = await dispatch(
          changePassword({
            oldPassword: data.oldpassword,
            newPassword: data.password,
          })
        )
        if (res?.status == 200) {
          saveToken(res.payload.tokens);
          changeform.reset()
          dispatch(
            showToast({
              message: res.message,
              type: "success",
            })
          );
          props.setShowChangePassword(false);
          setBusy(false);
        }
      } catch (error) {
        console.log("error ==> ", error);
        setBusy(false);
        dispatch(
          showToast({
            message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
            type: "error",
          })
        );
      }
    }
  };

  /**
   * Handles the logout button press event.
   * @returns None
   */
  const onLogoutPress = async () => {
    setShowLogoutModal(false);
    await dispatch(destroySession({}))
    navigate("/admin/login");
  };

  /**
   * Checks if the given file is a valid image file (jpg, png, jpeg).
   * @param {File} file - The file to check.
   * @returns {boolean} - True if the file is a valid image file, false otherwise.
   */
  const checkValidFile = (file) => {
    const fileType = file[0].type;
    if (
      fileType.includes("jpg") ||
      fileType.includes("png") ||
      fileType.includes("jpeg")
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * An object representing a profile form with validation rules for each field.
   * @property {object} profileName - The profile name field.
   * @property {string} profileName.name - The name of the profile name field.
   * @property {object} profileName.validate - The validation rules for the profile name field.
   * @property {object} profileName.validate.required - The required validation rule for the profile name field.
   * @property {boolean} profileName.validate.required.value - The value indicating if the profile name field is required.
   * @property {string} profileName.validate.required.message - The error message to display if the profile name field is empty.
   * @property {object} profileName.validate.pattern - The pattern validation rule
   */
  const profileForm = {
    profileName: {
      name: "profileName",
      validate: {
        required: {
          value: true,
          message: t("FULL_NAME_EMPTY"),
        },
        pattern: {
          value: Constant.REGEX.ALPHAHYPENAPOSTROPHES,
          message: t("ONLY_ALPHABETS_HYPHENS_APOSTROPHES"),
        },
      },
    },
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("EMAIL_INVALID"),
        },
      },
    },
  };

  const multiErrorFields = [
    { length: t("MINIMUM_8") },
    { hasSpecialChar: t("SPECIAL") },
    { hasNumbers: t("NUMERICAL") },
    { hasUpperLowercase: t("UPPERCASELOWERCASE") },
  ];

  /**
   * An object representing the password change form fields and their validation rules.
   * @constant {Object} changePasswordForm
   * @property {Object} oldpassword - The old password field.
   * @property {string} oldpassword.name - The name of the old password field.
   * @property {Object} oldpassword.validate - The validation rules for the old password field.
   * @property {Object} oldpassword.validate.required - The required validation rule for the old password field.
   * @property {boolean} oldpassword.validate.required.value - The value indicating if the old password field is required.
   * @property {string} oldpassword.validate.required.message - The error message to display if the old password field is not provided.
   * @property {Object
   */
  const changePasswordForm = {
    oldpassword: {
      name: "oldpassword",
      validate: {
        required: {
          value: true,
          message: t("NEW_PASSWORD_REQUIRED"),
        },
      },
    },

    newpassword: {
      name: "newpassword",
      validate: {
        required: {
          value: true,
          message: t("NEW_PASSWORD_REQUIRED"),
        },
        validate: {
          length: (value) =>
            (value && value.length >= 8 && value && value.length <= 64) || "",
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) !== null,
          hasUpperLowercase: (value) =>
            value && value.match(Constant.REGEX.UPPERCASELOWERCASE) !== null,
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: t("CONFIRM_PASSWORD_REQUIRED"),
        },
      },
    },
  };

  /**
   * Generates an image word based on the user's name.
   * @returns {string} The image word.
   */
  let imageWord = () => {
    if (userDetails) {
      const wordWrapName = userDetails && userDetails?.name;
      let w = wordWrapName
        ?.split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")
        .trim(2);
      let y = w?.substring(0, 2);
      return y;
    }
    return "";
  };

  return (
    <div className="ProfilePage">
      {!props.changePasswordModel ? (
        <div className="editprofile">
          <HookForm
            defaultForm={{}}
            init={(form) => setUserForm(form)}
            onSubmit={(e) => onUpdateSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="fileInput">
                    <Controller
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <FileUpload
                          onDrop={(acceptedFiles) => {
                            if (checkValidFile(acceptedFiles)) {
                              onChange(acceptedFiles);
                              onImageChange(acceptedFiles);
                            }
                          }}
                        >
                          <div className="imgBoxEmpty">
                            {(userDetails && userDetails?.image) || image ? (
                              <>
                                <div className="uploadedImg">
                                  <img
                                    src={image ? image : userDetails?.image}
                                    alt="viewImg"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="uploadedImg">
                                  <div className="ProifleName">
                                    {imageWord()}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="change_txt">{t("Upload")}</div>
                        </FileUpload>
                      )}
                      name="image"
                      control={formMethod.control}
                    />
                  </div>
                  <div className="input_label">{t("Name")}</div>
                  <TextField
                    formMethod={formMethod}
                    rules={profileForm.profileName.validate}
                    name={profileForm.profileName?.name}
                    errors={formMethod?.formState?.errors}
                    autoFocus={true}
                    textFieldClassName="fullTextBox"
                    type="text"
                    defaultValue={userDetails?.name || ''}
                    autoComplete="on"
                    placeholder={t("Full_name")}
                    maxLength={50}
                  />
                  <div className="input_label">{t("EMAIL_ADDRESS")}</div>
                  <TextFieldNoRequired
                    formMethod={formMethod}
                    rules={profileForm.email.validate}
                    name={profileForm.email?.name}
                    errors={formMethod?.formState.errors}
                    autoFocus={true}
                    type="text"
                    defaultValue={userDetails?.email || ''}
                    autoComplete="on"
                    placeholder={t("EMAIL_ADDRESS")}
                    disabled
                  />
                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={t("UPDATE")}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                    />
                    <CustomButton
                      type="button"
                      className="outline_btn"
                      title={t("CHANGEPASSWORD_BT")}
                      onClick={() => {
                        props.onHeaderChange("Change password");
                        props.setShowChangePassword(true)
                      }}
                    />
                    <CustomButton
                      type="button"
                      className="outline_btn"
                      onClick={() => setShowLogoutModal(!showLogoutModal)}
                      title={t("LOGOUT")}
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      ) : (
        <div className="change_pasword">
          <HookForm
            defaultForm={{}}
            init={(changeform) => setResetForm(changeform)}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <div className="form">
                  <div className="top_title_row">
                    <div className="change_password_title">{t("CHANGEPASSWORD_BT")}</div>
                    <div className="signfield">
                      <CustomButton
                        type="submit"
                        title={t("UPDATE")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                      <CustomButton
                        type="button"
                        className="outline_btn"
                        title={t("CANCEL")}
                        onClick={() => {
                          props.setShowChangePassword(false);
                          props.onHeaderChange("Profile");
                        }}
                      />
                    </div>
                  </div>
                  <TextField
                    formMethod={formMethod}
                    rules={changePasswordForm.oldpassword.validate}
                    multiErrorFields={multiErrorFields}
                    errors={formMethod?.formState.errors}
                    name={changePasswordForm.oldpassword.name}
                    iconError={formMethod?.errors}
                    type={showOldPass ? "text" : "password"}
                    placeholder={t("Old_Password")}
                    iconClass={showOldPass ? "icon-eye-show" : "icon-eye-hide"}
                    onKeyDown={onKeyDown}
                    onChange={(e) => {
                      let value = e.target.value;
                      e.target.value = value.replaceAll(" ", "");
                      formMethod.setValue("oldpassword", e.target.value);
                      // formMethod.watch("password") &&
                      // formMethod.trigger("password");
                      setSearchOldPassword(e.target.value);
                    }}
                    onIconClick={() => setShowOldPass(!showOldPass)}
                    iconRightShow={true}
                    defaultValue={""}
                  />
                  <TextField
                    formMethod={formMethod}
                    rules={changePasswordForm.newpassword.validate}
                    multiErrorFields={multiErrorFields}
                    errors={formMethod?.formState.errors}
                    name={changePasswordForm.newpassword.name}
                    iconError={formMethod?.errors}
                    type={showPass ? "text" : "password"}
                    placeholder={t("New_Password")}
                    iconClass={showPass ? "icon-eye-show" : "icon-eye-hide"}
                    onKeyDown={onKeyDown}
                    onChange={() =>
                      formMethod.watch("password") &&
                      formMethod.trigger("password")
                    }
                    onIconClick={() => setShowPass(!showPass)}
                    iconRightShow={true}
                    defaultValue={""}
                  />
                  <TextField
                    formMethod={formMethod}
                    rules={{
                      required: {
                        value: true,
                        message: t("CONFIRM_PASSWORD_REQUIRED"),
                      },
                      validate: {
                        matchPassword: (value) =>
                          (value &&
                            value === formMethod.watch("newpassword")) ||
                          t("BOTHNOTMATCH"),
                      },
                    }}
                    name={changePasswordForm.password.name}
                    errors={formMethod?.formState.errors}
                    iconError={formMethod?.errors}
                    type={showConfirmPass ? "text" : "password"}
                    placeholder={t("Confirm_Password")}
                    iconClass={
                      showConfirmPass ? "icon-eye-show" : "icon-eye-hide"
                    }
                    onChange={() =>
                      formMethod.watch("newpassword") &&
                      formMethod.trigger("newpassword")
                    }
                    onKeyDown={onKeyDown}
                    onIconClick={() => setshowConfirmPass(!showConfirmPass)}
                    iconRightShow={true}
                  />
                </div>
              );
            }}
          </HookForm>
        </div>
      )}
      <ConfirmModal
        modalTitle={t("LOGOUT")}
        leftBtnTitle={t("YES_LOGOUT")}
        rightBtnTitle={t("NO_CANCEL")}
        showModal={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        onProcced={() => {
          onLogoutPress();
          // alert("adfdaf");
        }}
        modalContent={false}
        className={"confirm-logout"}
      >
        <p>{t("Are_you_sure_you_want_to_logout")}</p>
      </ConfirmModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default Profile;
