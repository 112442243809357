import React from 'react';
import CustomToggleButton from '../customToggleButton/customToggleButton';
import './customNavigations.scss';


/**
 * A functional component that renders a custom navigation item.
 * @param {Object} props - The props object containing the following properties:
 *   - title: The title of the navigation item.
 *   - count: The count associated with the navigation item.
 *   - checked: A boolean indicating whether the navigation item is checked.
 *   - onCheckChange: A function to handle the change event when the item is checked.
 *   - color: The color of the navigation item.
 *   - onClickEdit: A function to handle the click event when the edit button is clicked.
 *   - onClickDelete: A function to handle the click event when the delete button is clicked.
 *   - className: The additional class name for
 */
const CustomNavigations = (props) => {

  let { title, count, checked, onCheckChange, color, onClickEdit, onClickDelete, className, onClickView, selected, showEdit } = props
  return (
    <div className={"CustomNavigationsMain" + className}>
      <button className={selected ? "full_btn selected_bg" : "full_btn"} type="button" onClick={onClickView}>
        <span className="active_bg" style={{ backgroundColor: color }}></span>
        <div className="title_left">
          <span className="cricle_bg" style={{ backgroundColor: color }}></span>
          <div className="title_txt"><div className="text">{title}<span className="count_number">{count}</span></div></div>
        </div>
        {showEdit && <div className="action_right">
          <button onClick={onClickEdit} className="icon-pencil"></button>
          <button onClick={onClickDelete} className="icon-delete"></button>
          <CustomToggleButton id="" checked={checked} onCheckChange={(e) => {
            onCheckChange(e)
          }} />
        </div>
        }
      </button>
    </div>
  );
}

export default CustomNavigations;
