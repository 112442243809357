import React, { useState, useEffect } from 'react';
import './categoryCheckbox.scss';
import PropTypes from 'prop-types';

/**
 * A checkbox component that allows users to select or deselect an option.
 * @param {Object} props - The props object containing the following properties:
 *   - {string} labelTitle - The title of the checkbox label.
 *   - {string} id - The unique identifier for the checkbox.
 *   - {function} onCheckedChange - The callback function to be called when the checkbox is checked or unchecked.
 *   - {function} register - The ref function to register the checkbox element.
 *   - {string} name - The name attribute of the checkbox.
 *   - {boolean} disabled - Determines if the checkbox is disabled or not.
 *   - {string} uniqueId - The unique identifier for the checkbox
 */
const CheckBox = (props) => {
    const { labelTitle, id, onCheckedChange, register, name, disabled, uniqueId, color } = props;
    const [checked, setChecked] = useState(!props.checked);

    /**
     * useEffect hook that updates the state of the checked variable whenever the props.checked value changes.
     * @param {function} setChecked - The state setter function for the checked variable.
     * @param {boolean} props.checked - The value of the checked prop.
     * @returns None
     */
    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    /**
     * Handles the event when the checkbox is checked or unchecked.
     * @param {Event} e - The event object.
     * @returns None
     */
    const onChecked = (e) => {
        setChecked(e.target.checked);
        onCheckedChange && onCheckedChange(e.target.checked);
    };

    /**
     * Renders a checkbox component with the given properties.
     * @param {string} uniqueId - The unique identifier for the checkbox.
     * @param {string} name - The name of the checkbox.
     * @param {string} labelTitle - The title of the checkbox label.
     * @param {string} id - The ID of the checkbox.
     * @param {boolean} disabled - Indicates whether the checkbox is disabled.
     * @param {boolean} checked - Indicates whether the checkbox is checked.
     * @param {function} onChecked - The function to call when the checkbox is checked/unchecked.
     * @param {function} onClick - The function to call when the checkbox is clicked.
     * @param {string} color - The color
     */
    return (
        <div className="checkboxMain1" key={`check_${uniqueId}`} >
            <input type="checkbox" ref={register} name={name} value={labelTitle} id={id} disabled={disabled} className='checkbox' checked={checked} onChange={onChecked} onClick={props.onClick} />
            <label className='checkboxLabel' id="checkLabel" htmlFor={id}>{labelTitle}</label>
            <span className={checked ? 'checked' : 'unchecked'} style={{ backgroundColor: checked ? '#fff' : color, border: `3px solid ${color}` }}><i style={{ borderColor: color }} ></i></span>
        </div>
    );
};

CheckBox.defaultProps = {
    id: '',
    checked: false,
    labelTitle: '',

};

CheckBox.propTypes = {
    disabled: PropTypes.bool,
}

export default CheckBox;
