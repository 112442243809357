/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import Constant from "../../../util/constant";
import { useTranslation } from "react-i18next";
import { saveToken } from "../../../services/jwtService";
import { showToast } from "../../../state/ducks/utils/operations";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { login, initializeSession } from "../../../state/ducks/auth/operations";
import Label from "../../../components/label/label";
import HookForm from "../../../components/hookForm/hookForm";
import CheckBox from "../../../components/checkbox/checkBox";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import "./login.scss";
import { Helmet } from 'react-helmet';

/**
 * A functional component that represents a login form.
 * @param {object} props - The props passed to the component.
 * @returns The rendered login form.
 */
function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dimensions = useWindowDimension();
  const [form, setLoginForm] = useState();
  const [showPass, setShowPass] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [busy, setBusy] = useState(false);

  const cloudnaryImage = process.env.REACT_APP_CLOUDNARY_URL + process.env.REACT_APP_CLOUDNARY_NAME + process.env.REACT_APP_CLOUDNARY_SUBFLODER
  /**
   * Represents a login form with email and password fields.
   * @type {Object}
   * @property {Object} email - The email field configuration.
   * @property {string} email.name - The name of the email field.
   * @property {Object} email.validate - The validation rules for the email field.
   * @property {boolean} email.validate.required - Indicates if the email field is required.
   * @property {string} email.validate.message - The error message to display if the email field is required.
   * @property {Object} email.validate.pattern - The pattern validation rule for the email field.
   * @property {string} email.validate.pattern.value - The regular expression pattern for validating the email field.
   * @property {string}
   */
  const loginForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("EMAIL_INVALID"),
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: t("PASSWORD_REQUIRED"),
        },
      },
    },
  };

  /**
   * Handles the form submission event and performs the necessary actions.
   * @param {Event} e - The form submission event.
   * @returns None
   */
  const onFormSubmit = async (e) => {
    try {
      setBusy(true);
      const params = {
        email: e.email.toLowerCase(),
        password: e.password,
      };

      let res = await dispatch(login(params));
      const { tokens, adminData } = res.payload;
      if (res?.status == 200) {
        saveToken(tokens);
      }
      let name = adminData?.name;
      window.localStorage.setItem("NAME", name);
      let unCategorisedData = res.payload.unCategorisedData.uncategorised
        ? res?.payload?.unCategorisedData?.uncategorised
        : {};
      let eventCount = res?.payload?.unCategorisedData?.eventCont;
      unCategorisedData["eventCount"] = eventCount;
      window.localStorage.setItem(
        "Uncategorised",
        JSON.stringify(unCategorisedData)
      );
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );

      dispatch(initializeSession({ adminData, tokens }));
      if (rememberMe) {
        window.localStorage.setItem("admin_email", e.email);
        window.localStorage.setItem("admin_password", e.password);
        window.localStorage.setItem("admin_rememberMe", rememberMe);
      } else {
        window.localStorage.removeItem("admin_email");
        window.localStorage.removeItem("admin_password");
        window.localStorage.removeItem("admin_rememberMe");
      }
      setBusy(false);
      navigate("/admin/service", { serviceCount: true });
    } catch (error) {
      console.log("login error ==> ", error);
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
    // document.body.classList.remove('rightSidebarshow');
  };

  /**
   * Renders a login form with various input fields and buttons.
   * @returns JSX element representing the login form.
   */
  return (
    <>
      <Helmet>
        <title>Admin | Angus Recovery Road Map</title>
      </Helmet >

      <div className="login">
        <div className="formMain" style={{ height: dimensions.height }}>
          <div className="formCenter">
            <div className="logo_bg">
              <img src={cloudnaryImage + 'banner_angus.png'} alt="" />
              <div className="logoauth">
                <img src={cloudnaryImage + 'Logo.png'} alt="Angus Logo" className="LogoAuth" />
              </div>
            </div>

            <Label title={t("LOGIN_TITLE")} />
            <HookForm
              defaultForm={{
                email: localStorage.getItem("admin_email"),
                password: localStorage.getItem("admin_password"),
                rememberMe: localStorage.getItem("admin_rememberMe"),
              }}
              init={(form) => setLoginForm(form)}
              onSubmit={(e) => onFormSubmit(e)}
            >
              {(formMethod) => {
                return (
                  <>
                    <TextField
                      formMethod={formMethod}
                      rules={loginForm.email.validate}
                      name={loginForm.email.name}
                      errors={formMethod?.formState.errors}
                      autoFocus={true}
                      type="text"
                      autoComplete="on"
                      placeholder={t("EMAIL_ADDRESS")}
                      iconClass={"icon-email"}
                      iconRightShow={true}
                    />
                    <TextField
                      formMethod={formMethod}
                      rules={loginForm.password.validate}
                      name={loginForm.password.name}
                      errors={formMethod?.formState.errors}
                      type={showPass ? "text" : "password"}
                      placeholder={t("PASSWORD")}
                      iconClass={showPass ? "icon-eye-show" : "icon-eye-hide"}
                      onIconClick={() => setShowPass(!showPass)}
                      iconRightShow={true}
                    />

                    <div className="checkboxRow">
                      <CheckBox
                        id="remember"
                        labelTitle={t("REMEMBER_ME")}
                        checked={rememberMe}
                        onCheckedChange={(checked) => {
                          setRememberMe(checked);
                        }}
                      ></CheckBox>
                    </div>
                    <div className="custombtnfield">
                      <CustomButton
                        type="submit"
                        title={t("LOG_IN")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                    </div>
                    <div className="forgotLink">
                      <Link to="/admin/forgotpassword">
                        {t("FORGOT_PASSWORD")}
                      </Link>
                    </div>
                  </>
                );
              }}
            </HookForm>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
