import React from 'react';
import './loading.scss';
import ReactLoading from "react-loading";

const Loading = ({
  show,
  type = "spinningBubbles",
  color = 'white',
  className = "full"
}) => {
  return (
    show ? <div className={className}><ReactLoading type={type} color={color} height={80} width={80} /></div> : null)
}

Loading.defaultProps = {
  height: 30,
  width: 30,
};

export default Loading;
