import {
    login as loginAction,
    resetPassword,
    forgotPassword,
    verifyResetPasswordLink,
    initializeSession
} from "./actions";

const login = (payload) => {
    return async (dispatch) => {
        let response = await dispatch(loginAction(payload));
        return response
    };
}

export {
    login,
    resetPassword,
    forgotPassword,
    initializeSession,
    verifyResetPasswordLink
};
