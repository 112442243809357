/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Constant from "../../../util/constant";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../state/ducks/utils/operations";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { forgotPassword } from "../../../state/ducks/auth/actions";

import Label from "../../../components/label/label";
import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";

import "./forgotPassword.scss";
import { Helmet } from 'react-helmet';

/**
 * A functional component that renders a forgot password form.
 * @param {object} props - The component props.
 * @returns The rendered forgot password form.
 */
function ForgotPassword(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dimensions = useWindowDimension();
  const [form, setForgotForm] = useState();
  const [busy, setBusy] = useState(false);

  const cloudnaryImage = process.env.REACT_APP_CLOUDNARY_URL + process.env.REACT_APP_CLOUDNARY_NAME + process.env.REACT_APP_CLOUDNARY_SUBFLODER


  /**
   * Represents a forgot password form with an email field.
   * @type {Object}
   * @property {Object} email - The email field configuration.
   * @property {string} email.name - The name of the email field.
   * @property {Object} email.validate - The validation rules for the email field.
   * @property {Object} email.validate.required - The required validation rule for the email field.
   * @property {boolean} email.validate.required.value - Indicates if the email field is required.
   * @property {string} email.validate.required.message - The error message to display if the email field is empty.
   * @property {Object} email.validate.pattern - The pattern validation rule for the email field.
   * @property {string}
   */
  const forgotForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("EMAIL_INVALID"),
        },
      },
    },
  };

  /**
   * Handles the form submission event and sends a request to the server to reset the password.
   * @param {Object} e - The form submission event object.
   * @returns None
   */
  const onFormSubmit = async (e) => {
    try {
      setBusy(true);
      const params = {
        email: e.email,
      };
      let res = await dispatch(forgotPassword(params));
      setBusy(false);
      dispatch(
        showToast({
          message: res.message,
          type: "success",
        })
      );
    } catch (error) {
      setBusy(false);
      dispatch(
        showToast({
          message: get(error, t("response.data.message"), t("SOMETHING_WENT_WRONG")),
          type: "error",
        })
      );
    }
  };

  /**
   * Renders a Forgot Password form component.
   * @returns {JSX.Element} - The rendered Forgot Password form component.
   */
  return (<>
    <Helmet>
      <title>Forgot Password | Admin Angus Recovery Road Map</title>
    </Helmet>

    <div className="ForgotPassword">
      <div className="formMain" style={{ height: dimensions.height }}>
        <div className="formCenter">
          <div className="logo_bg">
            <img src={cloudnaryImage + "banner_angus.png"} alt="" />
            <div className="logoauth">
              <img
                src={cloudnaryImage + "Logo.png"}
                alt="Angus Logo"
                className="LogoAuth"
              />
            </div>
          </div>
          <Label title={t("FORGOT_PASSWORD_TITLE")} />
          <HookForm
            defaultForm={{}}
            init={(form) => setForgotForm(form)}
            onSubmit={(e) => onFormSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <TextField
                    formMethod={formMethod}
                    rules={forgotForm.email.validate}
                    name={forgotForm.email.name}
                    errors={formMethod?.formState.errors}
                    autoFocus={true}
                    type="text"
                    placeholder={t("EMAIL_ADDRESS")}
                    iconClass="icon-email"
                    iconRightShow={true}
                  />

                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={t("SUBMIT")}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                    />
                  </div>
                  <div className="backtologin">
                    <Link to="/admin/login">{t("BACK_TO_SIGNIN")}</Link>
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      </div>
    </div>
  </>
  );
}

export default ForgotPassword;
