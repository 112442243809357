import { isCheckValueAndSetParams } from '../../../services/helperService';
import * as types from './types';

export const getEventList = (categoryId, search, limit, page) => (
    {
        type: types.GET_EVENT_LIST,
        meta: {
            async: true,
            blocking: false,
            path: `event/getEventList?categoryId=${categoryId}&sortBy=status:desc&limit=${limit}&page=${page}${isCheckValueAndSetParams("&search=", search)}`,
            method: 'GET',
        }
    }
)

export const getEventDetails = (id) => (
    {
        type: types.GET_EVENT_DETAILS_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `event/getEventDetails?id=${id}`,
            method: 'GET'
        }
    }
)

export const updateEventDetails = (id, payload) => (
    {
        type: types.EVENT_UPDATED_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `event/update?id=${id}`,
            method: 'PUT',
            body: payload
        }
    }
)

export const addEvent = (payload) => ({
    type: types.ADD_EVENT_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: `event/add`,
        method: 'POST',
        body: payload,

    }
})

export const eventStatusUpdate = (id, payload) => ({
    type: types.EVENT_STATUS_UPDATE_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: `event/statusUpdate?id=${id}`,
        method: 'PUT',
        body: payload
    }
})

export const deleteEvent = (id) => (
    {
        type: types.DELETE_EVENT_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `event/delete?id=${id}`,
            method: 'DELETE',
        }
    })