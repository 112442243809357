export const LOGIN = "auth/LOGIN";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";

export const SIGNUP = "SIGNUP";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_COMPLETED = "SIGNUP_COMPLETED";

export const AUTHENTICATED = "auth/AUTHENTICATED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";

export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_FAILED = "auth/LOGOUT_FAILED";
export const LOGOUT_COMPLETED = "auth/LOGOUT_COMPLETED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";
export const REFRESH_TOKEN_COMPLETED = "auth/REFRESH_TOKEN_COMPLETED";

export const FORGOT_PASSWORD = "auth/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAILED = "auth/FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_COMPLETED = "auth/FORGOT_PASSWORD_COMPLETED";

export const VERIFY_RESET_PASSWORD_LINK = "auth/VERIFY_RESET_PASSWORD_LINK";
export const VERIFY_RESET_PASSWORD_LINK_FAILED = "auth/VERIFY_RESET_PASSWORD_LINK_FAILED";
export const VERIFY_RESET_PASSWORD_LINK_COMPLETED = "auth/VERIFY_RESET_PASSWORD_LINK_COMPLETED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_COMPLETED = "auth/RESET_PASSWORD_COMPLETED";

export const DESTROY = "auth/DESTROY_SESSION";
export const INITIALIZE = "auth/INITIALIZE_SESSION";